<template>
  <div
    class="page-table column scrollable only-y"
    :class="{ flex: !isMobile, overflow: isMobile }"
  >
    <div class="page-header">
      <h1>
        Configuration de <span style="color: #ffa409">fonctionnement</span>
        <theme-picker style="float: right"></theme-picker>
      </h1>
    </div>

    <div class="mb-5">
      <el-button @click="toCreateConfig()" type="primary" round
        >Nouvelle configuration</el-button
      >
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
          placeholder="Rechercher config..."
          prefix-icon="el-icon-search"
          v-model="search"
          clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom: 15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top: 15px">
          <el-input
            placeholder="Rechercher config..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
    </div>

    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="!isMobile"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        :height="height"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Libéllé de la config"
          fixed
          min-width="130"
          prop="config_name"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 'password'" class="date"
              >*********</span
            >
            <span v-else class="date">{{ scope.row.libelle }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Valeur utilisée"
          min-width="150"
          fixed
          prop="config_value"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="50"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="detailAssu(scope.row)"
              type="primary"
              icon="el-icon-view"
              circle
              title="Voir détails"
            ></el-button>
            <el-button
              @click="editConfig(scope.row)"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              title="Modifier"
            ></el-button>
            <el-button
              @click="DeleteConfig(scope.row)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      ></el-pagination>
    </div>

    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="isMobile"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Libéllé de la config"
          fixed
          min-width="130"
          prop="config_name"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 'password'" class="date"
              >*********</span
            >
            <span v-else class="date">{{ scope.row.config_value }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Valeur utilisée"
          min-width="150"
          fixed
          prop="config_value"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="50"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="detailAssu(scope.row)"
              type="primary"
              icon="el-icon-view"
              circle
              title="Voir détails"
            ></el-button>
            <el-button
              @click="editConfig(scope.row)"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              title="Modifier"
            ></el-button>
             <el-button
              @click="DeleteConfig(scope.row)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      ></el-pagination>
    </div>

    <div
      class="table-box card-base card-shadow--medium box grow"
      id="table-wrapper"
      v-loading="!ready"
      v-if="isMobile"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Branche"
          min-width="150"
          prop="branche"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="50"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="BrancheDeleteConfirmation(scope.row)"
              type="danger"
              icon="el-icon-delete"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      width="70%!important"
      title="Détails Config"
      :visible.sync="showass"
      v-if="showass == true && !isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-card class="box-card">
                  <div >
                    <ul style="list-style: none">
                      <li>
                        Valeur de la configuration :
                        <span class="to-right assuraf-orange">{{
                          detailsconfig.config_value
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-dialog>

    <el-dialog
      width="90%!important"
      title="Détails Config"
      :visible.sync="showass"
      v-if="showass == true && isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24" style="margin-top: 15px">
              <div class="grid-content bg-purple">
                <el-card class="box-card">
                  <div >
                    <ul style="list-style: none">
                      <li>
                        Valeur de la configuration :
                        <span class="to-right assuraf-orange">{{
                          detailsconfig.config_value
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-dialog>

    <el-dialog
      width="50%!important"
      title="Formulaire de modification"
      :visible.sync="editerConfig"
      v-if="editerConfig == true && !isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="12">
              <div>
                <label>Valeur de la configuration</label>
                <el-input
                  :type="detailsconfig.type"
                  v-model="detailsconfig.config_value"
                  placeholder="Valeur config"
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="danger " round @click="UpdateConfig()"
            >Modifier</el-button
          >
          <el-button type="primary" round @click="editerConfig = false"
            >Annuler</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="90%!important"
      title="Formulaire de modification"
      :visible.sync="editerConfig"
      v-if="editerConfig == true && isMobile"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Valeur de la config</label>
                <el-input
                  v-model="detailsconfig.config_value"
                  placeholder="Valeur config"
                  class="input-with-select"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="danger " round @click="UpdateConfig()"
            >Modifier</el-button
          >
          <el-button type="primary" round @click="editerConfig = false"
            >Annuler</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Suppression de configuration"
      width="50%"
      :visible.sync="deleteConfig"
      v-if="deleteConfig == true"
    >
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cette configuration ?</span>
          </div>
          <div >
            <ul style="list-style: none">
              <li>
                <h3>
                  Libelle config :
                  <span class="to-right assuraf-orange">{{
                   detailsconfig.libelle
                  }}</span>
                </h3>
              </li>
              <li>
                <h3>
                  Valeur name :
                  <span class="to-right assuraf-orange">{{
                   detailsconfig.config_value
                  }}</span>
                </h3>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top: 15px">
        <el-button
          type="danger"
          round
          @click="deletingConfig(detailsconfig._id)"
          >Oui</el-button
        >
        <el-button type="primary" round @click="deleteConfig = false"
          >Non</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import _ from "lodash";
import moment from "moment-timezone";

//debut importation assuraf
import dayjs from "dayjs";
import Api from "../../../services/Api";
import store from "../../../store";
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      deleteConfig: false,
      editerConfig: false,
      partenaireId: null,
      testsoc: null,
      username: null,
      dismissSecs: 3,
      dismissCountDown: 0,
      totalRows: null,
      perPage: 8,
      data: {},
      html: "",
      detailsconfig: [],
      lassureur: [],
      waiting: false,
      showass: false,
      listconfig: [],
      filter: null,
      fields: [
        {
          key: "config_name",
          label: "Libéllé de la configuration",
        },
        {
          key: "config_value",
          label: "Valeur utilisée",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      listbranche: [],
      detailbranche: [],
      showpack: false,
      nombranche: null,
      modalgenerationloading: false,
      optionsdate: {
        format: "YYYY/MM/DD",
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: "",
        content: "",
      },
      fletteRows: null,
      Mycounter: 0,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false,
      },
      list: this.listbranche, //users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
    };
  },
  computed: {
    listFiltered() {
      return this.listconfig.filter((obj) => {
        let ctrl = false;
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true;
        }
        return ctrl;
      });
    },
    /*listFiltered() {
        return this.list.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },*/
    listSortered() {
      let prop = this.sortingProp;
      let order = this.sortingOrder;
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = "";
          let val2 = "";

          val1 = item1[prop];
          val2 = item2[prop];
          if (order === "descending") {
            return val2 < val1 ? -1 : 1;
          }
          return val1 < val2 ? -1 : 1;
        })
      );
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    itemPerPage() {
      return this.pagination.size;
    },
    selectedItems() {
      return this.itemsChecked.length || 0;
    },
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    deletingConfig(id) {
      Api()
        .delete("/systemeconfig/delete/" + id)
        .then((resultat) => {
          if (resultat.status == 200) {
            this.$message.success("configuration supprimé !", this.AlertMessage);
            this.deleteConfig = false;
            this.getConfig();
          }
        })
        .catch((err) => {});
    },
    editConfig(item) {
      this.editerConfig = true;
      this.detailsconfig = item;
    },
     DeleteConfig(item) {
      this.deleteConfig = true;
      this.detailsconfig = item;
    },
    /*================*/
    UpdateConfig(item) {
      item = this.detailsconfig;
      Api()
        .post("/systemeconfig/update", item)
        .then((response) => {
          this.editerConfig = false;
          this.$message.success(response.data.msg, this.AlertMessage);
          this.getConfig();
        })
        .catch((e) => {
          this.$message.error("Erreur de connexion !", this.AlertMessage);
        });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    configUpdate(item) {
      Api()
        .post("/systemeconfig/update", item)
        .then((resultat) => {
          this.showAlert();
          this.getConfig();
        });
    },

    detailAssu(item) {
      this.detailsconfig = item;
      this.showass = true;
    },

    toCreateConfig() {
        this.$router.push("/partner/configuration/create")
    },
    detailbrancheVue(item) {
      this.detailbranche = item;
      this.showpack = true;
    },
    getBranche() {
      Api()
        .get("/branche")
        .then((resultat) => {
          this.listbranche = resultat.data;
          this.totalRows = this.listbranche.length;
        });
    },

    // Creation de branche
    CreateBranche() {
      if (this.nombranche) {
        let br = {
          branche: this.nombranche,
          user: store.state.user._id,
        };

        Api()
          .post("/branche/add", br)
          .then((rep) => {
            if (rep.data.message) {
              this.$message.error(rep.data.message, this.AlertMessage);
              this.nombranche = "";
              this.getBranche();
            }
          });
      } else {
        this.$message.error(
          "Le nom de la branche obligatoire !",
          this.AlertMessage
        );
      }
    },

    BrancheDeleteConfirmation(item) {
      this.modalDelete = true;
      this.detailbranche = item;
    },

    Deletingbranche(id) {
      Api()
        .delete("branche/" + id)
        .then((resultat) => {
          if (resultat.data.rep == 1) {
            this.modalDelete = false;
            this.$message.success(
              "Branche supprimée avec succés !",
              this.AlertMessage
            );
            this.getBranche();
          }
        });
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    toggleDetails(item) {
      this.detailsUser = item;
      this.editUser = true;
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case "Admin":
          Api()
            .get("/user/frontoffice/")
            .then((resultat) => {
              this.listuser = resultat.data;
              this.totalRows = resultat.data.length;
            });
          break;

        case "assureur":
          Api()
            .get("/user/myuser/assureur/" + this.$store.state.user.societe)
            .then((resultat) => {
              this.listuser = resultat.data;
              this.totalRows = resultat.data.length;
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {
      let idU = item.id;
      Api()
        .post("user/" + idU, item)
        .then((response) => {
          if (response.data) {
            this.showAlert();
          }
          this.showAlert();
        })
        .catch((err) => {});
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },

    userDeleteConfirmation(item) {
      this.modalDelete = true;
      this.detailsUser = item;
    },

    DeletingUser(id) {
      Api()
        .delete("user/delete/" + id)
        .then((resultat) => {
          if (resultat.status == 200) {
            this.modalDelete = false;
            this.getUserList();
          }
        })
        .catch((err) => {});
    },

    getUserMenu(id) {
      Api()
        .post("/menu/user/menu", { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },
    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val;
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true;
    },
     getConfig(){
      Api()
        .get("/systemeconfig/listing")
        .then((resultat) => {
          this.listconfig = resultat.data.conf;
          this.totalRows = resultat.data.conf.length;
        });
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return "";
      if (!sel) return value;

      value = value.toString();
      sel = sel.toString();

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase());
      if (startIndex !== -1) {
        const endLength = sel.length;
        const matchingString = value.substr(startIndex, endLength);
        return value.replace(
          matchingString,
          `<span class="sel">${matchingString}</span>`
        );
      }

      return value;
    },
  },

  created() {
    this.init();
    this.getBranche();
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
      this.getConfig()
    } else {
      this.$router.go(this.$router.push("/login"));
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt;

    this.calcDims();
  },
};
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
  
<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
  