<template>
    <div class="toolbar flex align-center justify-space-between">
        <div class="box-left box grow flex">
            <button
                @click="toggleSidebar"
                v-if="menuBurger !== 'right'"
                class="toggle-sidebar card-base card-shadow--small"
            >
                <i class="mdi mdi-menu"></i>
            </button>

            <img @click="goto('/dashboard')" class="logo-mini" src="@/assets/images/logo_assuraf.png" alt="logo" />
            <!-- <search class="hidden-xs-only"></search> -->
        </div>

        <div class="box-right flex align-center pl-10">
            <button class="fullscreen-button" @click="toggleFullscreen">
                <i class="mdi mdi-fullscreen" v-if="!fullscreen"></i>
                <i class="mdi mdi-fullscreen-exit" v-if="fullscreen"></i>
            </button>
            <el-popover ref="popover" placement="bottom" :width="popoverWidth" trigger="click">
                <notification-box></notification-box>
            </el-popover>
           
            <span class="username">
                <router-link to="/profile"> {{$store.state.user.nom.toUpperCase()}} -  {{$store.state.user.prenom}} </router-link>
            </span>

            <el-dropdown trigger="click" @command="onCommand">
                <span class="el-dropdown-link">
                    <img src="../assets/images/tete.png" class="avatar" alt="avatar" />
                </span>
                <el-dropdown-menu slot="dropdown" @click="Myprofile=true">
                    <el-dropdown-item style="cursor: default ">
                        <i class="mdi mdi-account-outline mr-10"></i>{{$store.state.user.role }}
                    </el-dropdown-item>
                    <el-dropdown-item style="cursor: default ">
                        <i class="mdi mdi-account-outline mr-10" v-if="$store.state.user.code_partenaire!=null"></i> {{$store.state.user.code_partenaire + ' ' }}
                    </el-dropdown-item>
                    <el-dropdown-item command="/profile">
                        <i class="mdi mdi-account-outline mr-10" @click="Myprofile=true"></i> Mon profil
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                        <button class="logout-button" @click="Logout()">
                           <i class="mdi mdi-logout mr-10"></i>
                           Se deconnecter
                        </button> 
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <button
                @click="toggleSidebar"
                v-if="menuBurger === 'right'"
                class="toggle-sidebar toggle-sidebar__right card-base card-shadow--small"
            >
                <i class="mdi mdi-menu"></i>
            </button>
        </div>
    </div>
</template>

<script>
import NotificationBox from "@/components/NotificationBox"
import Search from "@/components/Search"
import Api from "../services/Api"
import store from './../store'
//import
export default {
    name: "Toolbar",
    props: ["menuBurger"],
    data() {
        return {
            popoverWidth: 300,
            fullscreen: false,
            lang: "fr",
            deleteConfirmation: false,
            modalDelete: false,
            dismissCountDown: 0,
            modalShow: false,
            listuser: null,
            Myprofile: false,
            username: null,
            nom: null,
            seenuser: true,
            currentuser: null,
            LoggedUser: null,
        }
    },
    methods: {
        goto(index, indexPath) {
            if (index.charAt(0) === "/") {
                this.$router.push(index)
                this.$emit("push-page", { page: index })
            }
        },
        Logout() {
            this.$store.state.isUserLoggedIn = false
            Api().get('user/logout/' + store.state.user._id)
                .then(response => {
                    if (response.data.info == 'ok') {
                        this.$store.dispatch("setUser", {});
                        this.$router.go(this.$router.push({
                            name: 'login'
                        }))
                    }
                })
            this.$store.dispatch("setUser", {});
            this.$router.go(this.$router.push("/login"));
        },

        UpdateUser() {

            if (this.username.nom && this.username.prenom && this.username.email) {
                if (this.username.statut_marital && this.username.telephone_port && this.username.telephone_bur && this.username.piece_identite) {
                    if (this.username.numero_piece && this.username.profession) {
                        Api().post('user/' + this.username._id, this.username)
                            .then(response => {
                                if (response.data) {
                                    this.showAlert()
                                }
                            }).catch(err =>{ console.log(err)})
                    } else {
                        this.$toasted.show('Touts les champs sont obligatoires !', this.ToastTabError)
                    }
                } else {
                    this.$toasted.show('Touts les champs sont obligatoires !', this.ToastTabError)
                }
            } else {
                this.$toasted.show('Touts les champs sont obligatoires !', this.ToastTabError)
            }

        },
        onCommandLang(lang) {
            if (lang.charAt(0) === "/") this.onCommand(lang)
            else this.lang = lang
        },
        onCommand(path) {
            this.$router.push(path)
        },
        toggleSidebar() {
            this.$emit("toggle-sidebar")
        },
        resizePopoverWidth() {
            if (window.innerWidth <= 768) {
                this.popoverWidth = 250
            } else {
                this.popoverWidth = 300
            }
        },
        toggleFullscreen() {
            this.$fullscreen.toggle(document.querySelector("body"), {
                wrap: false,
                callback: () => {
                    this.fullscreen = this.$fullscreen.getState()
                }
            })
        }
    },
    components: {
        NotificationBox,
        Search
    },
    mounted() {
        this.fullscreen = this.$fullscreen.getState()
        this.resizePopoverWidth()
        window.addEventListener("resize", this.resizePopoverWidth)

        if(this.$store.state.isUserLoggedIn && this.$store.state.user!=null){
            this.username = this.$store.state.user
            Api().get('/user/current/'+ this.username._id)
                .then(resultat => {
                    //console.log(resultat)
                    this.currentuser = resultat.data;

                })
        }else{
            this.$store.dispatch("setUser", {});
            this.$router.go(this.$router.push("/login"));
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizePopoverWidth)
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.toolbar {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .username {
        margin-left: 20px;
        font-weight: bold;
        @include text-bordered-shadow();

        a {
            color: $text-color;
            text-decoration: none;

            &:hover {
                color: $text-color-accent;
            }
        }
    }

    .avatar {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        border: 1px solid #fff;
        margin-left: 20px;
        box-sizing: border-box;
        display: block;
        cursor: pointer;
        box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        transition: box-shadow 0.5s;

        &:hover {
            box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08), 0px 2px 7px 0 rgba(0, 0, 0, 0.08);
        }
    }

    .notification-icon {
        font-size: 20px;
        outline: none;
        padding: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        //color: #aab7c5;
        color: transparentize($text-color, 0.7);
        @include text-bordered-shadow();

        &:hover {
            color: $text-color-accent;
        }
    }

    .toggle-sidebar {
        border: 1px solid transparent;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        box-sizing: border-box;
        text-align: center;
        margin: 0;
        outline: none;
        margin-right: 5px;
        font-size: 24px;
        padding: 0;
        cursor: pointer;
        display: inline-block;
        color: $text-color;
        background: white;
        display: none;
        opacity: 0;
        transition: all 0.5s;

        &__right {
            margin-right: 0px;
            margin-left: 5px;
        }

        &:hover,
        &:focus,
        &:active {
            color: $text-color-accent;
            border-color: $text-color-accent;
        }
    }
    
 .logout-button {
        font-size: 24px;
        cursor: pointer;
        outline: none;
        padding: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        //color: #aab7c5;
        color: transparentize($text-color, 0.7);
        @include text-bordered-shadow();

        &:hover {
            color: $text-color-accent;
        }
    }
    .fullscreen-button {
        font-size: 24px;
        cursor: pointer;
        outline: none;
        padding: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        //color: #aab7c5;
        color: transparentize($text-color, 0.7);
        @include text-bordered-shadow();

        &:hover {
            color: $text-color-accent;
        }
    }

    .logo-mini {
        width: 32px;
        height: 32px;
        display: none;
    }

    .el-dropdown {
        .flag-icon {
            box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
            cursor: pointer;
            border: 1px solid lighten($background-color, 20%);
            background-color: lighten($background-color, 20%);
        }
    }
}

@media (max-width: 650px) {
    .toolbar {
        .username {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .toolbar {
        padding: 0 10px;

        .toggle-sidebar {
            display: block;
            opacity: 1;
        }

        .fullscreen-button {
            display: none;
        }

        .logo-mini {
            display: inherit;
        }
    }
}
</style>
