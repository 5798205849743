<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Paiement<span style="color:#ffa409"> cash express</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Rechercher par numéro de devis..." prefix-icon="el-icon-search" v-model="numero" clearable>
          <el-button @click="SearchContrat()" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input placeholder="Rechercher par numéro de devis..." prefix-icon="el-icon-search" v-model="numero"
            clearable>
            <el-button @click="SearchContrat()" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>


    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready && listcontrat.length > 0"
        @selection-change="handleSelectionChange">
        <el-table-column label="Date souscription" min-width="150" fixed prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Numéro devis" min-width="200" prop="numeroDevis"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date Effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echeance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>

        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            {{ scope.row.prime_ttc.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="200" prop="contractLink">
          <template slot-scope="scope">
            {{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="70">
          <template slot-scope="scope">
            <el-button size="mini" @click="SetdetailsContrat(scope.row)" type="primary" icon="el-icon-check"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table :data="listInPage" style="width: 100%" v-if="ready && listcontrat.length > 0"
        @selection-change="handleSelectionChange">
        <el-table-column label="Date souscription" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Numéro devis" min-width="200" prop="numeroDevis"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date Effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echeance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>

        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            {{ scope.row.prime_ttc.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="200" prop="contractLink">
          <template slot-scope="scope">
            {{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="70">
          <template slot-scope="scope">
            <el-button size="mini" @click="SetdetailsContrat(scope.row)" type="primary" icon="el-icon-check"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détail du lien" :visible.sync="showdevis"
      v-if="showdevis == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails lien</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.createdDay)
                  }}</span></li>
                  <li>Client : <span class="to-right assuraf-orange">{{ detailsDevis.userid.prenom }} - {{
                    detailsDevis.userid.nom }}</span></li>
                  <li>Branche : <span class="to-right assuraf-orange">{{ detailsDevis.brancheid.branche }}</span></li>
                  <li v-if="detailsDevis.expired == true">Etat : <span class="to-right assuraf-orange">Déja
                      utilisé</span></li>
                  <li v-if="detailsDevis.expired == false">Etat : <span class="to-right assuraf-orange">Non
                      utilisé</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails Prime</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{
                    detailsDevis.devisid.prime_nette.toLocaleString() }} Fcfa</span>
                  </li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsDevis.devisid.prime_ttc.toLocaleString()
                  }} Fcfa</span>
                  </li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsDevis.devisid.taxe.toLocaleString() }}
                      Fcfa</span></li>
                  <li>Accessoires <sapn class="to-right assuraf-orange">
                      {{ detailsDevis.devisid.accessoires.toLocaleString() }} Fcfa</sapn>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détail du lien" :visible.sync="showdevis"
      v-if="showdevis == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails lien</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.createdDay)
                  }}</span></li>
                  <li>Client : <span class="to-right assuraf-orange">{{ detailsDevis.userid.prenom }} - {{
                    detailsDevis.userid.nom }}</span></li>
                  <li>Branche : <span class="to-right assuraf-orange">{{ detailsDevis.brancheid.branche }}</span></li>
                  <li v-if="detailsDevis.expired == true">Etat : <span class="to-right assuraf-orange">Déja
                      utilisé</span></li>
                  <li v-if="detailsDevis.expired == false">Etat : <span class="to-right assuraf-orange">Non
                      utilisé</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails Prime</span>
              </div>
              <div>
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{
                    detailsDevis.devisid.prime_nette.toLocaleString() }} Fcfa</span>
                  </li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsDevis.devisid.prime_ttc.toLocaleString()
                  }} Fcfa</span>
                  </li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsDevis.devisid.taxe.toLocaleString() }}
                      Fcfa</span></li>
                  <li>Accessoires <sapn class="to-right assuraf-orange">
                      {{ detailsDevis.devisid.accessoires.toLocaleString() }} Fcfa</sapn>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Liste des passagers" :visible.sync="modalInfoVoyageur"
      v-if="modalInfoVoyageur == true && !isMobile">
      <div class="grid-content bg-purple">
        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-show="passportStep">
          <h3>Choisir mes passeports ou nouveau passeport :</h3>
          <el-row justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-radio @change="myPassportValidator()" v-model="myPassports" size="medium" label="mes_passport">Mes
                  passeports</el-radio>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content bg-purple-light">
                <el-radio @change="myPassportValidator()" v-model="myPassports" label="nouveau">Nouveau passeport
                </el-radio>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-show="myPassportList">
          <el-table :data="listPassports" class="padded-table" height="200" style="width: 100%" row-key="id">
            <el-table-column prop="prenom_passager" label="Prénom" min-width="200"> </el-table-column>
            <el-table-column prop="nom_passager" label="Nom" min-width="200"> </el-table-column>
            <el-table-column prop="nationalite_passager" label="Nationalité" min-width="200"> </el-table-column>
            <el-table-column prop="numero_ci_passager" label="Numéro passeport" min-width="200"> </el-table-column>
            <el-table-column prop="dateNaissance" label="Date de naissance" min-width="200">
              <template slot-scope="scope">
                {{ DateFormater(scope.row.dateNaissance) }}
              </template>
            </el-table-column>
            <el-table-column prop="date_delivrance" label="Date de délivrance" min-width="200">
              <template slot-scope="scope">
                {{ DateFormater(scope.row.date_delivrance) }}
              </template>
            </el-table-column>
            <el-table-column prop="date_expiration" label="Date d'expiration" min-width="200">
              <template slot-scope="scope">
                {{ DateFormater(scope.row.date_expiration) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actions" prop="action" min-width="120" v-if="!isMobile">
              <template slot-scope="scope">
                <el-button @click="getPassportForDevis(scope.row)" type="primary" icon="el-icon-right" circle
                  title="Voir détails"></el-button>
                <el-button @click="deletePassport(scope.row)" type="danger" icon="el-icon-delete" circle
                  title="Désarchiver"></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Actions" prop="action" min-width="120" v-if="isMobile">
              <template slot-scope="scope">
                <el-button @click="getPassportForDevis(scope.row)" type="primary" icon="el-icon-right" circle
                  title="Voir détails"></el-button>
                <el-button @click="deletePassport(scope.row)" type="danger" icon="el-icon-delete" circle
                  title="Désarchiver"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="mb-5" style="margin-top: 15px">
            <el-button round @click="backToPassportStep()">Retour</el-button>
          </div>
        </div>

        <el-card class="box-card" v-show="newPassport">
          <div>
            <el-row :gutter="15">
              <el-col :span="8">
                <div>
                  <el-row justify="center">
                    <el-col>
                      <div class="grid-content bg-purple">
                        <el-radio v-model="genre" size="medium" label="Femme">Madame</el-radio>
                      </div>
                    </el-col>
                    <el-col>
                      <div class="grid-content bg-purple-light">
                        <el-radio v-model="genre" label="Homme">Monsieur</el-radio>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Nom</label>
                  <el-input v-model="nom_passager" placeholder="Votre nom" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Prénom</label>
                  <el-input v-model="prenom_passager" placeholder="Votre prénom" class="input-with-select"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="8">
                <div>
                  <label>Numéro de téléphone</label>
                  <el-input type="number" v-model="num_passager" placeholder="Votre numéro de téléphone"
                    class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Date de naissance</label>
                  <el-input disabled v-model="datenaissance" placeholder="Votre date de naissance"
                    class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Nationalité</label>
                  <el-select style="width: 100%!important;" v-model="nationalite_passager" placeholder="Selectionner">
                    <el-option v-for="items in listpays" :value="items.PAYS" :key="items.PAYS" :label="items.PAYS">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="8">
                <div>
                  <label>Numéro passeport</label>
                  <el-input v-model="numero_ci_passager" placeholder="Votre numéro de passeport"
                    class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Date de délivrance</label>
                  <el-date-picker style="width: 100%!important;" v-model="date_delivrance" type="date"
                    placeholder="Choississez une date">
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Date d'expiration</label>
                  <el-date-picker style="width: 100%!important;" v-model="date_expiration" type="date"
                    placeholder="Choississez une date">
                  </el-date-picker>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="mb-5" style="margin-top: 15px">
            <el-button type="primary" round @click="Detail_info_passager()">Ajouter</el-button>
            <el-button round @click="backToPassportStep()">Retour</el-button>
          </div>
          <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready"
            style="margin-top:15px!important">
            <el-table :data="listInPagePassager" style="width: 100%" height="150px" v-if="ready"
              v-show="listcontrat.length > 0" @selection-change="handleSelectionChange">
              <el-table-column label="Nom" min-width="150" fixed prop="nom_passager"></el-table-column>
              <el-table-column label="Prénom" min-width="150" prop="prenom_passager"></el-table-column>
              <el-table-column label="Date de naissance" min-width="150" prop="dateNaissance">
                <template slot-scope="scope">
                  {{ DateFormater(scope.row.dateNaissance) }}
                </template>
              </el-table-column>
              <el-table-column label="Numéro passeport" min-width="150" prop="numero_ci_passager"></el-table-column>
              <el-table-column label="Nationalité" min-width="150" prop="nationalite_passager"></el-table-column>
              <el-table-column label="Date de délivrance" min-width="200" prop="date_delivrance">
                <template slot-scope="scope">
                  {{ DateFormater(scope.row.date_delivrance) }}
                </template>
              </el-table-column>
              <el-table-column label="Date de d'expiration" min-width="200" prop="date_expiration" fixed="right">
                <template slot-scope="scope">
                  {{ DateFormater(scope.row.date_expiration) }}
                </template>
              </el-table-column>
            </el-table>

            <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
              :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
              :total="totalPassager"></el-pagination>
          </div>
          <div class="mb-5" style="margin-top: 15px" v-show="continue_btn">
            <el-button type="primary" round @click="ContratVoyage(detailsContrat)">Continuer</el-button>
          </div>
        </el-card>
      </div>


    </el-dialog>

    <el-dialog width="90%!important" title="Liste des passager" :visible.sync="modalInfoVoyageur"
      v-if="modalInfoVoyageur == true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Nom</label>
                <el-input v-model="nom_passager" placeholder="Votre nom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Prénom</label>
                <el-input v-model="prenom_passager" placeholder="Votre prénom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date de naissance</label>
                <el-input disabled v-model="datenaissance" placeholder="Votre date de naissance"
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Nationalité</label>
                <el-select style="width: 100%!important;" v-model="nationalite_passager" placeholder="Selectionner">
                  <el-option v-for="items in listpays" :value="items.PAYS" :key="items.PAYS" :label="items.PAYS">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Numéro passeport</label>
                <el-input v-model="numero_ci_passager" placeholder="Votre numéro de passeport"
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date de délivrance</label>
                <el-date-picker style="width: 100%!important;" v-model="date_delivrance" type="date"
                  placeholder="Choississez une date">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date d'expiration</label>
                <el-date-picker style="width: 100%!important;" v-model="date_expiration" type="date"
                  placeholder="Choississez une date">
                </el-date-picker>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="Detail_info_passager()">Ajouter</el-button>
          <el-button type="danger" round @click="modalInfoVoyageur = false">Annuler</el-button>
        </div>
      </div>

      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready">
        <el-table :data="listInPagePassager" style="width: 100%" height="150px" v-if="ready"
          v-show="listcontrat.length > 0" @selection-change="handleSelectionChange">
          <el-table-column label="Nom" min-width="150" fixed prop="nom_passager"></el-table-column>
          <el-table-column label="Prénom" min-width="150" prop="prenom_passager"></el-table-column>
          <el-table-column label="Date de naissance" min-width="150" prop="dateNaissance">
            <template slot-scope="scope">
              {{ DateFormater(scope.row.dateNaissance) }}
            </template>
          </el-table-column>
          <el-table-column label="Numéro passeport" min-width="150" prop="numero_ci_passager"></el-table-column>
          <el-table-column label="Nationalité" min-width="150" prop="nationalite_passager"></el-table-column>
          <el-table-column label="Date de délivrance" min-width="200" prop="date_delivrance">
            <template slot-scope="scope">
              {{ DateFormater(scope.row.date_delivrance) }}
            </template>
          </el-table-column>
          <el-table-column label="Date de d'expiration" min-width="200" prop="date_expiration" fixed="right">
            <template slot-scope="scope">
              {{ DateFormater(scope.row.date_expiration) }}
            </template>
          </el-table-column>
        </el-table>

        <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
          :total="totalPassager"></el-pagination>
      </div>
      <div class="mb-5" style="margin-top: 15px" v-show="continue_btn">
        <el-button type="primary" round @click="ContratVoyage(detailsContrat)">Continuer</el-button>
      </div>
    </el-dialog>


    <el-dialog width="50%!important" title="Confirmation contrat" :visible.sync="dialogConfirmContrat"
      v-if="dialogConfirmContrat == true">
      <div class="grid-content bg-purple">
        <h3 style="color:#ffa409;text-align:center">Voulez-vous confirmer la création du contrat Assur'Care</h3>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" @click="ConfirmContrat()" round>Confirmer</el-button>
          <el-button type="danger" round @click="dialogConfirmContrat = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>


    <!-- =================== Ajouter des bénéficiaire ouChoisir mes Bénénficiaires ===================== -->
    <el-dialog width="70%!important" title="Mes bénéficiaires" :visible.sync="beneficiariesChoice"
      v-if="beneficiariesChoice == true">
      <div class="grid-content bg-purple">
        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-show="beneficiarieStep">
          <h3>Choisir mes bénéficiaires ou ajouter un nouveau bénéficiaire :</h3>
          <el-row justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-radio v-model="myBeneficiaries" @change="getListBeneficiarie()" size="medium" label="my_beneficiarie">
                  Mes bénéficiaires
                </el-radio>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content bg-purple-light">
                <el-radio v-model="myBeneficiaries" @change="myBeneficiarieValidator()" label="new_beneficiarie">
                  Nouveau bénéficiaire
                </el-radio>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="grid-content bg-purple" v-show="newBeneficiarie">
          <el-card class="box-card">
            <p class="text-center">Le souscripteur fait partie des assurés ?</p>
            <div class="justify-content-center row mb-5">
              <el-switch :disabled="disable_switch_souscriptor" v-model="isAssure" active-text="Non" inactive-text="Oui"
                style="display: block;margin: 0 auto;text-align: center;" @change="souscriptorIsAssure()"></el-switch>
            </div>


            <el-row :gutter="15" class="mt-3">
              <el-col :span="8">
                <div>
                  <label>Nom</label>
                  <el-input v-model="AssureFields.Nom" placeholder="Votre nom" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Prénom</label>
                  <el-input v-model="AssureFields.Prenom" placeholder="Votre prénom" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Date de naissance</label>
                  <el-date-picker :picker-options="dateBeforeToday" style="width: 100%!important;"
                    v-model="AssureFields.dateNaissance" type="date" placeholder="Choississez une date">
                  </el-date-picker>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="15" style="margin-top:15px">
              <el-col :span="12">
                <div>
                  <label>CNI</label>
                  <el-input v-model="AssureFields.CNI" placeholder="Numéro CNI" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div>
                  <label>Assuré</label>
                  <el-select style="width: 100%!important;" v-model="AssureFields.age"
                    placeholder="Selectionner votre sexe">

                    <el-option :disabled="disabled_homme"
                      v-if="this.detailsContrat.details_pack_sante.assures.Adulte.Homme > 0" label="Homme"
                      value="Adulte_homme">Homme <span style="color: #ffa409!important;">({{
                        NombreHomme }})</span></el-option>
                    <el-option :disabled="disabled_femme"
                      v-if="this.detailsContrat.details_pack_sante.assures.Adulte.Femme > 0" label="Femme"
                      value="Adulte_femme">Femme <span style="color: #ffa409">({{ NombreFemme }})</span>
                    </el-option>

                    <el-option :disabled="disabled_garcon"
                      v-if="this.detailsContrat.details_pack_sante.assures.Enfant.Garcon > 0" label="Garçon"
                      value="garcon">Garçon <span style="color: #ffa409">({{ NombreGarcon }})</span>
                    </el-option>

                    <el-option :disabled="disabled_fille"
                      v-if="this.detailsContrat.details_pack_sante.assures.Enfant.Fille > 0" label="Fille" value="fille">
                      Fille <span style="color: #ffa409">({{ NombreFille }})</span>
                    </el-option>

                    <el-option :disabled="disabled_homme_senior"
                      v-if="this.detailsContrat.details_pack_sante.assures.Senior.Homme > 0" label="Homme Sénior"
                      value="homme">Homme sénior <span style="color: #ffa409">({{ NombreSeniorHomme }})</span>
                    </el-option>

                    <el-option :disabled="disabled_femme_senior"
                      v-if="this.detailsContrat.details_pack_sante.assures.Senior.Femme > 0" label="Femme Sénior"
                      value="femme">Femme sénior <span style="color: #ffa409">({{ NombreSeniorFemme }})</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </el-card>

          <div class="mb-5" style="margin-top: 15px">
            <el-button v-show="newBeneficiarie" round @click="backToBeneficiarieStep()">Fermer</el-button>
            <el-button v-if="newBeneficiarie"
              v-show="nbAssureAdded < detailsContrat.details_pack_sante.assures.nombre_assures" type="primary" round
              @click="CreateListAssure()">
              Ajouter ({{ nbAssureAdded }} / {{ detailsContrat.details_pack_sante.assures.nombre_assures }})
            </el-button>

            <el-button v-show="nbAssureAdded == detailsContrat.details_pack_sante.assures.nombre_assures" type="primary"
              round @click="setListeAssureToUpdate(detailsContrat)">
              Valider
            </el-button>
          </div>
        </div>

        <div v-if="myBeneficiarieList">
          <el-table :data="ListAssure" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column label="Nom" min-width="150" prop="nom"></el-table-column>
            <el-table-column label="Prenom" min-width="200" prop="prenom"></el-table-column>
            <el-table-column label="CNI" min-width="150" prop="numero_cni"></el-table-column>
            <el-table-column label="Age/Sexe" min-width="150" prop="genre"></el-table-column>
            <el-table-column label="Date de Naissance" min-width="150" prop="datenaissance">
              <template slot-scope="scope">
                {{ DateFormater(scope.row.datenaissance) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
              <template slot-scope="scope">
                <el-button size="mini" @click="SetBeneficiarieToUpdate(scope.row)" type="primary" icon="el-icon-edit"
                  circle></el-button>
                <el-button size="mini" @click="DeleteBeneficiarie(scope.row)" type="danger" icon="el-icon-delete"
                  circle></el-button>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Cocher" prop="cocher" min-width="100">
              <template slot-scope="scope">
                <!-- <el-checkbox v-model="cheked_benef" :label="scope.row">Choisir</el-checkbox> -->
                <el-checkbox v-model="selectionedBeneficiarie" :label="scope.row">Choisir</el-checkbox>
              </template>
            </el-table-column>
          </el-table>

          <div class="mb-5" style="margin-top: 15px">
            <el-button v-show="myBeneficiarieList" round @click="backToBeneficiarieStep()">Fermer</el-button>
            <el-button v-show="BeneficiarieCible.length < detailsContrat.details_pack_sante.assures.nombre_assures"
              type="primary" round @click="ajouterBenef()">
              Ajouter ({{ BeneficiarieCible.length }} / {{ detailsContrat.details_pack_sante.assures.nombre_assures }})
            </el-button>
            <el-button v-show="BeneficiarieCible.length == detailsContrat.details_pack_sante.assures.nombre_assures"
              type="primary" round @click="setListeAssureToUpdate(detailsContrat)">
              Valider
            </el-button>
          </div>
        </div>

        <div class="grid-content bg-purple" v-if="editBeneficiarieDialog">
          <el-card class="box-card">
            <el-row :gutter="15" class="mt-3">
              <el-col :span="8">
                <div>
                  <label>Nom</label>
                  <el-input v-model="TheBeneficiarie.nom" placeholder="Votre nom" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Prénom</label>
                  <el-input v-model="TheBeneficiarie.prenom" placeholder="Votre prénom"
                    class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Date de naissance</label>
                  <el-date-picker :picker-options="dateBeforeToday" style="width: 100%!important;"
                    v-model="TheBeneficiarie.datenaissance" type="date" placeholder="Choississez une date">
                  </el-date-picker>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="15" style="margin-top:15px">
              <el-col :span="12">
                <div>
                  <label>CNI</label>
                  <el-input v-model="TheBeneficiarie.numero_cni" placeholder="Numéro CNI"
                    class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div>
                  <label>Assuré</label>
                  <el-select style="width: 100%!important;" v-model="TheBeneficiarie.genre"
                    placeholder="Selectionner votre sexe">
                    <el-option label="Homme" value="Adulte_homme">Homme</el-option>
                    <el-option label="Femme" value="Adulte_femme">Femme</el-option>
                    <el-option label="Garçon" value="garcon">Garçon</el-option>
                    <el-option label="Fille" value="fille">Fille</el-option>
                    <el-option label="Homme Sénior" value="homme">Homme sénior</el-option>
                    <el-option label="Femme Sénior" value="femme">Femme sénior</el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>

            <el-button v-show="myBeneficiarieList" round
              @click="editBeneficiarieDialog = false, myBeneficiarieList = true">Fermer</el-button>
            <el-button type="primary" round @click="UpdateBeneficiarie()" class="mt-5">
              Modifier
            </el-button>
          </el-card>
        </div>
      </div>
    </el-dialog>

    <!-- coupon de reduction -->
    <el-dialog title="Réduction commerciale" width="50%" :visible.sync="reduction.dialogCodeCoupon"
      v-if="reduction.dialogCodeCoupon == true">
      <div class="grid-content bg-purple">


        <div slot="header" class="clearfix">
          <span style="margin-top: 15px;">Avez vous un coupon de reduction ? <el-switch
              style="margin-top: 15px; display: block" v-model="reduction.checkbox" active-color="#13ce66"
              inactive-color="#ff4949" active-text="Oui" inactive-text="Non">
            </el-switch></span>

          <el-col style="margin: 20px 0px;">
            <label>Code de reduction </label>
            <el-input :disabled="reduction.checkbox == false" placeholder="Code de reduction"
              v-model="reduction.code_coupon">
            </el-input>
          </el-col>
        </div>

      </div>
      <div>
        <el-button type="primary"  v-loading.fullscreen.lock="modalgenerationloading" round @click="toggleCheckbox()">Continuer</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import helsper from '../../../services/helper'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      devis_tmp: null,
      modalgenerationloading: false,
      // coupon de reduction
      reduction: {
        checkbox: false,
        resultCodeCoupon: false,
        dialogCodeCoupon: false,
        code_coupon: "",
      },
      beneficiarieStep: true,
      myBeneficiaries: null,
      newBeneficiarie: false,
      myBeneficiarieList: false,
      searchBeneficiarieDesktop: "",
      selectedBenef: false,


      BeneficiarieCible: [],
      selectionedBeneficiarie: [],
      TheBeneficiarie: null,
      editBeneficiarieDialog: false,
      showPopUpBeneficiaireExistantSante: false,


      cheked_benef: [],
      beneficiariesChoice: false,
      editBeneficiarieDialog: false,
      TheBeneficiarie: null,
      isAssure: false,
      disable_switch_souscriptor: false,
      valideSenior: false,
      valideEnfant: false,
      valideAdulte: false,
      NombreFemme: 0,
      NombreHomme: 0,
      NombreGarcon: 0,
      NombreFille: 0,
      NombreSeniorFemme: 0,
      NombreSeniorHomme: 0,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateAfterToday: {
        disabledDate(time) {
          return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
        }
      },
      dialogConfirmContrat: false,
      // Assure fields
      AssureFields: {
        Nom: null,
        Prenom: null,
        dateNaissance: null,
        CNI: null,
        age: null,
      },
      ListAssure: [],
      listAssure: [],
      dialogInfoAssure: false,
      dialogInfoAssureExistant: false,
      ageAssure: null,
      nbAssureAdded: 0,
      showAdulte: true,
      showEnfant: true,
      showSenior: true,
      disabled_homme: false,
      disabled_femme: false,
      disabled_garcon: false,
      disabled_fille: false,
      disabled_homme_senior: false,
      disabled_femme_senior: false,
      nbreAdulteHomme: 0,
      nbreAdulteFemme: 0,
      nbreEnfantGarcon: 0,
      nbreEnfantFille: 0,
      nbreSeniorHomme: 0,
      nbreSeniorFemme: 0,
      foundForHomme: null,
      foundForFemme: null,
      foundForGarcon: null,
      foundForFille: null,
      foundForHommeSenior: null,
      foundForFemmeSenior: null,




      passportStep: true,
      myPassportList: false,
      myPassports: null,
      newPassport: false,
      usersPasseport: [],
      listPassports: [],
      PassportDetails: [],
      tableau_info_passager_from_list: [],

      headerContrat: [
        {
          text: "Prénom",
          sortable: false,
          value: "prenom_passager"
        },
        {
          text: "Nom",
          sortable: false,
          value: "nom_passager"
        },
        {
          text: "Nationalité",
          sortable: false,
          value: "nationalite_passager"
        },
        {
          text: "Num Passport",
          sortable: false,
          value: "numero_ci_passager"
        },
        {
          text: "date Naissance",
          sortable: false,
          value: "dateNaissance"
        },
        {
          text: "Date délivrance",
          sortable: false,
          value: "date_delivrance"
        },
        {
          text: "Date expiration",
          sortable: false,
          value: "date_expiration"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      headerContratMobile: [
        {
          text: "Prénom",
          sortable: false,
          value: "prenom_passager"
        },
        {
          text: "Nom",
          sortable: false,
          value: "nom_passager"
        },
        {
          text: "Num passport",
          sortable: false,
          value: "numero_ci_passager"
        }
      ],
      debut: null,
      fin: null,
      client_name: '',
      client_firstname: '',
      client_email: '',
      client_phone: '',
      products_ids: '',
      listcontrat: [],
      numero: '',
      HideSearchDevis: false,
      NotFound: false,
      showDetail: false,
      CheckPayment: true,
      showcontrat: false,
      dateTransaction: null,
      used: false,
      detailsContrat: [],
      modalInfoBenef: false,
      TraceTranc: null,
      listpays: null,
      ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
      }, // Customized options for success toast
      ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
      }, // Customized options for error toast
      fields: [
        {
          key: 'dateSouscription',
          label: 'Date Souscription'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'numeroDevis',
          label: 'Numero'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echéance'
        },
        {
          key: 'prime_ttc',
          label: 'Prime TTC'
        },
        {
          key: 'userid',
          label: 'Client'
        },
        {
          key: 'action',
          label: 'Action'
        },
      ],
      MoyenSelected: [], // Must be an array reference!

      EnteteBenef: [
        {
          key: 'nom',
          label: 'Nom'
        },
        {
          key: 'prenom',
          label: 'Prénom'
        },
        {
          key: 'adresse',
          label: 'Adresse'
        },
        {
          key: 'telephone',
          label: 'Téléphone'
        },
        {
          key: 'numero_cni',
          label: 'Numéro CNI'
        },
        {
          key: 'pays',
          label: 'Nationalté'
        },
      ],

      EnteteListTracePays: [
        {
          key: 'datePayement',
          label: 'Date'
        },
        {
          key: 'numeroDevis',
          label: 'Devis'
        },
        {
          key: 'STATUT',
          label: 'STATUT'
        },
        {
          key: 'moyen_paiement',
          label: 'Moyen'
        },
        {
          key: 'montant',
          label: 'Montant'
        },
        {
          key: 'nom_client',
          label: 'Nom Client'
        },
        {
          key: 'prenom_client',
          label: 'Prénom Client'
        },
        {
          key: 'telephone_client',
          label: 'Téléphone Client'
        },
        {
          key: 'email_client',
          label: 'Email Client'
        },
      ],
      listPassager: [],
      modalInfoVoyageur: false,
      continue_btn: false,
      tableau_info_passager: [],
      num_passager: null,
      genre: null,
      nom_passager: null,
      prenom_passager: null,
      nationalite_passager: null,
      numero_ci_passager: null,
      dateNaissance: null,
      datenaissance: null,
      date_delivrance: null,
      date_expiration: null,
      showValiderBtn: false,
      tab_perso: [],
      maxPersonne: null,
      nom: null,
      prenom: null,
      adresse: null,
      telephone: null,
      numero_cni: null,
      pays: null,
      editUser: false,
      Selectedbranche: null,
      selectedAssureur: [],
      configUserDevisAssureur: null,
      assureurs: [],
      branchelist: null,
      modalSociete: false,
      showdevis: false,
      configHead: [
        { key: "branche", label: "branche" },
        { key: "assureurs", label: "assureur" },
        { key: "action", label: "Action" }
      ],
      selectedUser: null,
      listeAssureurs: null,
      usermenu: [],
      collapse: null,
      menu_name: null,
      showConfigMenu: false,
      submenu: null,
      userSubmenu: [],
      UserFinalMenu: [],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader: [
        { key: "theme", label: "Libéllé" },
        { key: "collapse", label: "Collapse" },
        { key: "sousmenu", label: "Sous menu" },
        { key: "action", label: "action" }
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listcontrat,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },

  computed: {
    listFiltered() {
      return this.listcontrat.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },

    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },

    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },

    total() {
      return this.listFiltered.length
    },

    listFilteredPassager() {
      return this.tableau_info_passager.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },

    listSorteredPassager() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFilteredPassager.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },

    listInPagePassager() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listFilteredPassager.slice(from, to)
    },

    totalPassager() {
      return this.listFilteredPassager.length
    },


    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },

  watch: {
    cheked_benef() {
      let detailsPopulation = this.detailsContrat.details_pack_sante.assures
      let checker = helsper.check_benefifiar(detailsPopulation, this.cheked_benef)

    },
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },

  methods: {
    toggleCheckbox() {
      if (this.reduction.checkbox) {
        if (this.reduction.code_coupon != "") {
          
          Api().post("/coupons/check/reduction", {
            code_coupon: this.reduction.code_coupon,
            numero_police: this.detailsContrat.numeroDevis || null,
            brancheid: this.detailsContrat.brancheid._id || null,
            partenaireid: this.detailsContrat.partenaireid._id || null,
            courtierid: this.detailsContrat.courtierid || null,
            dateSouscription: this.detailsContrat.dateSouscription,
            prime_ttc: this.detailsContrat.prime_ttc,
            plateforme: this.detailsContrat.plateforme,
            clientid: this.detailsContrat.clientid,
            dateEffet: this.detailsContrat.dateEffet
          
          }).then(result => {
            this.reduction.dialogCodeCoupon = false;
            this.reduction.resultCodeCoupon = true;
            if(result.data.status){
              const details = {
              ...this.detailsContrat,
              code_coupon: this.reduction.code_coupon
            }
            this.SetdetailsContrat(details)
            }
          }).catch((error) => {
            this.$message.error( error.response.data.message || 'Aucun coupon n\'est disponible pour le code coupon !', this.AlertMessage)
          })

        } else {
          this.$message.error('le code coupon est obligatoire !', this.AlertMessage)
        }
      } else {
        this.reduction.dialogCodeCoupon = false;
        this.reduction.resultCodeCoupon = true;
        this.SetdetailsContrat(this.detailsContrat)
      }


    },

    backToBeneficiarieStep() {
      this.myBeneficiarieList = false
      this.newBeneficiarie = false
      this.beneficiarieStep = true
      //this.myPassports = null
    },

    getListBeneficiarie() {
      this.beneficiarieStep = false;
      this.myBeneficiarieList = true;
      this.getBeneficiaries(store.state.user._id)
    },

    myBeneficiarieValidator() {
      if (this.myBeneficiaries == 'new_beneficiarie') {
        this.newBeneficiarie = true
        this.beneficiarieStep = false
      }
    },

    ajouterBenef() {
      let Adulte = this.detailsContrat.details_pack_sante.assures.Adulte;
      let Enfant = this.detailsContrat.details_pack_sante.assures.Enfant;
      let Senior = this.detailsContrat.details_pack_sante.assures.Senior;

      if (this.BeneficiarieCible.length < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
        for (let benef of this.selectionedBeneficiarie) {
          if (benef.genre == "Homme") {
            if (Adulte.Homme != 0) {
              this.BeneficiarieCible.push(benef);
              Adulte.Homme--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }

          if (benef.genre == "Femme") {
            if (Adulte.Femme != 0) {
              this.BeneficiarieCible.push(benef);
              Adulte.Femme--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }

          if (benef.genre == "Garçon") {
            if (Enfant.Garcon != 0) {
              this.BeneficiarieCible.push(benef);
              Enfant.Garcon--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }

          if (benef.genre == "fille") {
            if (Enfant.Fille != 0) {
              this.BeneficiarieCible.push(benef);
              Enfant.Fille--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }

          if (benef.genre == "Homme sénior") {
            if (Senior.Homme != 0) {
              this.BeneficiarieCible.push(benef);
              Senior.Homme--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }

          if (benef.genre == "Femme sénior") {
            if (Senior.Femme != 0) {
              this.BeneficiarieCible.push(benef);
              Senior.Femme--
              this.$message.success('Bénéficiaire ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout comme bénéficiaire impossible pour ce devis !', this.AlertMessage)
            }
          }
        }
      } else {
        this.$message.error("Vous avez ajouté le nombre d'assurés total pour ce devis !", this.AlertMessage)
        this.showAddButton = false;
      }
    },

    souscriptorIsAssure() {
      if (this.isAssure != true) {
        this.ClearFormAssure()
      } else {
        this.AssureFields.Prenom = this.detailsContrat.userid.prenom
        this.AssureFields.Nom = this.detailsContrat.userid.nom
        this.AssureFields.CNI = this.detailsContrat.userid.numero_piece
        this.AssureFields.dateNaissance = this.detailsContrat.userid.datenaissance
        this.yearsDiffAssure()
      }
    },

    ConfirmContrat() {
      this.dialogInfoAssure = false
      let objetContrat = {
        numero_police: this.detailsContrat.numeroDevis,
        userid: this.detailsContrat.userid._id,
        createur: store.state.user._id,
        brancheid: this.detailsContrat.brancheid._id,
        partenaireid: this.detailsContrat.partenaireid._id,
        devisid: this.detailsContrat._id,
        dateEffet: this.detailsContrat.dateEffet,
        dateEcheance: this.detailsContrat.dateEcheance,
        dateSouscription: this.detailsContrat.dateSouscription,
        isExpress: true,
        taxe: this.detailsContrat.taxe,
        prime_nette: this.detailsContrat.prime_nette,
        prime_ttc: this.detailsContrat.prime_ttc,
        bonus: this.detailsContrat.bonus,
        accessoires: this.detailsContrat.accessoires,
        isRegler: true,
        plateforme: "Dash",
        code_coupon: this.detailsContrat.code_coupon,
      }

     

      if (typeof this.detailsContrat.courtier != 'undefined') {
        objetContrat.courtierid = this.detailsContrat.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'YET':
              this.$router.go(this.$router.push({ name: 'cumule_contrat_alert' }))
              break;

          }
        })
    },

    async saveOneBeneficiaries(benef) {
      const benef_dt = {
        userid: benef.userid,
        nom: benef.nom,
        prenom: benef.prenom,
        genre: benef.age,
        datenaissance: benef.datenaissance,
        numero_cni: benef.numero_cni,
        pays: benef.pays,
        adresse: benef.adresse,
        telephone: benef.telephone,

      }
      const returner = await Api().post("/beneficiaries/create", benef_dt)
    },

    /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
    CreateListAssure() {

      if (this.AssureFields.Nom && this.AssureFields.Prenom && this.AssureFields.dateNaissance && this.AssureFields.age) {
        const Assure = {
          nom: this.AssureFields.Nom,
          prenom: this.AssureFields.Prenom,
          datenaissance: this.AssureFields.dateNaissance,
          numero_cni: this.AssureFields.CNI,
          age: this.AssureFields.age,
          userid: this.detailsContrat.userid._id
        };

        if (this.isAssure == true) {
          this.disable_switch_souscriptor = true
        } else {
          this.disable_switch_souscriptor = false
        }

        if (this.AssureFields.age == 'garcon') {
          this.yearsDiffAssure()
          if (this.valideEnfant == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreGarcon--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) avec succés !', this.AlertMessage)
                this.NombreGarcon--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un enfant !', this.AlertMessage)
          }
        } if (this.AssureFields.age == 'fille') {
          this.yearsDiffAssure()
          if (this.valideEnfant == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreFille--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) avec succés !', this.AlertMessage)
                this.NombreFille--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un enfant !', this.AlertMessage)
          }
        } else if (this.AssureFields.age == 'Adulte_homme') {
          this.yearsDiffAssure()
          if (this.valideAdulte == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreHomme--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) ajoutés avec succés !', this.AlertMessage)
                this.NombreHomme--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un adulte !', this.AlertMessage)
          }
        } else if (this.AssureFields.age == 'Adulte_femme') {
          this.yearsDiffAssure()
          if (this.valideAdulte == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreFemme--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) ajoutés avec succés !', this.AlertMessage)
                this.NombreFemme--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un adulte !', this.AlertMessage)
          }
        } else if (this.AssureFields.age == 'homme') {
          this.yearsDiffAssure()
          if (this.valideSenior == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreSeniorHomme--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) ajouté avec succés !', this.AlertMessage)
                this.NombreSeniorHomme--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un sénior !', this.AlertMessage)
          }
        } else if (this.AssureFields.age == 'femme') {
          this.yearsDiffAssure()
          if (this.valideSenior == true) {
            this.ListAssure.push(Assure);
            this.getHommeAssure()
            this.nbAssureAdded = this.nbAssureAdded + 1;

            if (this.detailsContrat.details_pack_sante.assures.nombre_assures > 0) {
              if (this.nbAssureAdded < this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Ajouté avec succés !', this.AlertMessage)
                this.ClearFormAssure();
                this.disableDateAssure = false
                this.NombreSeniorFemme--
              }
              if (this.nbAssureAdded == this.detailsContrat.details_pack_sante.assures.nombre_assures) {
                this.saveOneBeneficiaries(Assure)
                this.$message.success('Assuré(s) ajouté avec succés !', this.AlertMessage)
                this.NombreSeniorFemme--
              }
            } else {
              this.voidField = true
            }
          } else {
            this.$message.error('Date de naissance choisie ne correspond pas à un sénior !', this.AlertMessage)
          }
        }


      } else {
        this.$message.error('Tous les champs sont obligatoires sauf CNI !', this.AlertMessage)
      }

    },

    /*===Send of data to API to update assuré infos===*/
    setListeAssureToUpdate(Contrat) {
      if (this.myBeneficiaries == "new_beneficiarie") {
        const ASSURE = {
          devisid: this.detailsContrat._id,
          ListAssure: this.ListAssure,
        };

        Api().post('/assurcare/set_liste_assure', ASSURE)
          .then((result) => {
            if (result.nModified = 1) {
              this.dialogConfirmContrat = true
              this.$message.success('Assuré(s) ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout Assuré(s) échoué !', this.AlertMessage)
            }
          }).catch((err) => {
          });

      } else if (this.myBeneficiaries == "my_beneficiarie") {
        const ASSURE = {
          devisid: this.detailsContrat_id,
          ListAssure: this.BeneficiarieCible,
        };
        Api().post('/assurcare/set_liste_assure', ASSURE)
          .then((result) => {
            if (result.nModified = 1) {
              this.dialogConfirmContrat = true
              this.$message.success('Assuré(s) ajouté avec succès !', this.AlertMessage)
            } else {
              this.$message.error('Ajout Assuré(s) échoué !', this.AlertMessage)
            }
          }).catch((err) => {
          });
      }

    },

    yearsDiffAssure() {
      const Date60 = dayjs(this.AssureFields.dateNaissance).add(+60, 'year').format('YYYY-MM-DD')
      const Date75 = dayjs(this.AssureFields.dateNaissance).add(+74, 'year').format('YYYY-MM-DD')
      const Date59 = dayjs(this.AssureFields.dateNaissance).add(+59, 'year').format('YYYY-MM-DD')
      const Date21 = dayjs(this.AssureFields.dateNaissance).add(+21, 'year').format('YYYY-MM-DD')
      const Date20 = dayjs(this.AssureFields.dateNaissance).add(+20, 'year').format('YYYY-MM-DD')
      const dateToday = dayjs(new Date()).format('YYYY-MM-DD');

      if (this.AssureFields.age == 'Adulte_homme' || this.AssureFields.age == 'Adulte_femme') {
        if (Date21 <= dateToday && Date59 >= dateToday) {
          this.valideAdulte = true
        } else {
          this.valideAdulte = false
        }
      }

      if (this.AssureFields.age == 'homme' || this.AssureFields.age == 'femme') {
        if (Date60 <= dateToday && Date75 >= dateToday) {
          this.valideSenior = true
        } else {
          this.valideSenior = false
        }
      }

      if (this.AssureFields.age == 'fille' || this.AssureFields.age == 'garcon') {
        const DateDiff = dayjs(dateToday).diff(dayjs(Date20), 'year')
        if (DateDiff <= 0) {
          this.valideEnfant = true
        } else {
          this.valideEnfant = false
        }
      }
    },

    getHommeAssure() {
      if (this.AssureFields.age == 'Adulte_femme') {
        this.foundForFemme++
      }

      if (this.AssureFields.age == 'Adulte_homme') {
        this.foundForHomme++
      }

      if (this.AssureFields.age == 'garcon') {
        this.foundForGarcon++
      }

      if (this.AssureFields.age == 'fille') {
        this.foundForFille++
      }

      if (this.AssureFields.age == 'homme') {
        this.foundForHommeSenior++
      }

      if (this.AssureFields.age == 'femme') {
        this.foundForFemmeSenior++
      }

      if (this.foundForHomme == this.detailsContrat.details_pack_sante.assures.Adulte.Homme) {
        this.disabled_homme = true
      }

      if (this.foundForFemme == this.detailsContrat.details_pack_sante.assures.Adulte.Femme) {
        this.disabled_femme = true
      }

      if (this.foundForGarcon == this.detailsContrat.details_pack_sante.assures.Enfant.Garcon) {
        this.disabled_garcon = true
      }

      if (this.foundForFille == this.detailsContrat.details_pack_sante.assures.Enfant.Fille) {
        this.disabled_fille = true
      }

      if (this.foundForHommeSenior == this.detailsContrat.details_pack_sante.assures.Senior.Homme) {
        this.disabled_homme_senior = true
      }

      if (this.foundForFemmeSenior == this.detailsContrat.details_pack_sante.assures.Senior.Femme) {
        this.disabled_femme_senior = true
      }

    },

    ClearFormAssure: function () {
      this.AssureFields.Nom = null,
        this.AssureFields.Prenom = null,
        this.AssureFields.dateNaissance = null,
        this.AssureFields.CNI = null
      this.AssureFields.age = null

    },

    backToPassportStep() {
      this.myPassportList = false
      this.newPassport = false
      this.passportStep = true
      this.myPassports = null
    },

    deletePassport(item) {
      Api().post('/mypassports/delete', { passportid: item._id })
        .then(response => {
          if (response.status == 200) {
            this.$message.success('Passport supprimé avec succés !', this.AlertMessage)
            Api().post("mypassports/userpassport", { userid: this.detailsContrat.userid._id })
              .then(response => {
                this.listPassports = response.data.mespasseports
              });
          }
        })
        .catch(err => { })

    },

    saveUserPassport() {
      const UserPassport = {
        userid: this.detailsContrat.userid._id,
        prenom_passager: this.prenom_passager,
        nom_passager: this.nom_passager,
        nationalite_passager: this.nationalite_passager,
        numero_ci_passager: this.numero_ci_passager,
        dateNaissance: this.datenaissance,
        date_delivrance: this.date_delivrance,
        date_expiration: this.date_expiration,
        genre: this.genre

      }

      Api().post("mypassports/save", UserPassport)
        .then(response => {
          this.$message.success('Passport enregistré avec succés !', this.AlertMessage)
        });
    },

    myPassportValidator() {
      if (this.myPassports == 'nouveau') {
        this.newPassport = true
        this.passportStep = false
        this.listInPagePassager = []
      } else {
        this.passportStep = false
        this.myPassportList = true
        this.listPassports = this.usersPasseport
        this.myPassports = 'mes_passport'

        Api().post("mypassports/userpassport", { userid: this.detailsContrat.userid._id })
          .then(response => {
            this.listPassports = response.data.mespasseports
          });
      }
    },
    getListPassport() {
      this.passportStep = false
      this.myPassportList = true
      this.listPassports = this.usersPasseport
      this.myPassports = 'mes_passport'

      Api().post("mypassports/userpassport", { userid: this.detailsContrat.userid._id })
        .then(response => {
          this.listPassports = response.data.mespasseports
        });
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    getDateNaissance() {
      let dateNasiss = this.detailsContrat.listPassager[0].dateNaissance
      this.datenaissance = dateNasiss
    },

    SearchContrat() {
      if (this.numero) {

        const contrat = {
          numero: this.numero.trim()
        }
        Api().post('/devis/numeroDevis', contrat)
          .then(resp => {
            if (resp.data.devis.length > 0) {
              this.NotFound = false
              this.listcontrat = resp.data.devis

            } else {
              this.listcontrat = []
              this.NotFound = true
              this.$message.error('Aucun devis ne correspond à ce numéro !', this.AlertMessage)
              setTimeout(() => (this.NotFound = false), 3000);
            }
          })
          .catch(e => {
            e
          })
      } else {
        this.$message.error('Veillez entrer le numéro de devis !', this.AlertMessage)
      }

    },

    getListePays: function () {
      Api().get('/pays')
        .then(response => {
          this.listpays = response.data
        })
        .catch(err => {
          err
        })
    },

    SetdetailsContrat(item) {
      this.detailsContrat = item

      if (this.reduction.resultCodeCoupon) {
        this.modalgenerationloading = true
        if (item.brancheid.branche == 'Voyage') {
          this.nbPassager = item.nbpassager
          Api().post("mypassports/userpassport", { userid: this.detailsContrat.userid._id })
            .then(response => {
              this.listPassports = response.data.mespasseports
            });
          this.DispatcherContrat(this.detailsContrat)
          this.getDateNaissance()
        } else if (item.brancheid.branche == 'Santé') {

          this.NombreFemme = item.details_pack_sante.assures.Adulte.Femme;
          this.NombreHomme = item.details_pack_sante.assures.Adulte.Homme;
          this.NombreGarcon = item.details_pack_sante.assures.Enfant.Garcon;
          this.NombreFille = item.details_pack_sante.assures.Enfant.Fille;
          this.NombreSeniorHomme = item.details_pack_sante.assures.Senior.Homme;
          this.NombreSeniorFemme = item.details_pack_sante.assures.Senior.Femme;
          this.DispatcherContrat(this.detailsContrat)
        } else {
          this.DispatcherContrat(this.detailsContrat)
        }
      } else {
        this.reduction.dialogCodeCoupon = true;
      }
    },

    // FOnction permettant de recolter les info sur les passager du voyage (Si souscription a un contrat d'assurance voyage)
    Detail_info_passager() {
      if (this.nom_passager && this.prenom_passager && this.nationalite_passager && this.num_passager) {
        if (this.numero_ci_passager && this.date_delivrance && this.date_expiration && this.datenaissance && this.genre) {
          let Un_passager = {
            nom_passager: this.nom_passager,
            prenom_passager: this.prenom_passager,
            num_passager: this.num_passager,
            nationalite_passager: this.nationalite_passager,
            numero_ci_passager: this.numero_ci_passager,
            // PassengerBirthday: this.PassengerBirthday,
            dateNaissance: this.datenaissance,
            date_delivrance: this.date_delivrance,
            date_expiration: this.date_expiration,
            genre: this.genre
          }
          if (this.nbPassager > 0) {
            this.tableau_info_passager.push(Un_passager)

          }
          this.nbPassager = this.nbPassager - 1
          if (this.nbPassager === 0) {
            this.$store.dispatch('setListeBeneficiaire', this.tableau_info_passager)
            this.continue_btn = true
          }

        } else {
          this.$toasted.show('Tous les (*) sont obligatoires ! ', this.ToastTabError)
        }
      } else {
        this.$toasted.show('Tous les (*) sont obligatoires ! ', this.ToastTabError)
      }
    },

    Detail_info_beneficiaires() {
      if (this.nom && this.prenom && this.adresse) {
        if (this.telephone && this.numero_cni && this.pays) {

          let Un_beneficiaire = {
            nom: this.nom,
            prenom: this.prenom,
            adresse: this.adresse,
            telephone: this.telephone,
            numero_cni: this.numero_cni,
            pays: this.pays
          }

          if (this.tab_perso.length + 1 <= this.maxPersonne) {
            this.tab_perso.push(Un_beneficiaire)
            this.showValiderBtn = true
            this.nom = ''
            this.prenom = ''
            this.adresse = ''
            this.telephone = ''
            this.numero_cni = ''
          } else {
            this.$toasted.show('Le nombre maximum de personnes est atteind ! ', this.ToastTabError)
          }
        } else {
          this.$toasted.show('Tous les (*) sont obligatoires ! ', this.ToastTabError)
        }
      } else {
        this.$toasted.show('Tous les (*) sont obligatoires ! ', this.ToastTabError)
      }
    },

    DispatcherContrat(Contrat) {
      const checker = Contrat.numeroDevis.split("/")

      if (checker[0] == "OL")
      {
        if(helsper.offlineUserRight(store.state.user.email)){
          this.ContratOffline(Contrat)
        }else{
          alert("L'autorisation vous a été révoquée !!!")
        }
       
      } else {
        switch (Contrat.brancheid.branche) {
          case 'Automobile':
            this.ContratAuto(Contrat)
            break;
          case 'Habitation':
            this.ContratHabitat(Contrat)
            break;
          case 'Santé':
            if (Contrat.partenaireid.societe == "KERMEL") {
              this.maxPersonne = Contrat.details_pack_sante.packChoisis[0].nb_personne_max
              this.modalInfoBenef = true
            } else {
              //this.getBeneficiaries(Contrat.userid._id)
              this.beneficiariesChoice = true
            }
            break;
          case 'Voyage':
            this.modalInfoVoyageur = true
            break;
          case 'Individuel accident':
            this.ContratIndividuelAccident(Contrat)
            break;
          case 'Caution':
            this.ContracCaution(Contrat)
            break;
          case 'Vie':
            this.ContratVie(Contrat)
            break
          case 'Assur-Hospi':
            this.ContratMicroVie(Contrat)
            break
          case "Multirisque Professionnelle":
            this.ContractMrp(Contrat)
            break
          case "Tous Risques Chantier": 
            this.ContractTRC(Contrat)
            break
        }
      }
    },
    
    ContractTRC(param) {
      let objectContractTRC = {
        numero_police: param.numeroDevis,
        devisid : param._id,
        userid: param.userid.id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        createur: store.state.user._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        plateforme: param.plateforme,
        details: param.details,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        type_avenant: param.type_avenant,
        proprietaire: param.proprietaire,
        fga: param.fga,
        accessoire: param.accessoires
      }
      
      Api().post('contrat/add', objectContractTRC)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'YET':
              this.$router.go(this.$router.push({ name: 'cumule_contrat_alert' }))
              break;
          }
        }).catch(error => {
        }) 
    },

    ContractMrp(param) {
      let objectContractMrp = {
        numero_police: param.numeroDevis,
        userid: param.userid.id,
        devisid: param._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        createur: param.createur,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        plateforme: 'Dash',
        details: param.details,
        taxe: param.taxe,
        accessoires: param.accessoires,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc
      }
      
      Api().post('contrat/add', objectContractMrp)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'YET':
              this.$router.go(this.$router.push({ name: 'cumule_contrat_alert' }))
              break;
          }
        }).catch(error => {
        }) 
    },

    ContratAuto(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        proprietaire: param.proprietaire,
        dateEcheance: param.dateEcheance, 
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        fga: param.fga,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        plateforme: "Dash",
        details: {
          vehicules: param.vehicules,
          addrelivraison: 'Assur-Store'
        },
        courtierid: param.courtier,
        code_coupon: param.code_coupon,
        type_avenant: param.type_avenant,
      }

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        }).catch(e => {

        })
    },

    ContratHabitat(param) {

      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        proprietaire: param.proprietaire,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }

      let TabDetail = {
        typeBien: param.typeBien,
        nombrepiece: param.nombrepiece,
        nombreetage: param.nombreetage,
        naturecouverture: param.naturecouverture,
        adresselogement: param.adresselogement,
        environnement_immediat: param.environnement_immediat,
        valeurcontenu: param.valeurcontenu,
        valeurbatiment: param.valeurbatiment,
        qualiteassure: param.qualiteassure,
        montaLoyer: param.montaLoyer,
      }

      objetContrat.details = TabDetail

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;
            case 'YET':

              this.$toasted.show('il existe déjà un contrat pour ce devis ', this.ToastTabError)
              break;

          }
        }).catch(e => {

        })

    },

    ContratSante(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }

      if (param.partenaireid.societe == "KERMEL") {
        objetContrat.details = this.tableau_info_passager
      } else {
        objetContrat.details = param.details
      }

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        })

    },

    getPassportForDevis(item) {
      this.PassportDetails = item
      console.log('details du passepeort', this.PassportDetails)
      this.tableau_info_passager.push(this.PassportDetails)

      let DatePassport = this.PassportDetails.dateNaissance
      let DatePassager = this.detailsContrat.listPassager[0].dateNaissance


      if (DatePassport === DatePassager) {
        let objetContrat = {
          numero_police: this.detailsContrat.numeroDevis,
          userid: this.detailsContrat.userid._id,
          createur: store.state.user._id,
          brancheid: this.detailsContrat.brancheid._id,
          partenaireid: this.detailsContrat.partenaireid._id,
          devisid: this.detailsContrat._id,
          dateEffet: this.detailsContrat.dateEffet,
          dateEcheance: this.detailsContrat.dateEcheance,
          dateSouscription: this.detailsContrat.dateSouscription,
          isExpress: true,
          taxe: this.detailsContrat.taxe,
          dateNaissance: this.detailsContrat.dateNaissance,
          prime_nette: this.detailsContrat.prime_nette,
          prime_ttc: this.detailsContrat.prime_ttc,
          bonus: this.detailsContrat.bonus,
          accessoires: this.detailsContrat.accessoires,
          isRegler: true,
          details: this.tableau_info_passager,
          mode_remboursement_voyage: this.detailsContrat.mode_remboursement_voyage,
          plateforme: "Dash",
          code_coupon: this.detailsContrat.code_coupon,
        }
        
        if (typeof this.detailsContrat.courtier != 'undefined') {
          objetContrat.courtierid = this.detailsContrat.courtier
        }

        Api().post('/contrat/add', objetContrat)
          .then(response => {
            localStorage.removeItem('contrat_for_express_payment')
            switch (response.data.message) {
              case 'Contrat cree mais non envoye par email':
                this.contratToStore = response.data.contrat
                this.contratToStore.assureur = response.data.assureur
                this.contratToStore.branche = response.data.Thebranche
                this.$store.dispatch('setNewcontrat', this.contratToStore)

                this.$router.go(this.$router.push('/contrat/ok'))
                break;

              case 'Contrat cree et envoye par email':
                this.contratToStore = response.data.contrat
                this.contratToStore.assureur = response.data.assureur
                this.contratToStore.branche = response.data.Thebranche
                this.$store.dispatch('setNewcontrat', this.contratToStore)

                this.$router.go(this.$router.push('/contrat/ok'))
                break;

              case 'YET':

                this.$toasted.show('il existe déjà un contrat pour ce devis ', this.ToastTabError)
                break;

            }
          }).catch(e => {

          })
      } else {
        this.$message.error('La date de naissance du passager est différente de celle du passeport !', this.AlertMessage)
      }

    },

    ContratVoyage(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        dateNaissance: param.dateNaissance,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        details: this.tableau_info_passager,
        mode_remboursement_voyage: param.mode_remboursement_voyage,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }
      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)
              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$message.error('Il existe déjà un contrat pour ce devis !', this.AlertMessage)
              break;

          }
        }).catch(e => {

        })
      this.saveUserPassport()
    },

    ContratOffline(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        proprietaire: param.proprietaire,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        details: [],
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }
      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('assuranceOffline/contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$toasted.show('il existe déjà un contrat pour ce devis ', this.ToastTabError)
              break;

          }
        }).catch(e => {

        })
    },

    /*=== Send Individuel Accident Data to the api for create Contract ===*/
    ContratIndividuelAccident(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        accessoires: param.accessoires,
        details: param.education_plus,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        }).catch(e => {

        })
    },

    /*== Send Caution data to the api for Contract creation==*/
    ContracCaution(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        accessoires: param.accessoires,
        details: param.details,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        }).catch(e => {

        })
    },

    /*Send Education Plus data to the api for Contract creation*/
    ContratVie(param) {
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        proprietaire: param.proprietaire,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        fga: param.fga,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        details: param.education_plus,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
      }

      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        }).catch(e => {

        })
    },

    /*Send Education Plus data to the api for Contract creation*/
    ContratMicroVie(param) {

      if (localStorage.getItem('devis_tmp') != '') {
        this.devis_tmp = localStorage.getItem('devis_tmp')
      }
      
      let objetContrat = {
        numero_police: param.numeroDevis,
        userid: param.userid._id,
        createur: store.state.user._id,
        brancheid: param.brancheid._id,
        partenaireid: param.partenaireid._id,
        devisid: param._id,
        dateEffet: param.dateEffet,
        dateEcheance: param.dateEcheance,
        dateSouscription: param.dateSouscription,
        isExpress: true,
        taxe: param.taxe,
        fga: param.fga,
        prime_nette: param.prime_nette,
        prime_ttc: param.prime_ttc,
        bonus: param.bonus,
        accessoires: param.accessoires,
        isRegler: true,
        details: param.micro_assur,
        plateforme: "Dash",
        code_coupon: param.code_coupon,
        devis_tmp: this.devis_tmp
      }
      if (typeof param.courtier != 'undefined') {
        objetContrat.courtierid = param.courtier
      }
     

      Api().post('contrat/add', objetContrat)
        .then(response => {
          localStorage.removeItem('contrat_for_express_payment')
          localStorage.removeItem('devis_tmp')
          switch (response.data.message) {
            case 'Contrat cree mais non envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'Contrat cree et envoye par email':
              // this.sendsmsclient(response.data.contrat, response.data.Thebranche)
              this.contratToStore = response.data.contrat
              this.contratToStore.assureur = response.data.assureur
              this.contratToStore.branche = response.data.Thebranche
              this.$store.dispatch('setNewcontrat', this.contratToStore)

              this.$router.go(this.$router.push('/contrat/ok'))
              break;

            case 'YET':

              this.$router.go(this.$router.push({
                name: 'cumule_contrat_alert'
              }))
              break;

          }
        }).catch(e => {

        })
    },
    //Envoie sms au client
    sendsmsclient: function (contrat, branche) {
      let host = ''
      let numcli = this.detailsContrat.userid.telephone_port
      let adresstosend = numcli.substring(numcli.length - 9)
      if (branche == 'Santé') {
        host = 'https://api.assuraf.com/api/uploads/Contrat_provisoir_Sante_' + contrat._id + ".pdf"
      } else {
        host = 'https://api.assuraf.com/api/uploads/Contrat_provisoir_' + branche + '_' + contrat._id + ".pdf"
      }

      localStorage.setItem('Host', host)

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'WWySsQKLztBbZ8v7MrNlu2IkVXH3',
      }
      axios.defaults.headers.common['Authorization'] = "Bearer " + 'WWySsQKLztBbZ8v7MrNlu2IkVXH3'
      const textclient = {
        outboundSMSMessageRequest: {
          address: "tel:+221" + adresstosend,
          senderAddress: "tel:+221772981201",
          outboundSMSTextMessage: {
            message: "Assuraf vous informe que votre demande a été bien finalisée et votre attestation est disponible ici " + " " + host
          },
        }
      }

      axios.post('https://api.orange.com/smsmessaging/v1/outbound/tel:+221772981201/requests', textclient, headers)
        .then(res => {
        })
        .catch(err => {
        })

    },


    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },


    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },

    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },

    getBeneficiaries(id) {
      Api().post("/beneficiaries/getone", { userid: id })
        .then((result) => {
          this.ListAssure = result.data.beneficiaries
          this.nbAssureAdded = result.data.beneficiaries.length
          if (result.data.beneficiaries.length >= this.detailsContrat.details_pack_sante.assures.nombre_assures) {
            this.dialogInfoAssureExistant = true
          } else {
            this.dialogInfoAssure = true
          }
        }).catch((err) => {
        });
    },

    SetBeneficiarieToUpdate(item) {
      this.TheBeneficiarie = item
      this.editBeneficiarieDialog = true
    },

    UpdateBeneficiarie() {
      const data = this.TheBeneficiarie
      if (data.nom != null && data.nom != "") {
        if (data.prenom != null && data.prenom != "") {
          if (data.datenaissance != null && data.datenaissance != "") {
            if (data.userid != null && data.userid != "") {
              data.datenaissance = dayjs(data.datenaissance).format("YYYY-MM-DD")
              data.id = data._id
              Api().post("/beneficiaries/update", data)
                .then(resp => {
                  this.editBeneficiarieDialog = false
                  this.getBeneficiaries(data.userid)
                  if (resp.data.nModified == 1) {
                    this.$toasted.show('Le bénéficiaire a été mis à jour !', this.ToastTabSucess);
                  } else {
                    this.$toasted.show('Le mis à jour du bénéficiaire a échoué  !', this.ToastTabError);
                  }
                }).catch((err) => {
                })
            } else {

            }
          } else {
            this.$toasted.show('La date de naissance du béneficiaire est obligatoire !', this.ToastTabError);
          }
        } else {
          this.$toasted.show('Le prénom du béneficiaire est obligatoire !', this.ToastTabError);
        }
      } else {
        this.$toasted.show('Le nom du béneficiaire est obligatoire !', this.ToastTabError);
      }
    },

    DeleteBeneficiarie(item) {
      if (item._id && item._id != null && item._id != "") {
        item.id = item._id
        Api().post("/beneficiaries/delete", item)
          .then(resp => {
            this.editBeneficiarieDialog = false
            this.getBeneficiaries(item.userid)
            if (resp.data.deletedCount == 1) {
              this.$message.success('Le bénéficiaire a été supprimé !', this.AlertMessage)
            } else {
              this.$message.error('La suppression du bénéficiaire a échoué !', this.AlertMessage)
            }
          }).catch((err) => {
          })
      }
    },

    async saveOneBeneficiaries(benef) {
      const benef_dt = {
        userid: benef.userid,
        nom: benef.nom,
        prenom: benef.prenom,
        genre: benef.age,
        datenaissance: benef.datenaissance,
        numero_cni: benef.numero_cni,
        pays: benef.pays,
        adresse: benef.adresse,
        telephone: benef.telephone,
      }
      const returner = await Api().post("/beneficiaries/create", benef_dt)
    },

  },

  filters:
  {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },

  created() {
    this.init()
  },

  mounted() {
    this.fin = dayjs(new Date()).format('YYYY/MM/DD')
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      if (localStorage.getItem('contrat_for_express_payment') != '') {
        this.numero = localStorage.getItem('contrat_for_express_payment')
        this.SearchContrat()
      }
      this.used = true
      this.getListePays()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}

.button-filtre {
  padding: 12px !important;
  margin: 0 15px !important;
  background: #C0C7CF !important;
  border: none !important;
  color: #fff !important;
}
</style>