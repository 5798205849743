<template>
    <vue-scroll class="page-devis-auto">
        <div class="page-header">
            <h1>Parcours Digital Askia <span style="color:#ffa409">Auto/Moto</span></h1>
        </div>

        <div v-if="hidesearchClient && !isMobile"
            class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="10">
                        <el-input v-if="NumberInput" type="text" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom }} {{ usersearch.nom
                        }}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary"
                        round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="hidesearchClient && isMobile"
            class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="24">
                        <el-input v-if="NumberInput" type="text" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <el-col :span="24">
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom }} {{ usersearch.nom
                        }}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary"
                        round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="e1 == 0" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <h3>Vous souhaitez une assurance pour :</h3>
            <el-row justify="center">
                <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio @change="getListVehicule()" disabled v-model="myCars" size="medium"
                            label="mes_vehicule">Mes
                            véhicules</el-radio>
                    </div>
                </el-col>
                <el-col>
                    <div class="grid-content bg-purple-light">
                        <el-radio @change="getListVehicule()" v-model="myCars" label="nouveau">Nouveau
                            véhicule</el-radio>
                    </div>
                </el-col>
            </el-row>
            <div style="margin-top:15px">
                <div>
                    <h3>Carburation :</h3>
                    <el-row :gutter="15" justify="center">
                        <el-col v-for="item in nrg" :key="item.code">
                            <div class="grid-content bg-purple">
                                <el-radio v-model="carburant" :label="item.code" :value="item">{{ item.libelle
                                    }}</el-radio>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div style="margin-top:15px">
                <el-button @click="Stepper0Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 1" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <h3>Catégorie du véhicule :</h3>
            <el-row :gutter="15" justify="center">
                <el-col v-for="item in all_categories" :key="item.code">
                    <div class="grid-content bg-purple">
                        <el-radio v-model="categorie" :label="item" :value="item">{{ item.libelle
                            }}</el-radio>
                    </div>
                </el-col>
            </el-row>
            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 = 0" round>Retour</el-button>
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 2" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">

            <div v-if="!show_carrosseries">
                <h3>Sous catégorie du véhicule :</h3>
                <el-row :gutter="15" justify="center">
                    <el-col v-for="item in all_souscategories" :key="item.code">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="sous_categorie" :label="item" :value="item">{{ item.libelle
                                }}</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <div style="margin-top:15px">
                    <el-button style="float:left" @click="retourStepper2" round>Retour</el-button>
                    <el-button @click="Stepper2Validator" type="primary" round>Suivant</el-button>
                </div>
            </div>

            <div v-if="show_carrosseries">
                <h3>Carrosserie du véhicule :</h3>
                <el-row :gutter="15" justify="center">
                    <el-col v-for="item in all_carrosseries" :key="item.code">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="carosserie" :label="item" :value="item">{{ item.libelle
                                }}</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <div style="margin-top:15px">
                    <el-button style="float:left" @click="retourStepper2" round>Retour</el-button>
                    <el-button @click="Stepper22Validator" type="primary" round>Suivant</el-button>
                </div>
            </div>



        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">

            <div>
                <h3>Marque et modèle:</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Marque du véhicule</label>
                            <el-select style="width: 100%!important;" v-model="marque" value-key="code"
                                placeholder="Selectionner" filterable>
                                <el-option seleted v-for="items in all_marques" :key="items.code" :label="items.libelle"
                                    :value="items">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Modéle du véhicule</label>
                            <el-input placeholder="model du vehicule" v-model="model">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 = 2" round>Retour</el-button>
                <el-button @click="Stepper3Validator()" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 4 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Détails du véhicule :</h3>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Nombre de chevaux</label>
                            <el-select style="width: 100%!important;" v-model="force" placeholder="Selectionner">
                                <el-option v-for="items in PuissanceFinscaleItems" :key="items" :label="items"
                                    :value="items">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Nombre de places</label>
                            <el-input type="number" placeholder="Nombre de places" v-model="nbplace">
                                <template slot="append">place(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Date 1ére mise en circulation</label>
                            <el-date-picker style="width: 100%!important;" @change="yearsDiff()" v-model="dateCircule"
                                type="date" placeholder="Choississez une date" :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Âge du véhicule</label>
                            <el-input readonly placeholder="Âge du véhicule" v-model="age">
                                <template slot="append">{{ annee_mois }}</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" @click="e1 = 3" round>Retour</el-button>
                    <el-button @click="Stepper4Validator()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 4 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Détails du véhicule :</h3>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Nombre de chevaux</label>
                            <el-select style="width: 100%!important;" v-model="force" placeholder="Selectionner">
                                <el-option v-for="items in PuissanceFinscaleItems" :key="items" :label="items"
                                    :value="items">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Nombre de places</label>
                            <el-input type="number" placeholder="Nombre de places" v-model="nbplace">
                                <template slot="append">place(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple">
                            <label>Date 1ére mise en circulation</label>
                            <el-date-picker style="width: 100%!important;" @change="yearsDiff()" v-model="dateCircule"
                                type="date" placeholder="Choississez une date" :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Âge du véhicule</label>
                            <el-input readonly placeholder="Âge du véhicule" v-model="age">
                                <template slot="append">{{ annee_mois }}</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" @click="e1 = 3" round>Retour</el-button>
                    <el-button @click="Stepper4Validator()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 5 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 v-if="categorie !== '2_roues'">Valeur et Immatriculation :</h3>
                <h3 v-if="categorie == '2_roues'">Immatriculation :</h3>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                            <label>Valeur actuelle</label>
                            <el-input v-money="money" placeholder="Valeur actuelle" v-model="valeur_venale">
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Immatriculation</label>
                            <el-input v-mask="mask" placeholder="Immatriculation" v-model="immatriculation">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 4">Retour</el-button>

                    <el-button @click="Stepper5Validator()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 5 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 v-if="categorie !== '2_roues'">Valeur et Immatriculation :</h3>
                <h3 v-if="categorie == '2_roues'">Immatriculation :</h3>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                            <label>Valeur actuelle</label>
                            <el-input v-money="money" placeholder="Valeur actuelle" v-model="valeur_venale">
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Immatriculation</label>
                            <el-input v-mask="mask" placeholder="Immatriculation" v-model="immatriculation">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 4">Retour</el-button>
                    <el-button @click="Stepper5Validator()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 6" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Garanties :</h3>
                <div class="grid-content bg-purple" v-show="showOffre">
                    <el-row :gutter="15" justify="center">
                        <el-col>
                            <div class="grid-content bg-purple">
                                <el-radio v-model="offre" label="offre_pack" @change="show_offre()">packs</el-radio>
                            </div>
                        </el-col>
                        <el-col>
                            <div class="grid-content bg-purple-light">
                                <el-radio v-model="offre" label="offre_carte" @change="show_offre()">À la
                                    carte</el-radio>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <el-divider></el-divider>

                <div class="grid-content bg-purple" v-show="offre_pack">
                    <el-row :gutter="15" justify="center">
                        <el-col v-for="item in all_packs" :key="item.code" value-key="code">
                            <el-tooltip placement="right">
                                <div slot="content"> Durée minimum : {{ item.dureemin }}</div>
                                <div class="grid-content bg-purple-light">
                                    <el-radio v-model="garanties" :value="item" :label="item">{{ item.libelle }}</el-radio>
                                </div>
                            </el-tooltip>
                        </el-col>

                    </el-row>
                </div>

                <div class="grid-content bg-purple" v-show="offre_carte">
                    <el-checkbox v-model="recour" :true-label="1" :false-label="0" label="Recours" />
                    <el-checkbox v-model="vol" :true-label="1" :false-label="0" label="Vol" />
                    <el-checkbox v-model="inc" :true-label="1" :false-label="0" label="Incendie" />
                    <el-checkbox v-model="pt" :true-label="1" :false-label="0" label="Personnes transportées" />
                    <el-checkbox v-model="gb" :true-label="1" :false-label="0" label="Bris de glace" />
                </div>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 5">Retour</el-button>
                    <el-button v-if="suivant" @click="createTable()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 7" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
          
            <div>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <h3>Validation :</h3>
                        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper">
                            <el-table :data="ListAuto" style="width: 100%">
                                <el-table-column :fixed="!isMobile" prop="marque.libelle" label="Marque" width="150">
                                </el-table-column>
                                <el-table-column prop="model" label="Modèle" width="150">
                                </el-table-column>
                                <el-table-column prop="immatriculation" label="Immatricule" width="150">
                                </el-table-column>
                                <el-table-column prop="categorie.libelle" label="Catégorie" width="150">
                                </el-table-column>
                                <el-table-column prop="dateCircule" label="Date 1ére mise en cir..." width="250">
                                    <template slot-scope="scope">
                                        <span class="date">{{ DateFormater(scope.row.dateCircule) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="force" label="Puissance Fiscale" width="150">
                                </el-table-column>
                                <el-table-column prop="nbplace" label="Nbre de places" width="150">
                                </el-table-column>
                                <el-table-column prop="valeur_venale" label="Valeur venale" width="150">
                                    <template slot-scope="scope">
                                        <span class="date">{{ scope.row.valeur_venale }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="garanties" label="Garantie(s)" width="300">
                                    <template slot-scope="scope">
                                        <span class="date">
                                            <ul v-for="item in scope.row.garanties" :key="item">
                                                <li>{{ item }}</li>
                                            </ul>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                        <div>
                            <h3>Période de couverture</h3>
                            <el-row :gutter="20" v-if="!isMobile">
                                <el-col :span="8">
                                    <div class="grid-content bg-purple">
                                        <label>Durée de la garantie (en mois)</label>
                                        <el-select @change="dateDiff()" style="width:100%" v-model="duree"
                                            placeholder="Selectionner">
                                            <el-option v-for="item in DureeMois" :key="item" :label="item"
                                                :value="item"></el-option>
                                        </el-select>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="grid-content bg-purple">
                                        <label>Date d'effet</label>
                                        <el-date-picker style="width: 100%!important;" @change="dateDiff()"
                                            v-model="dateEffet" type="date" placeholder="Date d'effet"
                                            :picker-options="dateAfterToday">
                                        </el-date-picker>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="grid-content bg-purple">
                                        <label>Date d'échéance</label>
                                        <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20" v-if="isMobile">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple">
                                        <label>Durée de la garantie (en mois)</label>
                                        <el-select @change="dateDiff()" style="width:100%" v-model="duree"
                                            placeholder="Selectionner">
                                            <el-option v-for="item in DureeMois" :key="item" :label="item"
                                                :value="item"></el-option>
                                        </el-select>
                                    </div>
                                </el-col>
                                <el-col :span="24" style="margin-top:15px">
                                    <div class="grid-content bg-purple">
                                        <label>Date d'effet</label>
                                        <el-date-picker style="width: 100%!important;" @change="dateDiff()"
                                            v-model="dateEffet" type="date" placeholder="Date d'effet"
                                            :picker-options="dateAfterToday">
                                        </el-date-picker>
                                    </div>
                                </el-col>
                                <el-col :span="24" style="margin-top:15px">
                                    <div class="grid-content bg-purple">
                                        <label>Date d'échéance</label>
                                        <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div style="margin-top:15px">
                            <el-button style="float:left" round @click="e1 = 6">Retour</el-button>
                            <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="SendData()"
                                type="primary" round>Valider</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div v-if="result_askia" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <el-row :gutter="20">
                <el-col :span="24" class="ml-2">
                    <h2>Code ASKIA : <span style="color:#ffa409">{{ result_askia.idSaisie }}</span> </h2>
                </el-col>
                <el-col :span="12" class="ml-2">
                    <h4>Accessoire : <span style="color:#ffa409">{{ result_askia.accessoire }}</span> Fcfa</h4>
                    <h4>Fga : <span style="color:#ffa409">{{ result_askia.fga }}</span> Fcfa </h4>
                    <h4>Taxe : <span style="color:#ffa409">{{ result_askia.taxe }}</span> Fcfa </h4>
                </el-col>
                <el-col :span="12" class="ml-2">
                    <h4>Prime nette : <span style="color:#ffa409">{{ result_askia.primenette }}</span> Fcfa </h4>
                    <h4>Prime TTC : <span style="color:#ffa409">{{ result_askia.primettc }}</span> Fcfa</h4>
                    <h4>Commission : <span style="color:#ffa409">{{ result_askia.commission }}</span> Fcfa</h4>
                </el-col>
                <el-col :span="24">
                    <el-button @click="gotocontract()" type="primary" round>Poursuivre</el-button>
                </el-col>
            </el-row>
        </div>
        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Nom</label>
                            <el-input placeholder="Votre nom" v-model="nom"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom</label>
                            <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Numéro de téléphone</label>
                            <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse e-mail</label>
                            <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Sexe</label>
                            <el-select style="width: 100%!important;" v-model="genre"
                                placeholder="Selectionner votre sexe">
                                <el-option label="Homme" value="homme"></el-option>
                                <el-option label="Femme" value="femme"></el-option>
                                <el-option label="Entreprise" value="entreprise"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Nom</label>
                            <el-input placeholder="Votre nom" v-model="nom"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom</label>
                            <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple">
                            <label>Numéro de téléphone</label>
                            <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse e-mail</label>
                            <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple">
                            <label>Sexe</label>
                            <el-select style="width: 100%!important;" v-model="genre"
                                placeholder="Selectionner votre sexe">
                                <el-option label="Homme" value="homme"></el-option>
                                <el-option label="Femme" value="femme"></el-option>
                                <el-option label="Entreprise" value="entreprise"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Ajout véhicule" :visible.sync="popupAutre">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="15">
                    <el-col :span="12">
                        <el-form-item label="Marque du véhicule">
                            <el-input placeholder="Marque de votre véhicule" v-model="marque"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Modéle du véhicule">
                            <el-input placeholder="Modèle de votre véhicule" v-model="model"></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button type="primary" round @click="validMarqueModel">Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Choix du Capital Avance sur Recours" :visible.sync="AvRec">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-radio v-model="capital_AvRec" label="500000">500 000 CFA</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="capital_AvRec" label="1000000">1 000 000 CFA</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio disabled v-model="capital_AvRec" label="0">Plus de 1 million CFA <span>Nous
                                    consulter</span></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button @click="AvRecValidator" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Personnes Transportées" :visible.sync="PersoTransporteModal">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-radio v-model="optionPersoTrans" label="option 1">Option 1</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="optionPersoTrans" label="option 2">Option 2</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="optionPersoTrans" label="option 3">Option 3</el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button @click="PersonnesTransValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Individuel transport" :visible.sync="individiA">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-radio v-model="option_Individuel_Accident" label="option 1">Option 1</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="option_Individuel_Accident" label="option 2">Option 2</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="option_Individuel_Accident" label="option 3">Option 3</el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button @click="individuelTransValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Valeur à neuf" :visible.sync="VNeuf">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col :span="24">
                        <el-form-item label="Valeur à neuf">
                            <el-input v-money="money" placeholder="Valeur à neuf (CFA)" v-model="valeur"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button @click="CheckValeurNeuf()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>
        <el-dialog width="70%" title="Mes véhicules" :visible.sync="ModalListeContrat"
            v-if="ModalListeContrat && !isMobile">
            <el-table :data="userCars" class="padded-table" height="300" style="width: 100%" row-key="id">
                <el-table-column prop="marque" label="Marque" min-width="200"> </el-table-column>
                <el-table-column prop="model" label="Modèle" min-width="80"> </el-table-column>
                <el-table-column prop="nbplace" label="Nombre de places" min-width="150">
                </el-table-column>
                <el-table-column prop="force" label="Force" min-width="100"> </el-table-column>
                <el-table-column prop="dateCircule" label="Date 1ère mise en circule" min-width="200">
                    <template slot-scope="scope">
                        {{ DateFormater(scope.row.dateCircule) }}
                    </template>
                </el-table-column>
                <el-table-column prop="valeur_venale" label="Valeur actuelle" min-width="200">
                    <template slot-scope="scope">
                        {{ scope.row.valeur_venale }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="getCarForDevis(scope.row)" type="primary" icon="el-icon-right"
                            circle title="Voir détails"></el-button>
                        <el-button size="mini" @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete"
                            circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog width="90%" title="Mes véhicules" :visible.sync="ModalListeContrat"
            v-if="ModalListeContrat && isMobile">
            <el-table :data="userCars" class="padded-table" style="width: 100%" row-key="id">
                <el-table-column prop="marque" label="Marque" min-width="150"> </el-table-column>
                <el-table-column prop="model" label="Modèle" min-width="80"> </el-table-column>
                <el-table-column label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button @click="getCarForDevis(scope.row)" type="primary" icon="el-icon-right" size="mini"
                            circle title="Voir détails"></el-button>
                        <el-button @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete" size="mini"
                            circle title="Désarchiver"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </vue-scroll>
</template>

<script>

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import MarqueVehicule from '../../../common/marqueauto'
import Marque2roues from '../../../common/2roues'
import configAuto from '../../../common/configAuto'
import _ from 'underscore'
import {
    mask
} from 'vue-the-mask'
//fin importation assuraf
import customPassword from "../../../services/password_generator"
const validatePhone = phone => {

    if (!phone.length) {
        return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    } else {
        return { valid: true, error: null };
    }

};

const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "L'adresse e-mail est obligatoire!" };
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
        return { valid: true, error: null };
    }
};


export default {
    name: "AutoOnlineAskia",
    directives: {
        mask,
    },
    data() {
        return {
            all_packs: [],
            result_askia: null,
            show_carrosseries: false,
            recour: 0,
            vol: 0,
            inc: 0,
            pt: 0,
            gb: 0,
            sous_categorie: null,
            all_marques: [],
            all_carrosseries: [],
            all_categories: [],
            all_souscategories: [],
            nrg: [
                {
                    code: 'E00001',
                    libelle: 'ESSENCE'
                },
                {
                    code: 'E00002',
                    libelle: 'DISEL'
                }
            ],
            userRole: '',
            modalgenerationloading: false,
            userCarId: null,
            carDetails: {},
            ModalListeContrat: false,
            userCars: [],
            myCars: "nouveau",
            listCars: [],
            DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            PersoTransporteModal: false,
            isMobile: false,
            PuissanceFinscaleItems: ['2', '3', '4', '5', '6', '7', '8', '9', '10', 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, '24+'],
            errors: {},
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            AvRec: false,
            individiA: false,
            personnesTrans: false,
            NumberInput: false,
            EmailInput: false,
            mask: 'XX-XXXX-XX',
            radio: '1',
            //initialisation des models d'assuraf

            switch1: true,
          
            showOffre: true,
            offre_full: false,
            offre: 'offre_pack',
            offre_pack: true,
            offre_pack_full: false,
            offre_carte: false,
      
        
            VNeuf: false,
            neant: 'N.A',
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            optionsdateMEC: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                maxDate: new Date(),
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            e1: null,
            dateCircule: null,
            money: {
                thousands: ' ',
                precision: false,
                masked: false
            },
            telephoneclient: null,
            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            showId: false,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            seen: true,
            hideDive_vehicule: null,
            flotte_mono: null,
            nombreVehicules: null,
            categorie: null,
            volumetrie2_roues: '',
            genre: null,
            tonnage_carosserie: '',
            password1: null,
            optvehicule: '',
            nbredepiece: null,
            optionUtilitaire: '',
            carburant: null,
            marque: null,
            model: null,
            force: null,
            age: null,
            annee_mois: null,
            valeur: '',
            valeur_venale: null,
            valeurduvehicule: null,
            immatriculation: null,
            nbplace: null,
            garanties: [],
            assureurs: "ASKIA",
            capital_AvRec: null,
            optionPersoTrans: null,
            option_Individuel_Accident: null,
            listeModeles: null,
            popupAutre: false,
            listeAssureurs: [],
            ListAuto: [],
            suivant: true,
            categorieFInale: null,
            nbAutoAdded: null,
            listMarque: [],
            listeMarque2roues: [],
            listeModeles2roues: null,
            listMarqueprime: [],
            selectedMarque: null,
            duree: 1,
            dateEffet: null,
            dateEcheance: null,
            tonnage: null,
            carosserie: null,
            voidField: false,
            selectedcaté: 'tourisme',
            Deb: null,
            selectedcarbu: '',
            types: ['text', 'number'],
            stepper: 0,
            show: true,
            branche: null
        }
    },

    methods: {
        gotocontract() {
            this.modalgenerationloading = true
            setTimeout(() => (
                this.modalgenerationloading = false,
                this.$router.go(this.$router.push({ name: 'liste_devis_paiement' }))
            ), 3000);
        },
        getAllPacks() {
            Api().get('/auto_digital/getall/packs')
                .then(response => {
                    this.all_packs = response.data
                }).catch(error => { console.log(error) })
        },
        getAllMarques() {
            Api().get('/auto_digital/getall/marques')
                .then(response => {
                    this.all_marques = response.data
                }).catch(error => { console.log(error) })
        },
        getAllCarrosseries() {
            Api().post('/auto_digital/getall/carrosseries', { scategorie: this.sous_categorie.code })
                .then(response => {
                    this.all_carrosseries = response.data
                    if (this.all_carrosseries.length > 0) {
                        this.show_carrosseries = true;
                    } else {
                        this.show_carrosseries = false;
                        this.e1 = 3
                    }

                }).catch(error => { 
                    this.$message.error(result.data.message, this.AlertMessage)

                        console.log(error) 
                    })
        },
        getAllCategories() {
            Api().get('/auto_digital/getall/categories')
                .then(response => {
                    this.all_categories = response.data.categories
                    this.branche = response.data.branche
                }).catch(error => { console.log(error) })
        },
        getAllSubCategories() {
            Api().post('/auto_digital/getall/sub_categories', { categorie: this.categorie.code })
                .then(response => {
                    this.all_souscategories = response.data
                    this.e1 = 2
                }).catch(error => { console.log(error) })
        },

        getUserRoles() {
            Api().get('/roles/all')
                .then(response => {
                    this.listRoles = response.data.AllRoles
                    for (let lr of response.data.AllRoles) {
                        if (lr.name == "User") {
                            this.userRole = lr._id
                        }
                    }
                }).catch(err => { console.log("failed getAllUserRoles"); })
        },

        SaveUserFlette() {
            Api().post('/flette/addarray', { car: this.ListAuto, user: this.usersearch._id })
                .then((result) => {
                    if (result.data.msg) {
                        this.$message.error(result.data.msg, this.AlertMessage)
                    } else {
                        this.$message.success('Véhicule ajouté avec succés', this.AlertMessage)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error(result.data.msg, this.AlertMessage)
                });
        },

        getListVehicule() {
            // if (this.myCars == 'mes_vehicule') {
            //     if (this.listCars.length == 0) {
            //         this.$message.error("Vous n'avez pas de véhicule !", this.AlertMessage)
            //     } else {
            //         this.ModalListeContrat = true
            //         this.userCars = this.listCars
            //         this.myCars = 'mes_vehicule'
            //     }
            // } else if (this.myCars == 'nouveau') {
            //     this.e1 = 1
            // }
        },

        getCarForDevis(item) {
            this.carDetails = item

            const date1 = dayjs(this.carDetails.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff;

            if (this.age >= 360) {
                let year = Math.floor(this.age / 360);
                this.age = year;
                this.annee = year;
                this.year = this.age;
                this.an = this.annee_mois = 'an(s)'
            }

            if (this.carDetails.categorie == 'categorie 5') {
                this.categorie = '2_roues'
                this.offre_carte = true
                this.showOffre = false;
            } else if (this.carDetails.categorie != 'categorie 5') {
                this.categorie = null
                this.offre_carte = false
                this.showOffre = true;
                this.offre_pack = true
            }

            this.nbAutoAdded = this.nbAutoAdded + 1;
            this.flotte_mono = 'mono'
            this.e1 = 6
            this.ModalListeContrat = false
        },

        deleteUserCar(item) {
            const UserCar = {
                id: item._id
            }

            Api().post("/flette/delete", UserCar)
                .then(response => {
                    if (response.status == 200) {
                        Api().post("flette/listing/", { userid: this.usersearch._id })
                            .then(response => {
                                this.userCars = response.data.cars
                            })
                        this.$message.success('Véhicule supprimé avec succès !', this.AlertMessage)
                    }
                })
                .catch(err => { })

        },
        /*==========Fonction de nettoyage du formulaire apres recuperation des datas========*/
        ClearForm: function () {
            this.marque = '';
            this.model = '';
            this.categorie = '';
            this.force = '';
            this.age = '';
            this.nbplace = '';
            this.valeur = '';
            this.valeur_venale = '';
            this.immatriculation = '';
            this.carburant = '';
            this.option_Individuel_Accident = null;
            this.optionPersoTrans = null;
            this.capital_AvRec = null;
            // this.garanties = ['RESPONSABILITE CIVILE']
        },
        /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
        ListAutoCreateForCars: function () {
            this.Deduction_tonnage_carosserie();
            const Oneauto = {
                categorie: this.carDetails.categorie,
                tonnage: this.carDetails.tonnage,
                optionUtilitaire: this.carDetails.optionUtilitaire,
                volumetrie2_roues: this.carDetails.volumetrie2_roues,
                optionPersoTrans: this.optionPersoTrans,
                carburation: this.carDetails.carburation,
                type_vitre: this.carDetails.type_vitre,
                marque: this.carDetails.marque,
                carosserie: this.carDetails.carosserie,
                model: this.carDetails.model,
                force: this.carDetails.force,
                dateCircule: this.carDetails.dateCircule,
                age: this.carDetails.age,
                annee_mois: this.carDetails.annee_mois,
                nbplace: this.carDetails.nbplace,
                valeur: this.carDetails.valeur,
                valeur_venale: this.carDetails.valeur_venale,
                immatriculation: this.carDetails.immatriculation,
                flotte_mono: this.carDetails.flotte_mono,
                option_Individuel_Accident: this.carDetails.option_Individuel_Accident,
                garanties: this.garanties
            };
            if (this.capital_AvRec != null) {
                Oneauto.capitalAvRec = Number(this.capital_AvRec.replace(/[^0-9.-]+/g, ""))
            }
            if (this.categorie == '2_roues') {
                Oneauto.force = this.neant;
                Oneauto.dateCircule = this.neant;
                Oneauto.valeur = this.neant;
                Oneauto.valeur_venale = this.neant;
                Oneauto.nbplace = this.neant
                Oneauto.optionPersoTrans = this.optionPersoTrans
            }

            this.hideTablr = false;
            this.ListAuto.push(Oneauto);

            this.nbAutoAdded = this.nbAutoAdded + 1;

            this.ClearForm();
            if (this.nombreVehicules > 1) {
                if (this.nbAutoAdded < this.nombreVehicules) {
                    this.e1 = 2
                }
                if (this.nbAutoAdded == this.nombreVehicules) {
                    this.e1 = 7
                }
            } else {
                this.voidField = true
            }
        },
        /*=================Crée le tableau de la liste des véhicules==============*/
        createTable: function () {
            if (this.myCars != 'mes_vehicule') {
                if (this.offre == 'offre_pack') {
                    if (this.garanties.dureemin == 3) {
                        this.DureeMois = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    } else {
                        this.DureeMois = [12]
                    }
                    this.duree = this.garanties.dureemin
                    this.VNeuf = true
                } else {
                    this.garanties = []
                    this.recour == "1" ? this.garanties.push("recour") : 0
                    this.vol == "1" ? this.garanties.push("vol") : 0
                    this.inc == "1" ? this.garanties.push("incendie") : 0
                    this.pt == "1" ? this.garanties.push("Personnes transportees") : 0
                    this.gb == "1" ? this.garanties.push("Bris de glace") : 0

                }

                if (this.garanties) {
                    this.ListAutoCreate()
                } else {
                    this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
                }
            }
            else if (this.myCars == 'mes_vehicule') {
                if (this.garanties.length >= 1) {
                    this.ListAutoCreateForCars()
                    this.e1 = 7
                } else {
                    this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
                }
            }

            if (store.state.isUserLoggedIn) {
                this.proprietaire = this.$store.state.user.prenom + ' ' + this.$store.state.user.nom
            }
        },
        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },
        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },
        AvRecValidator() {
            if (this.capital_AvRec) {
                this.AvRec = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },
        PersonnesTransValidator() {
            if (this.optionPersoTrans) {
                this.PersoTransporteModal = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },
        individuelTransValidator() {
            if (this.option_Individuel_Accident) {
                this.individiA = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },

        /*============= fonction pour controler les garanties tierce a ne cocher plus d'un ===========*/
        tierceControlAddGarantie() {
            let cpt = 0;
            for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
                if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE') {
                    cpt = cpt + 1
                } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE') {
                    cpt = cpt + 1
                } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE') {
                    cpt = cpt + 1
                } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE' || this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE') {
                    cpt = cpt + 2
                }
            }
            if (cpt == 2) {
                this.$message.error('Impossible de prendre 2 garanties tierces en même temps !', this.AlertMessage)
                this.suivant = false
            } else if (cpt == 3) {
                this.$message.error('Impossible de prendre 3 garanties tierces en même temps !', this.AlertMessage)
                this.suivant = false
            } else {
                this.ValeurNeufModalAddGarantiesFunction()
                this.suivant = true
            }
        },

        optionPersonneTransControl() {
            if (this.optionPersoTrans) {
                this.PersoTransporteModal = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },

        PersoTransporteModalFunction() {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'PERSONNES TRANSPORTEES') {
                    trouver = 1
                }
            }
            if (trouver == 1) {
                this.PersoTransporteModal = true
            } else {
                this.optionPersoTrans = null
            }
        },

        PersoTransporteModalFunctionAddGaranties() {
            let trouver = 0;
            for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
                if (this.CarToUpdate.garanties[index] == 'PERSONNES TRANSPORTEES') {
                    trouver = 1
                }
            }
            if (trouver == 1) {
                this.PersoTransporteModal = true
            } else {
                this.optionPersoTrans = null
            }
        },

        AvRecModalFunction() {
            if (!this.CarToUpdate) {
                let trouver = 0
                for (let index = 0; index < this.garanties.length; index++) {
                    if (this.garanties[index] == 'AVANCE SUR RECOURS') {
                        trouver = 1
                    }
                }
                if (trouver == 1) {
                    this.AvRec = true
                } else {
                    this.capital_AvRec = null
                }
            } else {
                let trouver = 0;
                for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
                    if (this.CarToUpdate.garanties[index] == 'AVANCE SUR RECOURS') {
                        trouver = 1
                    }
                }
                if (trouver == 1) {
                    this.AvRecAddNew = true
                } else {
                    this.CarToUpdate.capital_AvRec = null
                }
            }

        },

        disabledBeforeToday(date) {
            const today = new Date();
            return date > today;
        },

        zeroControl() {
            if (this.duree <= 0) {
                this.duree = null
            }
        },
        numberMonth() {
            this.zeroControl()
            if (this.duree <= 12) {
                return true
            } else {

                return false
            }
        },
        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },
        errorNotif() {
            this.$notify({
                title: 'Error',
                message: 'Numéro obligatoire !',
                position: 'top-right',
                type: 'error'
            });
        },
        errorNotifCreateUser() {
            this.$notify({
                title: 'Error',
                message: 'Tout les Champs sont obligatoires !',
                position: 'top-right',
                type: 'error'
            });
        },
        successNotifCreateUser() {
            this.$notify({
                title: 'Success',
                message: 'Utilisateur crée avec succés !',
                position: 'top-right',
                type: 'succes'
            });
        },

        check_client_search() {
            if (this.clientsearch == 'téléphone') {
                this.NumberInput = true
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if (this.clientsearch == 'email') {
                this.NumberInput = false
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },

        show_offre() {
            if (this.offre == 'offre_pack') {
                this.offre_pack = true
                this.offre_carte = false
                this.garanties = []
            } else if (this.offre == 'offre_carte') {
                this.offre_pack = false
                this.offre_carte = true
                this.garanties = []
            }
        },
        ValeurNeufModalFunction() {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'TIERCE COMPLETE') {
                    trouver = 1
                } else if (this.garanties[index] == 'TIERCE COLLISION') {
                    trouver = 2
                }
            }
            if (trouver == 1 || trouver == 2) {
                this.VNeuf = true
            } else {
                this.valeur = this.neant
            }
        },

        CheckValeurNeuf: function () {
            if (this.valeur == '') {
                this.$message.error('Veuillez saisir la valeur à neuf du véhicule !', this.AlertMessage)
            } else if (this.valeur != '') {
                if (Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")) <= Number(this.valeur.replace(/[^0-9.-]+/g, ""))) {

                    this.VNeuf = false
                    this.ListAuto[0].valeur =  Number(this.valeur.replace(/[^0-9.-]+/g, ""))
                } else {
                    this.$message.error('La valeur à neuf et la valeur actuelle du véhicule ne sont pas cohérentes !', this.AlertMessage)
                }
            }
        },


        rechercherClient() {
            if (this.telephoneclient) {
                const validPhone = validatePhone(this.telephoneclient);
                this.errors.phone = validPhone.error;

                if (validPhone.valid == true) {
                    Api().get('/user/phone/' + this.telephoneclient)
                        .then(resultat => {
                            if (resultat.data.telephone_port === this.telephoneclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;

                                this.userCarId = this.usersearch._id

                                let useridentity = this.usersearch._id
                                this.$store.dispatch('setClient', resultat.data)

                                Api().post("flette/listing/", { userid: useridentity })
                                    .then(response => {
                                        this.listCars = response.data.cars
                                    });
                            }

                        })
                        .catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.telephoneC = this.telephoneclient;
                        })
                } else {
                    this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
                }
            } else {
                this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
            }


        },

        rechercheClientByMail() {
            if (this.emailclient) {

                const validEmail = validateEmail(this.emailclient);
                this.errors.email = validEmail.error;

                if (validEmail.valid == true) {
                    Api().get('/user/email/' + this.emailclient)
                        .then(resultat => {
                            if (resultat.data.email === this.emailclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;
                                this.userCarId = this.usersearch._id

                                let useridentity = this.usersearch._id
                                this.$store.dispatch('setClient', resultat.data)
                                Api().post("flette/listing/", { userid: useridentity })
                                    .then(response => {
                                        this.listCars = response.data.cars
                                    });
                            }
                        }).catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.emailC = this.emailclient;
                        })
                } else {
                    this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
                }
            } else {
                this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },

        yearsDiff() {
            const date1 = dayjs(this.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff

            if (this.age <= 29) {
                this.an = this.annee_mois = 'jour(s)'
            } else if (this.age >= 30, this.age <= 360) {
                let year = Math.floor(this.age / 30)
                this.age = year;
                this.an = this.annee_mois = 'mois'
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360)
                this.age = year;
                this.an = this.annee_mois = 'an(s)'
            }

        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 0
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        validMarqueModel() {
            if (this.categorie == '2_roues') {
                if (this.marque && this.model) {
                    this.popupAutre = false
                    this.e1 = 5
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            } else {
                if (this.marque && this.model) {
                    this.popupAutre = false
                    this.e1 = 4
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            }
        },

        flotteselect: function () {
            this.hideDive_vehicule = false
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },

        onChangeSelectedMarque: function (mark) {
            if (typeof mark == 'string') {
                if (mark == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(MarqueVehicule, {
                        'Marque': mark
                    })
                    this.listeModeles = _.uniq(selMarque, _.property('Type'))
                    this.selectedMarque = mark
                }
            }

        },

        onChangeSelectedMarque2roues: function (mark) {
            if (typeof mark == 'string') {
                if (mark == "AUTRE") {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues[0] = "AUTRE"
                    this.selectedMarque = mark

                }
            } else if (typeof mark == 'object') {
                if (mark.Marque == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues[0] = "AUTRE";
                    this.selectedMarque = mark.Marque
                    this.marque = this.selectedMarque
                    this.selectedMarque = mark
                }
            }

        },

      
        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        //Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) {
                const user = {
                    nom: this.nom,
                    prenom: this.prenom,
                    datenaissance: this.datenaissance,
                    profession: this.profession,
                    adresse: this.adresse,
                    sexe: this.genre,
                    telephone_port: this.telephoneclient,
                    email: this.emailclient,
                    password: this.password1,
                    confirmPass: this.password1,
                    role: this.userRole,
                    createdby: store.state.user._id,
                    photo: this.file,
                    piece_identite: this.piece_identite,
                    numero_piece: this.numero_piece,
                    plateforme: 'Dash',
                    created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                    .then(resultat => {
                        this.HubspotSend(user)
                        this.MailChimpInscriptionCompte(user)
                        if (resultat.status == 200) {
                            if (resultat.data.numero) {
                                this.$message.error(resultat.data.msg, this.AlertMessage)
                            } else {
                                this.$store.dispatch('setClient', resultat.data.data)
                                this.usersearch = resultat.data.data
                                this.AddUser = false
                                this.clientnonOk = false
                                this.hidesearchClient = false
                                this.e1 = 0
                            }
                        } else {
                            this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                        }

                    })
                    .catch(err => {
                        this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                    })

            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },

        //calclu date echéance
        dateDiff: function () {
            if (this.dateEffet && this.duree) {
                let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            } else {
                this.dateEffet = dayjs(new Date()).format('YYYY-MM-DD');
                let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            }
        },

        Deduction_tonnage_carosserie() {
            if (this.categorie == 'tourisme') {
                this.categorieFInale = 'categorie 1'
            } else if (this.categorie == 'utilitaire' && this.optionUtilitaire == 'materiel_perso') {
                this.categorieFInale = 'categorie 2'
                if (this.tonnage_carosserie == 'Autre carosserie jusqu\'à 3,5 tonnes') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'Autre carosserie plus de 3,5 tonnes') {
                    this.tonnage = 'Plus de 3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'carosserie tourisme') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'carosserie tourisme'
                }
            } else if (this.categorie == 'utilitaire' && this.optionUtilitaire == 'materiel_tiers') {
                this.categorieFInale = 'categorie 3'
                if (this.tonnage_carosserie == 'Autre carosserie jusqu\'à 3,5 tonnes') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'Autre carosserie plus de 3,5 tonnes') {
                    this.tonnage = 'Plus de 3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                }
            } else if (this.categorie == '2_roues') {
                this.categorieFInale = 'categorie 5'
            }
        },

        ClearForm: function () {
            this.model = ''
            this.marque = ''
            //this.categorie = ''
            this.force = ''
            this.age = ''
            this.nbplace = ''
            this.valeur = ''
            this.valeur_venale = ''
            this.immatriculation = ''
            this.proprietaire = ''
            this.carburant = ''
            this.option_Individuel_Accident = null
            this.optionPersoTrans = null
            this.capital_AvRec = null
            this.garanties = ['RESPONSABILITE CIVILE']
        },

        AutreCarburation(carburantChecked) {
            if (carburantChecked == 'Hybride' || carburantChecked == 'Electrique') {
                return 'Essence'
            } else {
                return carburantChecked
            }

        },
        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1
        Stepper1Validator() {
            if (this.categorie) {
                this.getAllSubCategories()
                // if (this.flotte_mono === 'flotte') {
                //     if (this.nombreVehicules) {
                //         this.e1 = 2
                //         this.HideNbVehicule = false
                //     } else {
                //         this.$message.error('Nombre de véhicules obligatoire !', this.AlertMessage)
                //     }
                // } else {
                //     this.e1 = 2
                //     this.HideNbVehicule = false
                // }
            } else {
                this.$message.error('Véhicule individuel ou Flotte automobile obligatoire !', this.AlertMessage)
            }
        },


        Stepper0Validator() {
            if (this.carburant) {
                this.e1 = 1
            } else {
                this.$message.error('Carburant obligatoire !', this.AlertMessage)
            }
        },


        //Validation des ellements du Stepper 2
        retourStepper2() {
            this.all_carrosseries = []
            this.show_carrosseries = false;
            this.sous_categorie = null
            this.e1 = 1
        },
        Stepper2Validator() {
            if (this.sous_categorie) {
                this.getAllCarrosseries();
            } else {
                this.$message.error('La sous catégorie du véhicule est obligatoire !', this.AlertMessage)
            }
        },
        Stepper22Validator() {
            if (this.carosserie) {
                this.e1 = 3
            } else {
                this.$message.error('La carrosserie du véhicule est obligatoire !', this.AlertMessage)
            }
        },

        //Validation des elements du Stepper 3
        Stepper3Validator() {
            if (this.categorie !== '2_roues') {
                if (this.marque) {
                    if (this.model) {
                        this.e1 = 4
                    } else {
                        this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
                }
            } else {
                if (this.marque) {
                    if (this.model) {
                        this.e1 = 5
                    } else {
                        this.$message.error('Veuillez choisir le modèle du véhicule !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez choisir la marque du véhicule !', this.AlertMessage)
                }
            }

        },

        //Validation des elements du Stepper 4
        Stepper4Validator() {
            //this.Deduction_tonnage_carosserie()
            if (this.categorie !== '2_roues') {
                if (this.force) {
                    if (this.age) {
                        if (this.nbplace) {
                            this.valeur_venale = ''
                            this.e1 = 5
                        } else {
                            this.$message.error('Veuillez saisir le nombre de place du véhicule !', this.AlertMessage)
                        }
                    } else {
                        this.$message.error("Veuillez saisir l'âge du véhicule !", this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez saisir la puissance du véhicule !', this.AlertMessage)
                }
            } else {
                if (this.age) {
                    if (this.nbplace) {
                        this.e1 = 5
                    } else {
                        this.$message.error('Le nombre de place du véhicule est obligatoire !', this.AlertMessage)
                    }
                } else {
                    this.$message.error("L'âge du véhicule est obligatoire !", this.AlertMessage)
                }
            }

        },

        //Validation des elements du Stepper 5
        Stepper5Validator() {
            if (!this.$store.state.isUserLoggedIn) {
                this.switch1 = false
            }
            if (this.categorie == '2_roues') {
                if (this.immatriculation) {
                    this.e1 = 6
                    this.garantiesFor2roues = true
                } else {
                    this.$message.error("Veuillez saisir l'immatriculation du véhicule !", this.AlertMessage)
                }
            } else {
                if (this.valeur_venale && Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")) >= configAuto.valeurNeufMinimal_Autre_categorie) {
                    if (this.immatriculation) {
                        this.e1 = 6
                        this.garantiesForAuto = true
                    } else {
                        this.$message.error("Veuillez saisir l'immatriculation du véhicule !", this.AlertMessage)
                    }
                } else {
                    this.$message.error("Veuillez saisir la valeur actuelle du véhicule !", this.AlertMessage)
                }
            }
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
        ListAutoCreate: function () {
            // this.Deduction_tonnage_carosserie()

            const Oneauto = {
                tonnage: this.sous_categorie,
                categorie: this.categorie,
                marque: this.marque,
                carosserie: this.carosserie,
                model: this.model,
                force: this.force,
                dateCircule: this.dateCircule,
                nbplace: this.nbplace,
                garanties: this.garanties,
                flotte_mono: this.flotte_mono,
                immatriculation: this.immatriculation,
                valeur_venale: Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")),
                valeur:  this.valeur,
                carburant: this.carburant
            }

            this.dateEffet = null
            this.dateEcheance = null
            this.ListAuto = [Oneauto]
            this.e1 = 7
        },

        testVar: function (val) {
            if (val != null) {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },

        SendData: function () {
            if (this.dateEffet) {

                if (this.myCars == 'mes_vehicule') {
                    /*========== Attemp to save user in the flette ===========*/
                    let OneautoToSave = {
                        userid: this.usersearch._id,
                        proprietaire: {
                            prenom: this.usersearch.prenom,
                            nom: this.usersearch.nom
                        },
                        dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                        dateEcheance: this.dateEcheance,
                        createur: this.$store.state.user._id,
                        branche: this.branche,
                        duree: this.duree,
                        dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                        vehicule: this.ListAuto,
                        assureurs: this.assureurs,
                        plateforme: 'Dash',
                        pack: null
                    }
                    if (this.offre == 'offre_pack') {
                        OneautoToSave.pack = this.garanties.code
                        OneautoToSave.vehicule[0].valeur= this.valeur
                    }
                    this.modalgenerationloading = true

                    Api().post('auto_digital/create', OneautoToSave)
                        .then(result => {
                            if (result.data.message) {
                                this.$store.dispatch('setDevis', result.data)
                                this.result_askia = result.data.result.devis[0].details[0]
                                this.modalgenerationloading = false
                            }
                        }).catch(err => {
                            this.modalgenerationloading = false
                            this.result_askia = null
                            this.$message.error('erreur de creation devis askia !', this.AlertMessage)
                        })
                } else {
                    this.modalgenerationloading = true

                    /*========== Attemp to save user in the flette ===========*/
                    let OneautoToSave = {
                        recour: this.recour,
                        vol: this.vol,
                        inc: this.inc,
                        pt: this.pt,
                        gb: this.gb,
                        userid: this.usersearch._id,
                        proprietaire: {
                            prenom: this.usersearch.prenom,
                            nom: this.usersearch.nom
                        },
                        dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                        dateEcheance: this.dateEcheance,
                        createur: this.$store.state.user._id,
                        branche: this.branche,
                        duree: this.duree,
                        dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                        vehicule: this.ListAuto,
                        assureurs: this.assureurs,
                        plateforme: 'Dash',
                        pack: null,
                        garanties: this.garanties
                    }
                    if (this.offre == 'offre_pack') {
                        OneautoToSave.pack = this.garanties.code
                        OneautoToSave.garanties = [this.garanties.libelle]
                    }
                    

                    Api().post('auto_digital/create', OneautoToSave)
                        .then(result => {
                            if (result.data) {
                                this.$store.dispatch('setDevis', result.data)
                                this.result_askia = result.data.result.devis[0].details[0]
                                this.modalgenerationloading = false
                            }
                        }).catch(err => {
                            this.modalgenerationloading = false
                            this.result_askia = null
                            this.$message.error('erreur de creation devis askia !', this.AlertMessage)
                        })

                }

            } else {
                this.$message.error("La date d'effet est obligatoire !", this.AlertMessage)
            }
        },
    },

    mounted() {


        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getAllCategories()
            this.getAllMarques()
            this.getAllPacks()
            this.getUserRoles()
        } else {
            this.$router.go(this.$router.push('/login'))
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }

                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }

                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }

                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
