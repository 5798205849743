<template>
    <vue-scroll class="page-dashboard">
        <resize-observer @notify="__resizeHanlder" />
        <el-row class="mt-0" :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">
                <div class="card-base card-shadow--medium mb-30 widget small-widget border-assuraf" style="cursor:pointer" @click="toDevis()">
                    <div class="widget-header ph-20 pt-20">
                        <div class="flex justify-center align-center">
                            <div class="widget-icon-box mr-20 animated fadeInRight">
                                <i class="widget-icon mdi mdi-book-open accent-text fs-30"></i>
                            </div>
                            <div class="widget-info box grow text-truncate animated fadeInLeft" v-if="listdevis">
                                <div class="o-050 widget-title text-truncate pt-5 pb-10">Devis</div>
                                <h2 class="m-0 text-truncate">{{devisCounter}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
           
           <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-if="$store.state.user.role=='SuperAdmin' || $store.state.user.role == 'Admin'">
                <div class="card-base card-shadow--medium mb-30 widget small-widget" style="cursor:pointer" @click="toAssureur()">
                    <div class="widget-header ph-20 pt-20">
                        <div class="flex justify-center align-center">
                            <div class="widget-icon-box mr-20 animated fadeInRight">
                                <i class="widget-icon mdi mdi-handshake accent-text fs-30"></i>
                            </div>
                            <div class="widget-info box grow text-truncate animated fadeInLeft" v-if="listassureur">
                                <div class="o-050 widget-title text-truncate pt-5 pb-10">Assureurs</div>
                                <h2 class="m-0 text-truncate">{{listassureur.length}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
           </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" v-if="$store.state.user.role=='SuperAdmin' || $store.state.user.role == 'Admin'">
				<div class="card-base card-shadow--medium mb-30 widget small-widget" style="cursor:pointer" @click="toCourtier()">
					<div class="widget-header ph-20 pt-20">
						<div class="flex justify-center align-center">
							<div class="widget-icon-box mr-20 animated fadeInRight">
								<i class="widget-icon mdi mdi-message-text-outline danger-text fs-30"></i>
							</div>
							<div class="widget-info box grow text-truncate animated fadeInLeft" v-if="listcourtier">
								<div class="o-050 widget-title text-truncate pt-5 pb-10">Courtiers</div>
								<h2 class="m-0 text-truncate">{{listcourtier.length}}</h2>
							</div>
						</div>
					</div>
				</div>
			</el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <div class="card-base card-shadow--medium mb-30 widget small-widget" @click="toClient()" style="cursor:pointer">
                    <div class="widget-header ph-20 pt-20">
                        <div class="flex justify-center align-center">
                            <div class="widget-icon-box mr-20 animated fadeInRight">
                                <i class="widget-icon mdi mdi-account-convert success-text fs-30"></i>
                            </div>
                            <div class="widget-info box grow text-truncate animated fadeInLeft" v-if="listclient">
                                <div class="o-050 widget-title text-truncate pt-5 pb-10">Clients</div>
                                <h2 class="m-0 text-truncate">{{listclient}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
      </el-col>
        
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <div class="card-base card-shadow--medium mb-30 widget small-widget" style="cursor:pointer"  @click="toContrat()">
                    <div class="widget-header ph-20 pt-20">
                        <div class="flex justify-center align-center">
                            <div class="widget-icon-box mr-20 animated fadeInRight">
                                <i class="widget-icon mdi mdi-cash-multiple success-text fs-30"></i>
                            </div>
                            <div class="widget-info box grow text-truncate animated fadeInLeft">
                                <div class="o-050 widget-title text-truncate pt-5 pb-10">Contrats</div>
                                <h2 class="m-0 text-truncate">{{contratCounter}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" v-if="$store.state.user.role=='SuperAdmin' || $store.state.user.role == 'Admin'">
              <div class="card-base card-shadow--medium mb-30 widget small-widget" style="cursor:pointer" @click="toPartenaire()">
                <div class="widget-header ph-20 pt-20">
                  <div class="flex justify-center align-center">
                    <div class="widget-icon-box mr-20 animated fadeInRight">
                      <i class="widget-icon mdi mdi-cart danger-text fs-30"></i>
                    </div>
                    <div class="widget-info box grow text-truncate animated fadeInLeft">
                      <div class="o-050 widget-title text-truncate pt-5 pb-10">Partenaires</div>
                      <h2 class="m-0 text-truncate">{{listcourtier.length + listassureur.length}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

        <el-row class="mt-0" :gutter="30">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            </el-col>
        </el-row>
    </vue-scroll>
</template>

<script>
import "vue-cute-timeline/dist/index.css"
import Api from '../../services/Api'
import store from '../../store'

export default {
    name: "Dashboard",
    data() {
        return {
            /*==================== DEBUT DES VARIABLES ASSURAF ========================*/
      DeliveryCounter: 0,
      contratCounter: 0,
      show: false,
      mywidth: 6,
      mypointbordercolor: "#f35009",
      hoverwidth: 3,
      hoverbackgroundcolor: "#636b6f",
      hoverbordercolor: "#ffd663",
      labels: ["DEVIS", "CONTRAT", "CLIENTS"],
      labelsdevis: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],

      datasets: [
        {
          data: [45, 15, 50],
          backgroundColor: ["Red", "Yellow", "Purple"],
        },
      ],
      datasetsdevis: [
        {
          data: [0, 45, 15, 50, 33, 100, 0, 50], //, 200, 300
          backgroundColor: ["Red", "Yellow", "Purple"],
        },
      ],
      option: {
        title: {
          display: true,
          position: "bottom",
          text: "Assuraf",
        },
      },
      devis: true,
      assureur: true,
      courtier: true,
      client: true,
      contrat: true,
      listcontrat: 0,
      mydata: [],
      data: {},
      listdevis: [],
      listassureur: [],
      listcourtier: [],
      listclient: 0,
      currentuser: null,
      timer: "",
      Mano: false,
      devisCounter: 0,
            /*<=================== FIN DES VARIABLES ASSURAF   ========================>*/

        }
    },
    computed: {
        smallWidget() {
            return this.dashboardWidth >= 970 && this.dashboardWidth <= 1412 && this.windowWidth >= 1200
        }
    },
    methods: {
        toDevis (){
            this.$router.go(this.$router.push('/Devis/listdevis'))
        },
        toAssureur (){
            this.$router.go(this.$router.push('/assureurs/liste'))
        },
        toCourtier(){
            this.$router.go(this.$router.push('/courtier/courtierlist'))
        },

        toClient(){
            this.$router.go(this.$router.push('clients/clientlist'))
        },
        toContrat(){
            this.$router.go(this.$router.push('/contrat/listcontrat'))
        },
        toPartenaire(){
            this.$router.go(this.$router.push('/partenaires/liste'))
        },
        /*==================== DEBUT DES FONCTIONS ASSURAF ========================*/
         isMobile: function () {
            if (screen.width <= 760) {
                return true
            } else {
                return false
            }
        },

        isTablette() {
            if (screen.width > 425 && screen.width <= 768) {
                return true
            } else {
                return false
            }
        },

        isDesktop: function () {
            if (screen.width > 768) {
                return true
            } else {
                return false
            }
        },
        fetchEventsList: function () {
            this.Mano = this.Mano + 1
            // this.getDevis()
            // this.getPartenaire()
            // this.getCourtier()
            // this.getClient()
            // this.getContrat()
            this.getNotViewDelivery()
            this.getCountDevis()
        },

        getCountDevis() {
      Api()
        .get("/devis/all")
        .then((resultat) => {
          this.devisCounter = resultat.data.count;
        });
    },

    getDevis() {
      if (this.$store.state.user.role == "agent général") 
      {
        Api()
          .get("/devis/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listdevis = resultat.data.devis;
          });
      } else if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") 
      {
        Api()
          .get("/devis/courtier/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listdevis = resultat.data.devis;
            this.devisCounter = resultat.data.devis.length
          });
      } else if (this.$store.state.user.role == "assureur") 
      {
        Api()
          .get("/devis/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listdevis = resultat.data.devis;
          });
      } else if (this.$store.state.user.role == "Admin" || this.$store.state.user.role == "SuperAdmin")
      {
        Api()
          .get("/devis/")
          .then((resultat) => {
            this.listdevis = resultat.data.devis;
            this.getCountDevis()
          });
      }
    
    },

    getCurrentUser() {
      Api()
        .get("/user/current/" + this.$store.state.user._id)
        .then((resultat) => {
          this.currentuser = resultat.data;
        });
    },

    getPartenaire() {
      Api()
        .get("/partenaire")
        .then((resultat) => {
          this.listassureur = resultat.data.partenaires;
        });
    },

    getCourtier() {
      Api()
        .get("/partenaires/all")
        .then((resultat) => {
          this.listcourtier = resultat.data.partenaires;
        });
       
    },

    getClient() {
      if (this.$store.state.user.role == "agent général") {
        Api()
          .get("/user/client/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listclient = resultat.data.length;
          });
      } else if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
        Api()
          .get("/user/client/courtier/" + this.$store.state.user.societe)
          .then((resultat) => {
            this.listclient = resultat.data.length;
          });
      } else if (this.$store.state.user.role == "assureur") {
        Api()
          .get("/user/client/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listclient = resultat.data.length;
          });
      } else if (
        this.$store.state.user.role == "Admin" ||
        this.$store.state.user.role == "SuperAdmin"
      ) {
        Api()
          .get("/user/clients")
          .then((resultat) => {
            this.listclient = resultat.data.length;
          });
      }
    },

    getContrat() {
      if (this.$store.state.user.role == "agent général") {
        Api()
          .get("/contrat/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
          });
      } else if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
        Api()
          .get("/contrat/courtier/" + this.$store.state.user.societe)
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
          });
      } else if (this.$store.state.user.role == "assureur") {
        Api()
          .get("/contrat/assureur/" + this.$store.state.user._id)
          .then((resultat) => {
            this.listcontrat = resultat.data.contrats;
          });
      } else if (
        this.$store.state.user.role == "Admin" ||
        this.$store.state.user.role == "SuperAdmin"
      ) {
        Api()
          .get("/contrat/counter/all")
          .then((resultat) => {
            this.contratCounter = resultat.data.counter;
          });
      }
    },

    getNotViewDelivery() {
      Api()
        .get("delivery/new")
        .then((resultat) => {
          this.DeliveryCounter = resultat.data.count;
        });
    },
        /*<=====================FIN DES FONCTIONS ASSURAF =========================>*/

        __resizeHanlder: _.throttle(function (e) {
            if (this.resized) {
                this.asyncComponent = null
            }
            this.resized = true
        }, 700),


    },
    mounted() {
        if(!store.state.isUserLoggedIn){
            this.$router.go(this.$router.push('/login'))
        }else {
            if (store.state.user.role !== 'User') 
        {
            if (this.$store.state.user.role !== 'ATPS User') {
                this.getDevis()
                this.getCurrentUser()
                this.getPartenaire()
                this.getCourtier()
                this.getClient()
                this.getContrat()
                //this.getCountDevis()
            } else if (this.$store.state.user.role == 'ATPS User') {
                this.$router.go(this.$router.push('/contrat/search'))
            } else {
                this.$router.go(this.$router.push('/login'))
            }
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        }
       

    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.widget {
    height: 120px;
    position: relative;

    .widget-header {
        .widget-icon-box {
            background: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.02);
            border-radius: 4px;
            text-align: center;
            width: 60px;
            padding: 5px;
        }

        .widget-title {
            font-weight: bold;
        }
    }

    .badge-box {
        .badge {
            //background: rgba(0, 0, 0, .02);
            display: inline-block;
            //padding: 2px 5px;
            //border: 1px solid rgba(0, 0, 0, .02);
            border-radius: 4px;
            font-size: 80%;
        }
    }
}

/*@media (max-width: 768px) {
	.el-row {
		//margin-left: 0 !important;
		//margin-right: 0 !important;

		.el-col-24 {
			//padding-left: 0 !important;
			//padding-right: 0 !important;
		}
	}
}*/

.timeline {
    max-width: 1200px;
    margin: 6px;
}
.timeline,
.timeline-title {
    color: $text-color;
    line-height: 1.4;
    cursor: default;
    font-family: inherit;
}

/*@media (min-width: 1200px) and (max-width: 1850px) {
	.widget {
		.widget-header {
			.widget-icon-box {
				display: none;
			}
		}
	}
}*/
@media (min-width: 768px) and (max-width: 1040px) {
    .radio-switcher {
        display: none;
    }

    .widget {
        .widget-header {
            .widget-icon-box {
                display: none;
            }
        }
    }
}
@media (max-width: 420px) {
    .radio-switcher {
        display: none;
    }
}
</style>

<style lang="scss">
.page-dashboard {
    .widget {
        .peity {
            position: absolute;
            bottom: -1px;
            left: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    table.styled {
        .peity {
            margin-right: 10px;
        }
    }

    .vb-content {
        padding: 0 20px;
        box-sizing: border-box !important;
        margin-top: -5px;
        margin-left: -20px;
        margin-right: -20px;
        height: calc(100% + 15px) !important;
        width: calc(100% + 40px) !important;
    }
}

@media (max-width: 768px) {
    .page-dashboard {
        .vb-content {
            padding: 0 5px !important;
            margin: -5px;
            width: calc(100% + 10px) !important;
        }
    }
}
</style>
