import axios from 'axios'
import store from '../store'
import router from '../router'
export default () => {
  const axiosConf = axios.create({
    //baseURL: `http://localhost:3005/`,
    baseURL: `https://assurapi.assuraf.com/apicloud`,    
    //baseURL: `https://assurapi.assuraf.com/apicloudmigration`,
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'user': store.state.user
    }
  })
  axiosConf.defaults.headers['codep'] = store.state.code_partenaire
  axiosConf.defaults.headers['country'] = store.state.user.partnercountry
  axiosConf.defaults.headers.common['Authorization'] = "Bearer " + store.state.token
  axiosConf.defaults.headers.post['Content-Type'] = 'Application/json'
  axiosConf.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  return axiosConf
}
