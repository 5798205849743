<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
      <div class="page-header">
        <h1>Liste des <span style="color:#ffa409">partenaires cloud</span>
          <theme-picker style="float:right"></theme-picker>
        </h1>
      </div>
  
      <div class="mb-5">
        <el-button @click="toCreatePartenaire()" type="primary" round>Créer un nouveau partenaire</el-button>
      </div>
  
       <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow">
          <el-input
              placeholder="Rechercher partenaire..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable
          >
          </el-input>
        </div>
        <div class="box grow">
          <!--        A ne pas supprimer-->
        </div>
      </div>
  
      <div v-if="isMobile" style="margin-bottom:15px">
        <el-row :gutter="15">
          <el-col :span="24" style="margin-top:15px">
            <el-input
              placeholder="Rechercher partenaire..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable>
          </el-input>
          </el-col>
        </el-row>
      </div>

      <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
    
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            :height="height"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Code Partenaire" min-width="250" prop="code_partenaire" :fixed="!isMobile"></el-table-column>
          <el-table-column label="Nom Partenaire" min-width="250" prop="namePartner" :fixed="!isMobile"></el-table-column>
          <el-table-column label="Email" prop="email" min-width="300">
            <template slot-scope="scope">
              <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
          <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>  
          <el-table-column label="Type partenaire" min-width="150" prop="type_partenaire"></el-table-column>
          <el-table-column fixed="right" label="Actions" prop="action" min-width="175">
            <template slot-scope="scope">
              <el-button size="mini" @click="detailAssu(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
              <el-button size="mini" type="primary" @click="BloquePartenaire(scope.row.id)" icon="el-icon-lock" v-if="scope.row.etat=== 'enabled'" circle title="Bloquer partenaire"></el-button>
              <el-button size="mini" type="primary" @click="DebloquePartenaire(scope.row.id)" icon="el-icon-unlock" v-if="scope.row.etat=== 'disabled'" circle title="Débloquer partenaire"></el-button>
              <el-button size="mini" type="primary" @click="editPart(scope.row)" icon="el-icon-edit" circle title="Modifier"></el-button>
              <el-button size="mini" @click="partenaireDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
       <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Email" prop="email" min-width="300">
            <template slot-scope="scope">
              <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Type partenaire" min-width="150" prop="type_partenaire"></el-table-column>
          <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
          <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>
          <el-table-column label="Ninea" min-width="150" prop="ninea"></el-table-column>
          <el-table-column label="RCCM" min-width="150" prop="rccm"></el-table-column>
          <el-table-column label="Actions" prop="action" min-width="175">
            <template slot-scope="scope">
              <el-button size="mini" @click="detailAssu(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
              <el-button size="mini" type="primary" @click="BloquePartenaire(scope.row.id)" icon="el-icon-lock" v-if="scope.row.etat=== 'enabled'" circle title="Bloquer partenaire"></el-button>
              <el-button size="mini" type="primary" @click="DebloquePartenaire(scope.row.id)" icon="el-icon-unlock" v-if="scope.row.etat=== 'disabled'" circle title="Débloquer partenaire"></el-button>
              <el-button size="mini" type="primary" @click="editPart(scope.row)" icon="el-icon-edit" circle title="Modifier"></el-button>
              <el-button size="mini" @click="partenaireDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <el-dialog width="70%!important" title="Détails partenaire" :visible.sync="showpartenaire" v-if="showpartenaire==true && !isMobile">
        <el-row :gutter="15">
          <el-col :span="16">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div  >
                  <ul style="list-style:none">
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsAssu.adresse}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsAssu.email}}</span></li>
                    <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsAssu.telephone_port}}</span></li>
                    <li>Téléphone bureau : <span class="to-right assuraf-orange">{{detailsAssu.telephone_bur}}</span></li>
                    <li>Ninéa : <span class="to-right assuraf-orange">{{detailsAssu.ninea}}</span></li>
                    <li>RCCM : <span class="to-right assuraf-orange">{{detailsAssu.rccm}}</span></li>
                    <li>Type partenaire : <span class="to-right assuraf-orange">{{detailsAssu.type_partenaire}}</span></li>
                    <li>Status partenaire : <span class="to-right assuraf-orange">{{detailsAssu.etat}}</span></li> 
                    <li>Numéro agrément : <span class="to-right assuraf-orange">{{detailsAssu.numero_agrement}}</span></li>
                    <li>Date agrément : <span class="to-right assuraf-orange">{{DateFormater(detailsAssu.date_agrement)}}</span></li>
                    <li>
                          Devis Auto :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneAutoView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                    <li>
                          Devis Habitation :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneHabitationView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                    <li>
                          Devis Voyage :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneVoyageView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div style="text-align: center!important;">
                  <img class="justify-center" :src="detailsAssu.logo" width="200" alt="logo assureur">
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
      <el-dialog width="90%!important" title="Détails partenaire" :visible.sync="showpartenaire" v-if="showpartenaire==true && isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div  >
                  <ul style="list-style:none">
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsAssu.adresse}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsAssu.email}}</span></li>
                    <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsAssu.telephone_port}}</span></li>
                    <li>Téléphone bureau : <span class="to-right assuraf-orange">{{detailsAssu.telephone_bur}}</span></li>
                    <li>Ninéa : <span class="to-right assuraf-orange">{{detailsAssu.ninea}}</span></li>
                    <li>RCCM : <span class="to-right assuraf-orange">{{detailsAssu.rccm}}</span></li>
                    <li>Numéro agrément : <span class="to-right assuraf-orange">{{detailsAssu.numero_agrement}}</span></li>
                    <li>Date agrément : <span class="to-right assuraf-orange">{{DateFormater(detailsAssu.date_agrement)}}</span></li>
                    <li>
                          Devis Auto :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneAutoView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                    <li>
                          Devis Habitation :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneHabitationView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                    <li>
                          Devis Voyage :
                          <span class="to-right assuraf-orange font-weight-bold" v-if="detailsAssu.showOneVoyageView"> OUI</span>
                          <span class="to-right assuraf-orange font-weight-bold" v-else> NON</span>
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div style="text-align: center!important;">
                  <img class="justify-center" :src="detailsAssu.logo" width="200" alt="logo assureur">
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
      <el-dialog width="70%" title="Formulaire de modification du partenaire" :visible.sync="editPartenaire" v-if="editPartenaire==true && !isMobile">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <el-row :gutter="15">
              <el-col :span="8">
                <div>
                  <label>Nom</label>
                  <el-input v-model="detailsAssu.societe" placeholder="Nom du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Adresse</label>
                  <el-input v-model="detailsAssu.adresse" placeholder="Adresse partenaire..." class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Téléphone portable</label>
                  <el-input type="number" v-model="detailsAssu.telephone_port" placeholder="Numéro téléphone" class="input-with-select"></el-input>
                </div>
              </el-col>
            </el-row>
  
            <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="8">
                <div>
                  <label>Téléphone bureau</label>
                  <el-input v-model="detailsAssu.telephone_bur" placeholder="Numéro bureau" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Adresse email</label>
                  <el-input v-model="detailsAssu.email" placeholder="Adresse email." class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Ninéa</label>
                  <el-input v-model="detailsAssu.ninea" placeholder="Ninéa du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
            </el-row>
  
            <el-row style="margin-top: 15px" :gutter="15">
               <el-col :span="8">
                <div>
                   <label>Type</label>
                  <el-select style="width: 100%" v-model="detailsAssu.type_partenaire" placeholder="Selectionner">
                    <el-option value="assureur" label="Assureur">Assureur</el-option>
                    <el-option value="courtier" label="Courtier">Courtier</el-option>
                    <el-option value="tiers" label="Tiers">Tiers</el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>RCCM</label>
                  <el-input v-model="detailsAssu.rccm" placeholder="RCCM du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Numéro d'agrement</label>
                    <el-input v-model="detailsAssu.numero_agrement" placeholder="Numéro agrement" class="input-with-select"></el-input>
                </div>
              </el-col>
            </el-row>
  
            <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="12">
                <div>
                  <label>Date d'agrement</label>
                  <el-date-picker
                      style="width: 100%!important;"
                      @change="yearsDiff()"
                      v-model="detailsAssu.date_agrement"
                      type="date"
                      placeholder="Choississez une date"
                      :picker-options="dateBeforeToday">
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="12">
                <div>
                  <label>Logo</label>
                    <input type="file" ref="file" @click="idP=detailsAssu.id" @change="UpdatePartenaireLogo" class="input-with-select">
                    <img :src="detailsAssu.logo" width="100" alt="logo du partenaire">
                </div>
              </el-col>
            </el-row>
  
            <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="12">
                <div>
                  <label>Devis Automobile</label>
                  <el-select style="width: 100%" v-model="detailsAssu.showOneAutoView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                </div>
              </el-col>
              <el-col :span="12">
                <div>
                  <label>Devis Habitation</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneHabitationView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
            </el-row>
  
             <el-row style="margin-top: 15px" :gutter="15">
              <el-col :span="8">
                <div>
                  <label>Devis Voyage</label>
                  <el-select style="width: 100%" v-model="detailsAssu.showOneVoyageView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <label>Devis Santé</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneVieView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
  
               <el-col :span="8">
                <div>
                  <label>Devis Vie</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneSanteView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
            </el-row>
          </el-card>
  
          <div class="mb-5" style="margin-top: 15px">
            <el-button type="danger" round @click="partenaireUpdate()">Modifier</el-button>
            <el-button type="primary" round @click="editPartenaire=false">Annuler</el-button>
          </div>
        </div>
      </el-dialog>
  
      <el-dialog width="90%" title="Formulaire de modification du partenaire" :visible.sync="editPartenaire" v-if="editPartenaire==true && isMobile">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <el-row :gutter="15">
              <el-col :span="24">
                <div>
                  <label>Nom</label>
                  <el-input v-model="detailsAssu.societe" placeholder="Nom du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Adresse</label>
                  <el-input v-model="detailsAssu.adresse" placeholder="Adresse du partenaire..." class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Téléphone portable</label>
                  <el-input type="number" v-model="detailsAssu.telephone_port" placeholder="Numéro téléphone" class="input-with-select"></el-input>
                </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Téléphone bureau</label>
                  <el-input v-model="detailsAssu.telephone_bur" placeholder="Numéro bureau" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Adresse email</label>
                  <el-input v-model="detailsAssu.email" placeholder="Adresse email." class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Ninéa</label>
                  <el-input v-model="detailsAssu.ninea" placeholder="Ninéa du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                   <label>Type</label>
                    <el-select style="width: 100%" v-model="detailsAssu.type_partenaire" placeholder="Selectionner">
                    <el-option value="assureur" label="Assureur">Assureur</el-option>
                    <el-option value="courtier" label="Courtier">Courtier</el-option>
                    <el-option value="tiers" label="Tiers">Tiers</el-option>
                    </el-select>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>RCCM</label>
                  <el-input v-model="detailsAssu.rccm" placeholder="RCCM du partenaire" class="input-with-select"></el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Numéro d'agrement</label>
                    <el-input v-model="detailsAssu.numero_agrement" placeholder="Numéro agrement" class="input-with-select"></el-input>
                </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Date d'agrement</label>
                  <el-date-picker
                      style="width: 100%!important;"
                      @change="yearsDiff()"
                      v-model="detailsAssu.date_agrement"
                      type="date"
                      placeholder="Choississez une date"
                      :picker-options="dateBeforeToday">
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Logo</label>
                    <input type="file" ref="file" @click="idP=detailsAssu.id" @change="UpdatePartenaireLogo" class="input-with-select">
                    <img :src="detailsAssu.logo" width="100" alt="logo du partenaire">
                </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Devis Automobile</label>
                  <el-select style="width: 100%" v-model="detailsAssu.showOneAutoView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Devis Habitation</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneHabitationView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Devis Voyage</label>
                  <el-select style="width: 100%" v-model="detailsAssu.showOneVoyageView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Devis Santé</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneVieView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
               <el-col :span="24" style="margin-top:15px">
                <div>
                  <label>Devis Vie</label>
                   <el-select style="width: 100%" v-model="detailsAssu.showOneSanteView" placeholder="Selectionner">
                  <el-option value="true" label="Oui">Oui</el-option>
                  <el-option value="false" label="Non">Non</el-option>
                </el-select>
                 </div>
              </el-col>
            </el-row>
          </el-card>
  
          <div class="mb-5" style="margin-top: 15px">
            <el-button type="danger" round @click="partenaireUpdate()">Modifier</el-button>
            <el-button type="primary" round @click="editPartenaire=false">Annuler</el-button>
          </div>
        </div>
      </el-dialog>
  
      <el-dialog title="Suppression partenaire" width="50%" :visible.sync="modalDelete" v-if="modalDelete==true">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Voulez-vous supprimer ce partenaire?</span>
            </div>
            <div  >
              <ul style="list-style:none">
                <li> <h3>Partenaire : <span class="to-right assuraf-orange">{{detailsAssu.societe}}</span></h3></li>
              </ul>
            </div>
          </el-card>
        </div>
        <div style="margin-top:15px">
          <el-button type="danger" round @click="DeletingPartenaire(detailsAssu.societe)">Oui</el-button>
          <el-button type="primary" round @click="modalDelete = false">Non</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
    import _ from "lodash"
    import moment from "moment-timezone"
    
    //debut importation assuraf
    import dayjs from 'dayjs'
    import Api from '../../../services/Api'
    import store from '../../../store'
    //fin importation assuraf
    
    export default {
      name: "StyledTablePage",
      data() {
        return {
          editPartenaire : false,
          modalgeneration:false,
          partenaireId: null,
          testsoc: null,
          file: null,
          ErrorDelete: false,
          idP: null,
          listpartenaire: [],
    
          showpartenaire: false,
          detailsAssu: [],
          fields: [
            {
              key: 'societe',
              label: 'societe',
              sortable: true,
              sortDirection: 'desc'
            },
            {
              key: 'email',
              label: 'Email'
            },
            {
              key: 'adresse',
              label: 'Adresse'
            },
            {
              key: 'telephone_port',
              label: 'Telephone'
            },
            {
              key: 'ninea',
              label: 'Ninea'
            },
            {
              key: 'rccm',
              label: 'rccm'
            },
            {
              key: 'type_partenaire',
              label: 'Type partenaire'
            },
            {
              key: 'action',
              label: 'Action'
            },
          ],
          modalDeleteCar:false,
          userflette:[],
          fletteRows:null,
          carFields:[
            {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
            {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
            {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
            {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
            {key:'added_date',label:"Date d'ajout"},
            {key:'action',label:'Action'},
          ],
          editUser : false,
          Selectedbranche:null,
          selectedAssureur:[],
          configUserDevisAssureur:null,
          assureurs:[],
          branchelist:null,
          modalSociete: false,
          configHead:[
            { key:"branche", label:"branche"},
            { key:"assureurs", label:"assureur"},
            { key:"action", label:"Action"}
          ],
          selectedUser:null,
          listeAssureurs: [],
          usermenu:[],
          collapse:null,
          menu_name:null,
          showConfigMenu:false,
          submenu:null,
          userSubmenu:[],
          UserFinalMenu:[],
    
          username: null,
          data: {},
          html: '',
          Mycounter: 0,
          dismissSecs: 3,
          totalRows: null,
          perPage: 8,
          UpdateOk: false,
          deleteConfirmation: false,
          modalDelete: false,
          dismissCountDown: 0,
          detailsUser: [],
          listuser: null,
          filter: null,
          showuser: false,
          modalInfo: {
            title: '',
            content: ''
          },
          MenuHeader:[
            {key: "theme",label: "Libéllé"},
            {key: "collapse",label: "Collapse"},
            {key: "sousmenu",label: "Sous menu"},
            {key: "action", label:"action"}
          ],
          voidField: false,
          isMobile: false,
          ready: false,
          width: 0,
          height: "auto",
          loading: false,
          search: "",
          pagination: {
            page: 1,
            size: 100,
            sizes: [10, 15, 20, 30, 50, 100],
            layout: "total, ->, prev, pager, next, jumper, sizes",
            small: false
          },
          list: this.listpartenaire,//users,
          editMode: false,
          itemsChecked: [],
          dialogUserVisible: false,
          currentId: 0,
          moment
        }
      },
      computed: {
        listFiltered() {
          return this.listpartenaire.filter(obj => {
            let ctrl = false
            for (let k in obj) {
              if (
                  obj[k] &&
                  obj[k]
                      .toString()
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
              )
                ctrl = true
            }
            return ctrl
          })
        },
        listSortered() {
          let prop = this.sortingProp
          let order = this.sortingOrder
          return [].concat(
              this.listFiltered.sort((item1, item2) => {
                let val1 = ""
                let val2 = ""
    
                val1 = item1[prop]
                val2 = item2[prop]
                if (order === "descending") {
                  return val2 < val1 ? -1 : 1
                }
                return val1 < val2 ? -1 : 1
              })
          )
        },
        listInPage() {
          let from = (this.currentPage - 1) * this.itemPerPage
          let to = from + this.itemPerPage * 1
          //return this.listSortered.slice(from, to)
          return this.listFiltered.slice(from, to)
        },
        total() {
          return this.listFiltered.length
        },
    
        listFilteredFlotte() {
          return this.userflette.filter(obj => {
            let ctrl = false
            for (let k in obj) {
              if (
                  obj[k] &&
                  obj[k]
                      .toString()
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
              )
                ctrl = true
            }
            return ctrl
          })
        },
        listSorteredFlotte() {
          let prop = this.sortingProp
          let order = this.sortingOrder
          return [].concat(
              this.listFilteredFlotte.sort((item1, item2) => {
                let val1 = ""
                let val2 = ""
    
                val1 = item1[prop]
                val2 = item2[prop]
                if (order === "descending") {
                  return val2 < val1 ? -1 : 1
                }
                return val1 < val2 ? -1 : 1
              })
          )
        },
        listInPageFlotte() {
          let from = (this.currentPage - 1) * this.itemPerPage
          let to = from + this.itemPerPage * 1
          return this.listSorteredFlotte.slice(from, to)
          return this.listFilteredFlotte.slice(from, to)
        },
        totalFlotte() {
          return this.listFilteredFlotte.length
        },
    
        currentPage: {
          get() {
            return this.pagination.page
          },
          set(val) {
            this.pagination.page = val
          }
        },
        itemPerPage() {
          return this.pagination.size
        },
        selectedItems() {
          return this.itemsChecked.length || 0
        }
      },
      watch: {
        itemPerPage(val) {
          this.ready = false
          this.currentPage = 1
    
          setTimeout(() => {
            this.ready = true
          }, 500)
        },
        search(val) {
          this.currentPage = 1
        }
      },
      methods: {
        getListPartner() {
          Api().get('/partenaires/all')
              .then(resultat => {
                this.listpartenaire = resultat.data.partenaires
                this.totalRows = resultat.data.partenaires.length
              });
        },
    
    
        exportToExcel(){
          Api().get('/export/partenaire')
              .then(resultat => {
                this.modalgeneration = true
                let url = resultat.data.lien
                setTimeout(() => (
                    this.modalgeneration = false,
                        window.open(url)), 7000
                );
              });
        },
    
        UpdatePartenaireLogo: function (e, item) {
          var files = e.target.files || e.dataTransfer.files;
          this.file = this.$refs.file.files[0];
    
          let formData = new FormData();
          formData.append("logo", this.file);
          formData.append("partenaireId", this.idP);
          Api().post('partenaire/updatelogo/', formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
              .then(response => {
                this.ServerRep = response.data;
              })
        },
        partenaireDeleteConfirmation(item) {
    
          this.modalDelete = true
          this.detailsAssu = item
    
        },
        BloquePartenaire(id) {
          Api().get('partenaire/bloquer/' + id)
              .then(rep => {
                this.getListPartner()
              })
        },
    
        DebloquePartenaire(id) {
          Api().get('partenaire/debloquer/' + id)
              .then(rep => {
                this.getListPartner()
              })
        },
        DeletingPartenaire(id)
        {
          Api().delete('partenaires/delete/' + id)
              .then(resultat => {
                if (resultat.data.rep == true) {
                  this.modalDelete = false
                  this.getListPartner()
                }
              })
              .catch(err => {
                alert("Erreur interne au serveur !")
              })
        },
    
        editPart(item) {
          this.detailsAssu = item
          this.editPartenaire = true
        },
    
    
        toCreatePartenaire (){
          this.$router.go(this.$router.push('/partenaires/nouveau'))
        },
        partenaireUpdate() {
            let itemPartenaire =  this.detailsAssu
          Api().put('partenaire/update/', itemPartenaire)
              .then(resultat => {
                this.getListPartner()
                this.editPartenaire = false
                this.$store.dispatch("setPartenaire", resultat.data)
    
              });
        },
        fetch() {
          this.$http.get("home").then(({
                                        data
                                      }) => {
            this.data = data
          });
        },
    
        detailAssu(item) {
          this.detailsAssu = item
          this.showpartenaire = true
        },
    
    
    
        ready: function () {
          var self = this;
    
          setInterval(function () {
            self.$data.ticker = Date.now();
          }, 1000);
        },
    
        countDownChanged(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
        },
    
        showAlert() {
          this.dismissCountDown = this.dismissSecs
        },
    
        getUserList:function(){
          switch (this.$store.state.user.role) {
            case 'Admin':
              Api().get('/user/frontoffice/')
                  .then( resultat => {
                    this.listuser = resultat.data
                    this.totalRows = resultat.data.length
                  });
              break;
    
    
            case 'assureur':
              Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                  .then( resultat => {
                    this.listuser = resultat.data
                    this.totalRows = resultat.data.length
                  });
              break;
    
            default:
              break;
          }
        },
    
        UpdateUser(item) {
    
          let idU = item.id
          Api().post('user/' + idU, item)
              .then(response => {
                if (response.data) {
                  this.showAlert()
                }
                this.showAlert()
              })
              .catch(err => {
    
              })
    
        },
    
        DateFormater: function (TheDate) {
          if (TheDate) {
            return dayjs(TheDate).format('DD-MM-YYYY')
          }
        },
    
        calcDims()
        {
          const tableWrapper = document.getElementById("table-wrapper")
          if (tableWrapper) this.width = tableWrapper.clientWidth
    
          if (!this.isMobile && tableWrapper) {
            this.height = tableWrapper.clientHeight - 44
          }
    
          if (this.width < 480) {
            this.pagination.small = true
            this.pagination.layout = "prev, pager, next"
          } else if (this.width >= 480 && this.width < 700) {
            this.pagination.small = false
            this.pagination.layout = "prev, pager, next, ->, sizes"
          } else {
            this.pagination.small = false
            this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
          }
    
          this.ready = true
        },
        handleResize: _.throttle(function(e) {
          this.ready = false
          this.width = 0
          setTimeout(this.calcDims, 1000)
        }, 500),
    
        handleSelectionChange(val) {
          this.itemsChecked = val
        },
    
        init() {
          if (window.innerWidth <= 768) this.isMobile = true
        }
      },
    
      filters: {
        selected: function(value, sel) {
          if (!value) return ""
          if (!sel) return value
    
          value = value.toString()
          sel = sel.toString()
    
          const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
          if (startIndex !== -1) {
            const endLength = sel.length
            const matchingString = value.substr(startIndex, endLength)
            return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
          }
    
          return value
        }
      },
      created() {
        this.init()
        this.getListPartner()
    
      },
      mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
          this.getListPartner()
        } else {
          this.$router.go(this.$router.push('/login'))
        }
    
        //ie fix
        if (!window.Number.parseInt) window.Number.parseInt = parseInt
    
        this.calcDims()
      }
    }
  </script>
  
  <style lang="scss" scoped>
  @import "../../../assets/scss/_variables";
  
  .page-table {
    &.overflow {
      overflow: auto;
    }
  
    .toolbar-box {
      &.hidden {
        visibility: hidden;
      }
    }
  
    .table-box {
      overflow: hidden;
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
  </style>
  
  <style lang="scss">
  @import "../../../assets/scss/_variables";
  
  .page-table {
    .toolbar-box {
      margin-bottom: 10px;
  
      .card-shadow--medium {
        &.el-input,
        &.el-select {
          border-radius: 4px;
  
          input {
            border-color: transparent;
            background-color: lighten($background-color, 2%);
            color: $text-color;
  
            &:hover {
              border-color: #c0c4cc;
            }
            &:focus {
              border-color: $text-color-accent;
            }
          }
        }
  
        &.el-button {
          border-color: transparent;
        }
      }
    }
  
    .clickable {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  
    .sel-string {
      .sel {
        background: transparentize($text-color, 0.8);
        border-radius: 5px;
        //text-transform: uppercase;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page-table {
      .toolbar-box {
        display: block;
        overflow: hidden;
        font-size: 80%;
        padding-bottom: 10px;
  
        & > * {
          display: inline-block;
          min-width: 120px;
          height: 22px;
          //background: rgba(0, 0, 0, 0.04);
          margin-bottom: 16px;
        }
      }
    }
  }
  </style>
  