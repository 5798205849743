<template>
    <vue-scroll class="page-devis-habitaion" >
        <div class="page-header">
			<h1>Création de devis <span style="color:#ffa409">Individuel Accident</span></h1>
		</div>
       
        <div v-if="hidesearchClient && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="10">
                  <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
              </el-row>
            </div> 

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>

         <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                 <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input></el-col>
              </el-row>
            </div> 

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>
        
        <div v-if="e1 == 0 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <h3>Choix classe :</h3>
            <el-row :gutter="15" justify="center">
                <el-col>
                    <div class="grid-content bg-purple" v-for="item in this.listClasses" :key="item">
                        <el-tooltip placement="right">
                            <div style="width: 500px;" slot="content">
                                <p>Description :<br/> <span>{{ item.description }} </span></p>
                                <p class="mt-3">Profession :<br/> <span v-for="itemProf in item.profession" :key="itemProf">{{ itemProf }} </span></p>
                            </div>
                            <el-radio v-model="classe" :label="item.classe" @change="getClassforDevis(item.classe)" @click="getClassforDevis(item.classe)">Classe {{item.classe}}</el-radio>
                        </el-tooltip>
                        <p></p>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div v-if="e1 == 0 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Choix classe :</h3>
                    <el-row :gutter="15">
                      <el-col :span="24">
                        <div>
                            <label>Classe</label>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                <div class="grid-content bg-purple" v-for="item in this.listClasses" :key="item">
                                    <el-tooltip placement="right">
                                        <div style="width: 500px;" slot="content">
                                            <p>Description :<br/> <span>{{ item.description }} </span></p>
                                            <p class="mt-3">Profession :<br/> <span v-for="itemProf in item.profession" :key="itemProf">{{ itemProf }} </span></p>
                                        </div>
                                        <el-radio v-model="classe" :label="item.classe" @change="getClassforDevis(item.classe)">Classe {{item.classe}}</el-radio>
                                    </el-tooltip>
                                </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
            </el-row>
           </div>
        </div>

        <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Choix capitaux:</h3>
                <el-row :gutter="15" justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                    <el-checkbox-group v-model="garanties" v-for="choice in iAData.choice" :key="choice">
                                        <el-checkbox :label="choice.value" @change="garantiesValidator()">{{ choice.name }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-col>
                        </el-row>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" justify="center" style="margin-top: 20px;">
                    <el-col :span="12" v-if="capitalValideForDeces">
                        <div class="grid-content bg-purple">
                                <label>Capital souhaité pour décès (En million) CFA</label>
                                <el-select style="width:100%" v-model="capitalDeces" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capital" :key="item" :label="item.capital" :value="item.value" ></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-col :span="12" v-if="capitalValideForInvalidite">
                        <div class="grid-content bg-purple">
                                <label>Capital souhaité pour invalidité (En million) CFA</label>
                                <el-select style="width:100%" v-model="capitalInvalidite" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capital" :key="item" :label="item.capital" :value="item.value"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" justify="center" style="margin-top: 20px;">
                    <el-col :span="12"  v-if="bourseValideIncapacite">
                        <div class="grid-content bg-purple">
                                <label>Bourse incapacité temporaire CFA</label>
                                <el-select style="width:100%" v-model="bourseIncapacite" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capitalIncapacite" :key="item" :label="item.capital" :value="item.value"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-col :span="12"  v-if="bourseValideFrais">
                        <div class="grid-content bg-purple">
                                <label>Remboursement frais médicaux CFA</label>
                                <el-select style="width:100%" v-model="bourse" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.fraisMedicaux" :key="item" :label="item.bourse" :value="item.bourse"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="Prime_deces !== null" label="Prime Décès"><span style="color:#ffa409">{{Prime_deces.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="prime_invalidite !== null" label="Prime Invalidité"><span style="color:#ffa409">{{prime_invalidite.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="bourseIncapaciteTemporaire !== null" label="Prime Incapacité temporaire"><span style="color:#ffa409">{{bourseIncapaciteTemporaire.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="bourseFraisMedicaux !== null" label="Prime Frais médicaux"><span style="color:#ffa409">{{bourseFraisMedicaux.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="primeNette !== null" label="Prime Nette"><span style="color:#ffa409">{{primeNette.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="returToFirstStep()">Retour</el-button>
                    <el-button @click="Stepper2Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

         <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Choix capitaux:</h3>
                    <el-row :gutter="15">
                      <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                    <el-checkbox-group v-model="garanties" v-for="choice in iAData.choice" :key="choice">
                                        <el-checkbox :label="choice.value" @change="garantiesValidator()">{{ choice.name }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-col>
                        </el-row>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px" v-if="capitalValideForDeces">
                        <div class="grid-content bg-purple">
                                <label>Capital souhaité pour décès (En million) CFA</label>
                                <el-select style="width:100%" v-model="capitalDeces" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capital" :key="item" :label="item.capital" :value="item.value" ></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px" v-if="capitalValideForInvalidite">
                        <div class="grid-content bg-purple">
                                <label>Capital souhaité pour invalidité (En million) CFA</label>
                                <el-select style="width:100%" v-model="capitalInvalidite" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capital" :key="item" :label="item.capital" :value="item.value"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px" v-if="bourseValideIncapacite">
                        <div class="grid-content bg-purple">
                                <label>Bourse incapacité temporaire CFA</label>
                                <el-select style="width:100%" v-model="bourseIncapacite" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.capitalIncapacite" :key="item" :label="item.capital" :value="item.value"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px" v-if="bourseValideFrais">
                        <div class="grid-content bg-purple">
                                <label>Remboursement frais médicaux CFA</label>
                                <el-select style="width:100%" v-model="bourse" placeholder="Selectionner" @change="calculPrime()">
                                    <el-option v-for="item in iAData.fraisMedicaux" :key="item" :label="item.bourse" :value="item.bourse"></el-option>
                                </el-select>
                            </div>
                    </el-col>
                    <el-divider></el-divider>
                    <el-col :span="24" style="margin-top:15px">
                        <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="Prime_deces !== null" label="Prime Décès"><span style="color:#ffa409">{{Prime_deces.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="prime_invalidite !== null" label="Prime Invalidité"><span style="color:#ffa409">{{prime_invalidite.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="bourseIncapaciteTemporaire !== null" label="Prime Incapacité temporaire"><span style="color:#ffa409">{{bourseIncapaciteTemporaire.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="bourseFraisMedicaux !== null" label="Prime Frais médicaux"><span style="color:#ffa409">{{bourseFraisMedicaux.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="primeNette !== null" label="Prime Nette"><span style="color:#ffa409">{{primeNette.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>
                    </el-col>
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="returToFirstStep()">Retour</el-button>
                    <el-button @click="Stepper2Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>

                <h3>Assuré :</h3>
                <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="24">
                    <div class="justfy-center grid-content bg-purple-dark">
                    <p>Le souscripteur est-il le compte connecté ?</p>
                    <el-switch
                        style="display: block"
                        v-model="checkbox"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Oui"
                        inactive-text="Non"
                        @change="toggleCheckbox">
                    </el-switch>
                    </div>
                </el-col>
                </el-row>

                <el-row :gutter="20">
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                    <label>Nom</label>
                    <el-input :disabled="this.checkbox == true" placeholder="Votre nom" v-model="ContractantFields.Nom">
                    </el-input>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input :disabled="this.checkbox == true" placeholder="Votre prenom" v-model="ContractantFields.Prenom">
                    </el-input>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                    <label>Date de naissance</label>
                    <el-date-picker
                        @change="yearsDiffAssure()"
                        style="width: 100%!important;"
                        v-model="ContractantFields.dateNaissance"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date"
                        :picker-options="dateBeforeToday">
                    </el-date-picker>
                    </div>
                </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                    <label>CNI</label>
                    <el-input @keypress="onlyNumber" placeholder="Votre Cni" v-model="ContractantFields.Cni">
                    </el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                    <label>Profession</label>
                    <el-input type="text" placeholder="Votre profession" v-model="ContractantFields.Profession">
                    </el-input>
                    </div>
                </el-col>
                </el-row>


                <div style="margin-top:15px">
                <el-button style="float:left" round @click="returToSecondStep()">Retour</el-button>
                <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>

            <h3>Assuré :</h3>
            <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="24">
                <div class="justfy-center grid-content bg-purple-dark">
                <p>Le souscripteur est-il le compte connecté ?</p>
                <el-switch
                    style="display: block"
                    v-model="checkbox"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Oui"
                    inactive-text="Non"
                    @change="toggleCheckbox">
                </el-switch>
                </div>
            </el-col>
            </el-row>

            <el-row :gutter="20">
            <el-col :span="24">
                <div class="grid-content bg-purple">
                <label>Nom</label>
                <el-input :disabled="this.checkbox == true" placeholder="Votre nom" v-model="ContractantFields.Nom">
                </el-input>
                </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                <label>Prénom</label>
                <el-input :disabled="this.checkbox == true" placeholder="Votre prenom" v-model="ContractantFields.Prenom">
                </el-input>
                </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                <label>Date de naissance</label>
                <el-date-picker
                    @change="yearsDiffAssure()"
                    style="width: 100%!important;"
                    v-model="ContractantFields.dateNaissance"
                    type="date"
                    format="yyyy/MM/dd"
                    placeholder="Choississez une date"
                    :picker-options="dateBeforeToday">
                </el-date-picker>
                </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple">
                <label>CNI</label>
                <el-input placeholder="Votre Cni" v-model="ContractantFields.Cni">
                </el-input>
                </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                <label>Profession</label>
                <el-input placeholder="Votre profession" v-model="ContractantFields.Profession">
                </el-input>
                </div>
            </el-col>
            </el-row>

            <div style="margin-top:15px">
            <el-button style="float:left" round @click="returToSecondStep()">Retour</el-button>
            <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
            </div>
        </div>
        </div>

        <div v-if="e1 == 4 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3 style="font-weight:400;font-size: 20px;">Détails de l'assurance</h3>
                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <h3 style="font-weight:400;font-size: 20px;">Souscripteur</h3>
                            <!-- <label>Souscripteur</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <li style="list-style: none;font-size:14px">Nom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Nom}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prénom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Prenom}}</span>
                                </li>

                                <li  style="list-style: none;font-size:14px">Date de naissance :
                                    <span class="font-weight-bold to-right">{{DateFormater(ContractantFields.dateNaissance)}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">CNI :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Cni}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Profession :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Profession}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <h3 style="font-weight:400;font-size: 20px;">Garanties</h3>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <li style="list-style: none;font-size:14px" v-for="garantie in this.garanties" :key="garantie">Nom garantie :
                                    <span class="font-weight-bold to-right">{{garantie}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>

                <el-divider></el-divider>

                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <h3 style="font-weight:400;font-size: 20px;">Détails des capitaux</h3>
                            <!-- <label>Souscripteur</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <li v-if="capitalDeces !== null" style="list-style: none;font-size:14px">Capital Décès:
                                    <span class="font-weight-bold to-right">{{capitalDeces.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="capitalInvalidite !== null" style="list-style: none;font-size:14px">Capital Invalidité :
                                    <span class="font-weight-bold to-right">{{capitalInvalidite.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourse !== null"  style="list-style: none;font-size:14px">Frais médicaux :
                                    <span class="font-weight-bold to-right">{{bourse.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseIncapacite !== null" style="list-style: none;font-size:14px">incapacité temporaire :
                                    <span class="font-weight-bold to-right">{{bourseIncapacite.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <h3 style="font-weight:400;font-size: 20px;">Détails des primes</h3>
                            <!-- <label>Souscripteur</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <li style="list-style: none;font-size:14px">Profil:
                                    <span class="font-weight-bold to-right">{{nomClasse}}</span>
                                </li>

                                <li v-if="Prime_deces !== null" style="list-style: none;font-size:14px">Prime Décès :
                                    <span class="font-weight-bold to-right">{{Prime_deces.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="prime_invalidite !== null"  style="list-style: none;font-size:14px">Prime Invalidité :
                                    <span class="font-weight-bold to-right">{{prime_invalidite.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseIncapaciteTemporaire !== null" style="list-style: none;font-size:14px">Prime Incapacité :
                                    <span class="font-weight-bold to-right">{{bourseIncapaciteTemporaire.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseFraisMedicaux !== null" style="list-style: none;font-size:14px">Prime Frais médicaux :
                                    <span class="font-weight-bold to-right">{{bourseFraisMedicaux.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="SurprimeNette !== null" style="list-style: none;font-size:14px">Surprime Totale :
                                    <span class="font-weight-bold to-right">{{SurprimeNette.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="primeNette !== null" style="list-style: none;font-size:14px">Prime Nette :
                                    <span class="font-weight-bold to-right">{{primeNette.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="frais !== null" style="list-style: none;font-size:14px">Frais :
                                    <span class="font-weight-bold to-right">{{frais.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="taxe !== null" style="list-style: none;font-size:14px">Taxe :
                                    <span class="font-weight-bold to-right">{{taxe.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="PrimeTTC !== null" style="list-style: none;font-size:14px">Prime TTC :
                                    <span style="color:#5B94BC!important" class="font-weight-bold to-right">{{PrimeTTC.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <div>
                    <h3 style="font-weight:400;font-size: 20px;">Période de couverture</h3>
                    <!-- <h3>Période de couverture</h3> -->
                    <el-row :gutter="20" style="margin-top: -20px;">
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <!-- <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Pack"><span style="color:#ffa409" v-if="pack == 'essentiel'">Essentiel 70%</span><span style="color:#ffa409" v-if="pack == 'serenite'">Sérénité 80%</span><span style="color:#ffa409" v-if="pack == 'confort'">Confort 90%</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="prixNetTotal !== null" label="Prime TTC"><span style="color:#ffa409">{{ttc.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div> -->

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 3">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevisIndividuel()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 4 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3 style="font-weight:400;font-size: 20px;">Détails de l'assurance :</h3>
                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Souscripteur</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <li style="list-style: none;font-size:14px">Nom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Nom}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prénom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Prenom}}</span>
                                </li>

                                <li  style="list-style: none;font-size:14px">Date de naissance :
                                    <span class="font-weight-bold to-right">{{DateFormater(ContractantFields.dateNaissance)}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">CNI :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Cni}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Profession :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Profession}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Garanties</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <li style="list-style: none;font-size:14px" v-for="garantie in this.garanties" :key="garantie" >Nom garantie :
                                    <span class="font-weight-bold to-right">{{garantie}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-divider></el-divider>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Détails des capitaux</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <li v-if="capitalDeces !== null" style="list-style: none;font-size:14px">Capital Décès:
                                    <span class="font-weight-bold to-right">{{capitalDeces.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="capitalInvalidite !== null" style="list-style: none;font-size:14px">Capital Invalidité :
                                    <span class="font-weight-bold to-right">{{capitalInvalidite.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourse !== null"  style="list-style: none;font-size:14px">Frais médicaux :
                                    <span class="font-weight-bold to-right">{{bourse.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseIncapacite !== null" style="list-style: none;font-size:14px">incapacité temporaire :
                                    <span class="font-weight-bold to-right">{{bourseIncapacite.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>

                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Détails des primes</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <li style="list-style: none;font-size:14px">Profil:
                                    <span class="font-weight-bold to-right">{{nomClasse}}</span>
                                </li>

                                <li v-if="Prime_deces !== null" style="list-style: none;font-size:14px">Prime Décès :
                                    <span class="font-weight-bold to-right">{{Prime_deces.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="prime_invalidite !== null"  style="list-style: none;font-size:14px">Prime Invalidité :
                                    <span class="font-weight-bold to-right">{{prime_invalidite.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseIncapaciteTemporaire !== null" style="list-style: none;font-size:14px">Prime Incapacité :
                                    <span class="font-weight-bold to-right">{{bourseIncapaciteTemporaire.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="bourseFraisMedicaux !== null" style="list-style: none;font-size:14px">Prime Frais médicaux :
                                    <span class="font-weight-bold to-right">{{bourseFraisMedicaux.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="SurprimeNette !== null" style="list-style: none;font-size:14px">Surprime Totale :
                                    <span class="font-weight-bold to-right">{{SurprimeNette.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="primeNette !== null" style="list-style: none;font-size:14px">Prime Nette :
                                    <span class="font-weight-bold to-right">{{primeNette.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="frais !== null" style="list-style: none;font-size:14px">Frais :
                                    <span class="font-weight-bold to-right">{{frais.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="taxe !== null" style="list-style: none;font-size:14px">Taxe :
                                    <span class="font-weight-bold to-right">{{taxe.toLocaleString()}} CFA</span>
                                </li>

                                <li v-if="PrimeTTC !== null" style="list-style: none;font-size:14px">Prime TTC :
                                    <span style="color:#5B94BC!important" class="font-weight-bold to-right">{{PrimeTTC.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>

                <el-divider></el-divider>

                <div>
                    <h3 style="font-weight:400;font-size: 20px;">Période de couverture</h3>
                    <el-row :gutter="20" style="margin-top: 0px;">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px!important">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 =3">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevisIndividuel()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile"> 
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise"  value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile"> 
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise"  value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Pratiquez-vous une de ces activités ?" :visible.sync="SurprimeDialog">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="15" justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                    <el-checkbox-group v-model="Surprimes" @change="surprimeCalcul()">
                                        <el-checkbox label="Sport" >Pratique des sports</el-checkbox>
                                        <el-checkbox label="cycle_moteur" >Usage de cycle à moteur</el-checkbox>
                                    </el-checkbox-group>
                                </el-col>
                        </el-row>
                        </div>
                    </el-col>
                </el-row> 
                
                <el-row :gutter="15" justify="center" style="margin-top: 20px">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-row :gutter="15" justify="center">
                                <el-col v-if="Surprimes =='Sport'">
                                    <h1>Surprime Sport</h1>
                                    <p><span>Exclusions : escalade, alpinisme, spéléologie, skeleton, polo à cheval,
                          sport de combat sans arme (boxe, lutte, judo. karaté, etc.),
                          chasse et plongée sous-marine, yachting à moteur (embarcation de plus de 16 noeuds)</span></p>
                                </el-col>

                                <el-col v-if="Surprimes =='cycle_moteur'">
                                    <h1>Surprime Usage de cycle</h1>
                                    <p><span>Usage de cycles à moteur d'une cylindrée supérieur à <span class="font-weight-bold">50 cm3</span> motocyclettes et tricars</span></p>
                                </el-col>
                        </el-row>
                        </div>
                    </el-col>
                </el-row> 
            </el-form>
            <div>
                 <el-button @click="SurprimeValidator()" type="primary" round>Oui</el-button>
                 <el-button @click="nextToContractStep()" round>Non</el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import customPassword from "../../../services/password_generator"
import individuelAccidentData from "../../../common/indivuduelAccident.json"
import qm from "../../../common/q_medical_indiv_accident.json"

const validatePhone = phone => {
 if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire !"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
    name: "devis_individuel_accident",
    
    data() {
        return {
            // Important individuel accident 
            listClasses :  [],
            metierClasses : null,
            classe : null,
            nomClasse : null,
            iAData: [],
            CapitalItems : [],
            capitalDeces : null,
            capitalInvalidite: null,
            bourseIncapacite : null,
            bourse : null,
            capitalValideForDeces : true,
            capitalValideForInvalidite : true,
            bourseValideFrais : false,
            bourseValideIncapacite :  false,
            capitalDeces : null,
            Prime_deces : null,
            prime_invalidite : null,
            bourseFraisMedicaux : null,
            bourseIncapacite : null,
            bourseIncapaciteTemporaire : null,
            primeNette : null,
            SurprimeNette : null,
            frais : 2000,
            SurprimeDialog : false,
            Surprimes : [],
            // Contractant fields
            ContractantFields: {
                Nom: null,
                Prenom: null,
                dateNaissance: null,
                Cni: null,
                Profession: null,
            },
            checkbox : false,
            PrimeTTC : null,








          modalgenerationloading : false,
          ModalListeHabitation : false,
     
          ModalListeContrat : false,
       


          isMobile: false,
          errors : {},
             dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            optionsdate: {
                minDate: new Date(),
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            money: {
                thousands: '',
                precision: false,
                masked: false
            },
            money_2: {
                thousands: ' ',
                precision: false,
                masked: false
            },
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            telephoneC: null,
            showId: false,
            duree: null,
            e1: null,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            seen: true,
            username: null,
            telephoneclient: null,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            assureurs: "AXA",
            Assureur_si_courtier: null,
            usersearch: null,
            proprietaire: 'ASSURAF',
            dateEcheance: null,
            dateEffet: '',
            emailclient: null,
            emailC: this.emailclient,
            notifMessage: '',
            listeAssureurs: [],
            liste_type_secu: [],
            garanties: ['DECES','INVALIDITE PERMANENTE'],
            PassengerBirthOptionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            telephoneC: null,
            showId: false,
            nom: null,
            prenom: null,
            email: null,
            duree: null,
            profession:null,
            genre:null,
            datenaissance: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            emailclient: null,
            emailC: this.emailclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            hideForm: false,
           
            steps: [{
                    label: 'Etape 1',
                    completed: false,
                },
                {
                    label: 'Etape 2',
                    completed: false,
                },
                {
                    label: 'Etape 3',
                    completed: false,
                },
            ],
            seen: true,
            selected: [],
            usersearch: [],
            telephoneclient: '',
            destination: null,
         
            NumberInput : false,
            EmailInput : false,
            
            radio: '1',
            voidField: false,
          
            Deb: null,
            selectedcarbu: '',
           
            types: [
                'text',
                'text',
                'number',
            ],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: [],
                userRole:null
        }
    },
    mounted() {
        this.iAData = individuelAccidentData;
        console.log(':::::::::::::: choix garanties :::::::::::::', this.iAData)
        this.getListeClass();
        this.getUserRoles()
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getListePartenaire();
            this.duree = 12
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
    watch:{
        assureurs(val)
        {
            let cpt = 0
            for(let v of this.assureurs)
            {
                if(v=='SALAMA')
                {
                    for(let g of this.garanties)
                    {
                        if(g=='VOL PAR EFFRACTION' && this.vol_par_efraction==null){
                            this.garantie_vol_efra = g
                            cpt = cpt+1
                        }

                        if(g=='DEGAT DES EAUX' && this.degat_des_eaux ==null){
                            this.garantie_deg_eaux = g
                            cpt = cpt+1
                        }

                        if(g=='BRIS DE GLACES' && this.bris_de_glaces==null){
                            this.garantie_gdg = g
                            cpt = cpt+1
                        }
                    }
                }
                    
            }
            if(cpt>=1){
                this.popup_extension_salama = true 
            }
        },
    },
    methods: {
        /*===Send of data to API allowing create proposition for the customer===*/
        CreateDevisIndividuel() {
        if (this.dateEffet) {
                const Individuel = {
                    branche:"Individuel accident",
                    assureurs:"AXA",
                    Contractor : this.ContractantFields,
                    Classe : this.classe,
                    CapitalDeces : this.capitalDeces,
                    CapitalInvalidite : this.capitalInvalidite,
                    garanties: this.garanties,
                    Bourse : this.bourse,
                    BourseIncapacite : this.bourseIncapacite,
                    bourseIncapaciteTemporaire :  this.bourseIncapaciteTemporaire,
                    BourseFraisMedicaux : this.bourseFraisMedicaux,
                    PrimeDeces : this.Prime_deces,
                    PrimeInvalidite : this.prime_invalidite,
                    surprimes:this.SurprimeNette,
                    MontantNet: this.primeNette,
                    userid: this.usersearch._id, //store.state.user._id,
                    clientid: this.usersearch._id, //store.state.user._id,
                    createur: store.state.user._id,
                    dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
                    dateEcheance: this.dateEcheance,
                    dateSouscription: new Date(),
                    FuturPaiement: null,
                    plateforme : 'Dash',
                };

                if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
                Sunu.courtierid = this.$store.state.user.societe;
                }

                Api()
                    .post("/individuelaccident/add", Individuel)
                    .then((result) => {
                    Api()
                        .get("devis/" + result.data.devis._id)
                        .then((response) => {
                            this.modalgenerationloading = true
                            this.$store.dispatch("setDevisPayement", response.data);
                            setTimeout(() => (
                                this.modalgenerationloading = false,
                                this.$router.go(this.$router.push({
                                name: 'paiement'
                            }))), 4000
                            );
                        });
                    })
                    .catch((err) => {
                    console.log("error");
                    });
            
        } else {
            this.$message.error('Date d\'effet obligatoire !', this.AlertMessage)
        }
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format("DD-MM-YYYY");
            }
        },

        garantiesValidator() {
            const garantieMap = {
                'DECES': 'CapitalDeces',
                'INVALIDITE PERMANENTE': 'CapitalInvalidite',
                'FRAIS MEDICAUX': 'BourseFraisMedicaux',
                'INCAPACITE TEMPORAIRE': 'BourseIncapacite'
            };
          
            const result = this.garanties.reduce((acc, garantie) => {
                const key = garantieMap[garantie];
                if (key) {
                acc[key] = true;
                }
                return acc;
            }, {
                CapitalDeces: false,
                CapitalInvalidite: false,
                BourseFraisMedicaux: false,
                BourseIncapacite: false
            });

            console.log(":::::::::: resultat ::::::::::::",result);
            this.capitalValideForDeces = result.CapitalDeces;
                if (!result.CapitalDeces) {
                    this.capitalDeces = null;
                    this.Prime_deces = null;
                }
            // Ajoutez ici la logique pour les autres garanties si nécessaire
            this.capitalValideForInvalidite = result.CapitalInvalidite;
                if (!result.CapitalInvalidite) {
                    this.CapitalInvalidite = null;
                    this.prime_invalidite = null;
                }
            this.bourseValideFrais = result.BourseFraisMedicaux;
                if (!result.BourseFraisMedicaux) {
                    this.bourse = null;
                    this.bourseFraisMedicaux = null;
                }
            this.bourseValideIncapacite = result.BourseIncapacite;
                if (!result.BourseIncapacite) {
                    this.bourseIncapacite = null;
                    this.bourseIncapaciteTemporaire = null;
                }

        },

        calculPrime() {
            const C = this.classe;
            const tauxParClasseDeces = {
                1: 1.3,
                2: 1.7,
                3: 2.2,
                4: 2.7,
                5: 5.2
            };
            const tauxParClasseInvalide = {
                1: 1.4,
                2: 1.9,
                3: 2.5,
                4: 3.2,
                5: 5.2
            };
            const tauxParClasseIncapacite = {
                1: 2.3,
                2: 3,
                3: 4,
                4: 7,
                5: 8
            };

            if (this.garanties.includes('DECES') && tauxParClasseDeces[C]) {
                this.Prime_deces = this.capitalDeces * tauxParClasseDeces[C] / 1000;
                console.log('::::::::::::: PRIME DECES :::::::::::::::', this.Prime_deces)
            } else {
                this.Prime_deces = null; // ou une autre valeur par défaut si nécessaire
            }

            if (this.garanties.includes('INVALIDITE PERMANENTE') && tauxParClasseInvalide[C]) {
                this.prime_invalidite = this.capitalInvalidite * tauxParClasseInvalide[C] / 1000;
                console.log('::::::::::::: PRIME INVALIDITE :::::::::::::::', this.prime_invalidite)
            } else {
                this.prime_invalidite = null; // ou une autre valeur par défaut si nécessaire
            }

            if (this.garanties.includes('INCAPACITE TEMPORAIRE') && tauxParClasseIncapacite[C]) {
                this.bourseIncapaciteTemporaire = this.bourseIncapacite * tauxParClasseIncapacite[C];
                console.log('::::::::::::: PRIME INCAPACITE :::::::::::::::', this.bourseIncapaciteTemporaire)
            } else {
                this.bourseIncapaciteTemporaire = null; // ou une autre valeur par défaut si nécessaire
            }

            if (this.garanties.includes('FRAIS MEDICAUX') && C) {
                if(this.bourse == 125000){
                    if(C == 1) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[0].donne_prime[0].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    } else if (C == 2) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[0].donne_prime[1].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 3) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[0].donne_prime[2].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 4) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[0].donne_prime[3].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 5) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[0].donne_prime[4].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }
                } else if(this.bourse == 250000){
                    if(C == 1) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[1].donne_prime[0].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    } else if (C == 2) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[1].donne_prime[1].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 3) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[1].donne_prime[2].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 4) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[1].donne_prime[3].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 5) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[1].donne_prime[4].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }
                } else if(this.bourse == 500000){
                    if(C == 1) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[2].donne_prime[0].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    } else if (C == 2) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[2].donne_prime[1].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 3) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[2].donne_prime[2].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 4) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[2].donne_prime[3].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }else if (C == 5) {
                        this.bourseFraisMedicaux = this.iAData.fraisMedicaux[2].donne_prime[4].prime
                        console.log('::::::::::::: PRIME FRAIS MEDICAUX :::::::::::::::', this.bourseFraisMedicaux)
                    }
                }
            } else {
                this.bourseFraisMedicaux = null; // ou une autre valeur par défaut si nécessaire
            }

            this.calculPrimeTotal()

        },

        calculPrimeTotal(){
            this.primeNette = this.Prime_deces + this.prime_invalidite + this.bourseFraisMedicaux + this.bourseIncapaciteTemporaire + this.SurprimeNette
            this.calculTaxe()
            console.log(":::::::::::::::::: PRIME NETTE TOTAL :::::::::::::::::::",this.primeNette)
        },

        calculTaxe(){
            this.taxe = (this.primeNette + this.frais) * 0.1
            console.log(":::::::::::::::::: CALCUL TAXE :::::::::::::::::::",this.primeNette)
        },

        surprimeCalcul(){
            for (let index = 0; index < this.garanties.length; index++) {
                if(this.Surprimes.indexOf("Sport") !== -1 && this.Surprimes.indexOf("cycle_moteur") !== -1){
                    if(this.garanties[index] == 'DECES'){
                        this.Surprime_cycle_deces =  0 //this.capitalDeces * 0.75/1000
                    console.log(this.Surprime_cycle_deces)
                    }
                    else if(this.garanties[index] == 'INVALIDITE PERMANENTE') 
                    {
                    this.Surprime_cycle_invalidite =  0 //this.capitalInvalidite * 0.75/1000
                    console.log(this.Surprime_cycle_invalidite)
                    }
                    else if(this.garanties[index] == 'INCAPACITE TEMPORAIRE')
                    {
                    this.Surprime_cycle_incapacite =  0 //this.bourseIncapacite * 1
                    console.log(this.Surprime_cycle_incapacite)
                    }
                    else if(this.garanties[index] == 'FRAIS MEDICAUX')
                    {
                    if(this.bourse == 125000)
                    {
                        this.Surprime_cycle_fraisMedicaux =  0 //2500
                        console.log(this.Surprime_cycle_fraisMedicaux)
                    } else if(this.bourse == 250000)
                    {
                        this.Surprime_cycle_fraisMedicaux =  0 //3000
                    }else if(this.bourse == 500000) 
                    {
                        this.Surprime_cycle_fraisMedicaux =  0 //3750
                    }
                    }
                }else {
                    this.Surprime_sport_deces = null
                    this.Surprime_sport_invalidite = null
                    this.Surprime_sport_incapacite = null
                    this.Surprime_Sport_fraisMedicaux = null

                    this.Surprime_cycle_deces = null
                    this.Surprime_cycle_invalidite = null
                    this.Surprime_cycle_incapacite = null
                    this.Surprime_cycle_fraisMedicaux = null

                    if(this.garanties[index] == 'DECES')
                    {
                    if(this.Surprimes == 'Sport')
                    {
                        this.Surprime_sport_deces = 0 //this.capitalDeces * 0.3/1000
                        console.log(this.Surprime_sport_deces)
                    }else if(this.Surprimes == 'cycle_moteur')
                    {
                        this.Surprime_cycle_deces =  0 //this.capitalDeces * 0.75/1000
                        console.log(this.Surprime_cycle_deces)
                    } else {
                        this.Surprime_cycle_deces = 0
                        this.Surprime_cycle_deces = 0
                    }

                    }
                    else if(this.garanties[index] == 'INVALIDITE PERMANENTE')
                    {
                    if(this.Surprimes == 'Sport')
                    {
                        this.Surprime_sport_invalidite =  0 //this.capitalInvalidite * 0.6/1000
                    }else if(this.Surprimes == 'cycle_moteur')
                    {
                        this.Surprime_cycle_invalidite =  0 //this.capitalInvalidite * 0.75/1000
                    } else {
                        this.Surprime_sport_invalidite = 0
                        this.Surprime_cycle_invalidite = 0
                    }
                    }
                    else if(this.garanties[index] == 'INCAPACITE TEMPORAIRE')
                    {
                    if(this.Surprimes == 'Sport')
                    {
                        this.Surprime_sport_incapacite =  0 //this.bourseIncapacite * 1
                    }else if(this.Surprimes == 'cycle_moteur')
                    {
                        this.Surprime_cycle_incapacite =  0 //this.bourseIncapacite * 1
                    } else {
                        this.Surprime_sport_incapacite = 0
                        this.Surprime_cycle_incapacite = 0
                    }
                    }
                    else if(this.garanties[index] == 'FRAIS MEDICAUX')
                    {
                    if(this.Surprimes == 'Sport')
                    {
                        if(this.bourse == 125000)
                        {
                        this.Surprime_Sport_fraisMedicaux =  0 //1250
                        } else if(this.bourse == 250000)
                        {
                        this.Surprime_Sport_fraisMedicaux =  0 //1750
                        }else if(this.bourse == 500000) 
                        {
                        this.Surprime_Sport_fraisMedicaux =  0 //2250
                        }
                    }
                    else if(this.Surprimes == 'cycle_moteur')
                    {
                        if(this.bourse == 125000)
                        {
                        this.Surprime_cycle_fraisMedicaux =  0 //2500
                        } else if(this.bourse == 250000)
                        {
                        this.Surprime_cycle_fraisMedicaux =  0 //3000
                        }else if(this.bourse == 500000) 
                        {
                        this.Surprime_cycle_fraisMedicaux =  0 //3750
                        }
                    } else {
                        this.Surprime_Sport_fraisMedicaux = 0
                        this.Surprime_cycle_fraisMedicaux = 0
                    }
                    }
                }
            }
            this.calculPrimeTotal()
        },

        returToFirstStep(){
            this.e1 = 0
            this.bourse = null
            this.capitalDeces = null
            this.capitalInvalidite = null
            this.bourseIncapacite = null
            this.bourse = null
            this.garanties = ['DECES','INVALIDITE PERMANENTE']
        },

        nextToContractStep(){
            this.SurprimeDialog = false
            this.e1 = 3
            this.Surprimes = null
        },

        SurprimeValidator(){
            if(this.Surprimes.indexOf("Sport") !== -1 || this.Surprimes.indexOf("cycle_moteur") !== -1 ){
                let primeDeces = this.Surprime_sport_deces + this.Surprime_cycle_deces
                let primeInvalidite = this.Surprime_sport_invalidite + this.Surprime_cycle_invalidite
                let primeIncapacite = this.Surprime_sport_incapacite + this.Surprime_cycle_incapacite
                let primeFraisMedicaux = this.Surprime_Sport_fraisMedicaux + this.Surprime_cycle_fraisMedicaux
                this.SurprimeNette = primeDeces + primeInvalidite + primeIncapacite + primeFraisMedicaux
                this.SurprimeDialog = false
                this.e1 = 3
                this.calculPrimeTotal()
            }else {
                this.$message.error("Veuillez faire un choix!", this.AlertMessage)
            }
        },

        getClassforDevis(item){
            if(item == 1) {
                this.classe = item;
                this.nomClasse =  "Bureau";
            }else if(item == 2) {
                this.classe = item;
                this.nomClasse =  "Employé bureau + Manuel";
            }else if(item == 3) {
                this.classe = item;
                this.nomClasse =  "Artisans";
            }else if(item == 4) {
                this.classe = item;
                this.nomClasse =  "Industrie";
            }else if(item == 5) {
                this.classe = item;
                this.nomClasse =  "Construction";
            }
            this.e1 = 1

        },

        getListeClass(){
            Api().get('/classe_metier/index')
          .then(response => {
            this.listClasses = response.data
            console.log('::::::::::::: liste des classes ::::::::::::::', this.listClasses);
            this.metierClasses = this.listClasses
          })
        },

        toggleCheckbox(){
            if(this.checkbox == true){
                this.ContractantFields.Nom = this.usersearch.nom
          this.ContractantFields.Prenom = this.usersearch.prenom
            }else if(this.checkbox == false) {
                this.ClearForm()
            }

        },
        ClearForm(){
            this.ContractantFields.Nom = null
            this.ContractantFields.Prenom = null
        },
        returToSecondStep(){
            this.e1 = 1
        },

        ContractControl(){
            if (this.ContractantFields.Nom && this.ContractantFields.Prenom) {
        if(this.ContractantFields.dateNaissance){
          if(this.ContractantFields.Cni && this.ContractantFields.Profession){
            this.ContractantFields.Questionnaire = qm
            this.e1 = 4
            this.calculPrimeTTC()
          }else {
            this.$message.error("Cni et Profession obligatoires !", this.AlertMessage)
          }
        }else {
            this.$message.error("Date de naissance obligatoire !", this.AlertMessage)
        }
      } else {
        this.$message.error("Nom et Prénom obligatoires !", this.AlertMessage)
      }
        },

        calculPrimeTTC (){
      this.PrimeTTC =  this.primeNette + this.taxe + this.frais
        },

        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },
        
        RoundToMillion(num) {
            var parts = num.split(" ");
            var fac = Math.pow(1000, parts.length - 1)
            return (Math.ceil(parseInt(parts.join("")) / fac) * fac)
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },

        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },

        AlertMessage() {
            this.$message({
            showClose: false,
            duration : 5000
            });
        },

        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },

        rechercherClient() {
            if(this.telephoneclient){
            const validPhone = validatePhone(this.telephoneclient);
            this.errors.phone = validPhone.error;

            if(validPhone.valid == true){
                Api().get('/user/phone/' + this.telephoneclient)
                    .then(resultat => {
                    if (resultat.data.telephone_port === this.telephoneclient) {
                        this.clientOk = true
                        this.clientnonOk = false
                        this.usersearch = resultat.data;
                        this.$store.dispatch('setClient', resultat.data)
                        this.userHabitationId = this.usersearch._id

                        let useridentity = this.usersearch._id

                        Api().post("habitation/myhouse", {userid: useridentity})
                            .then(response => {
                            this.listHabitations = response.data.logements
                        });
                    }

                    })
                    .catch(e => {
                    this.clientnonOk = true
                    this.clientOk = false
                    this.telephoneC = this.telephoneclient;
                    })
            } else {
                this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
            }
            }else {
            this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
            }


        },

        rechercheClientByMail(){
            if(this.emailclient){

            const validEmail = validateEmail(this.emailclient);
            this.errors.email = validEmail.error;

            if(validEmail.valid == true){
                Api().get('/user/email/' +this.emailclient)
                    .then(resultat =>{
                    if (resultat.data.email === this.emailclient) {
                        this.clientOk = true
                        this.clientnonOk = false
                        this.usersearch = resultat.data;
                        this.$store.dispatch('setClient', resultat.data)

                        this.userHabitationId = this.usersearch._id

                        let useridentity = this.usersearch._id

                        Api().post("habitation/myhouse", {userid: useridentity})
                            .then(response => {
                            this.listHabitations = response.data.logements
                        });

                    }
                    }).catch(e => {
                this.clientnonOk = true
                this.clientOk = false
                this.emailC = this.emailclient;
                })
            } else {
                this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
            }
            }else {
            this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 0
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },
        //liste de touts les partenaires
        getListePartenaire: function () 
        {
            if (this.$store.state.user.role == 'Agent')
            {
                Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Habitation"})
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires[0].assureurs
                    }).catch(err => {})
            } else if (this.$store.state.user.role == 'SuperAdmin'  || this.$store.state.user.role == 'Admin') 
            {
                Api().get('/partenaire/assureur/')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires
                    })
            } else if (this.$store.state.user.role == 'assureur') 
            {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }

        },

        HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        // Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) 
            {
                const user = {
                        nom: this.nom,
                        prenom: this.prenom,
                        datenaissance: this.datenaissance,
                        profession: this.profession,
                        adresse: this.adresse,
                        sexe: this.genre,
                        telephone_port: this.telephoneclient,
                        email: this.emailclient,
                        password: this.password1,
                        confirmPass: this.password1,
                        role: this.userRole,
                        createdby:store.state.user._id,
                        photo: this.file,
                        piece_identite: this.piece_identite,
                        numero_piece: this.numero_piece,
                         plateforme : 'Dash',
                        created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 0
                                }
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }

                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
               
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },

        dateDiff: function () {
            if (this.dateEffet === '' & this.duree === '') this.dateEcheance = ''
            let inter = dayjs(this.dateEffet).add(1, 'year').format('YYYY-MM-DD')
            this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
        },

        Stepper2Validator() {
            if (this.garanties.length >= 1) {
                if(this.garanties.indexOf("DECES") !== -1 || this.garanties.indexOf("INVALIDITE PERMANENTE") !== -1){
                    console.log('::::::::::: GARANTIES ::::::::::::::', this.garanties.length)
                for (let index = 0; index < this.garanties.length; index++) {
                    if (this.garanties[index] == 'DECES') {
                    if (this.capitalDeces) {
                        if(this.capitalValideForInvalidite == true){
                        if(this.capitalInvalidite){
                            this.SurprimeDialog = true
                        }
                        }else {
                        this.SurprimeDialog = true
                        }
                    } else {
                        this.$message.error("Capital Décès obligatoire", this.AlertMessage)
                    }
                    }
                    else if(this.garanties[index] == 'INVALIDITE PERMANENTE'){
                    if(this.capitalInvalidite){
                        if(this.capitalValideForDeces == true){
                        if(this.capitalDeces){
                            this.SurprimeDialog = true
                        }
                        }else {
                        this.SurprimeDialog = true
                        }
                    }else {
                        this.$message.error("Capital invalidité obligatoire", this.AlertMessage)
                    }
                    }
                    else if(this.garanties[index] == 'INCAPACITE TEMPORAIRE'){
                    if(this.bourseIncapacite){
                        this.step = 3
                    }else {
                        this.$message.error("Ramboursement incapacité temporaire obligatoire!", this.AlertMessage)
                    }
                    }
                    else if(this.garanties[index] == 'FRAIS MEDICAUX'){
                    if(this.bourse){
                        if(this.bourseValideIncapacite == true){
                        if(this.bourseIncapacite){
                            this.SurprimeDialog = true
                        }
                        }else {
                        this.SurprimeDialog = true
                        }
                        //this.step = 3
                    }else {
                        this.$message.error("Ramboursement obligatoire", this.AlertMessage)
                    }
                    }
                }
                }else {
                    this.$message.error("Garantie Décès ou Invalidite permanente obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error("Veuillez cocher une garantie au minimum !", this.AlertMessage)
            }
        },

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },

        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),
    
    },
    created(){
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
