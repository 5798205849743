<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste contrats <span style="color:#ffa409">d'encaissement</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="card-form card-base">
      <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow m-10 mt-5">
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="grid-content bg-purple mr-5">
                <label>Période</label>
                <el-date-picker style="width: 100%!important;" v-model="form.date" type="daterange" range-separator="à"
                  start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy" @change="onChange">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <label>Selectionner la branche(s)</label>
                <el-select style="width: 100%!important;" v-model="form.branches" multiple filterable allow-create
                  default-first-option placeholder="Selectionner branche(s)">
                  <el-option v-for="items in brancheOptions" :key="items" :label="items.branche" :value="items._id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <label>Selectionner l'assureur(s)</label>
                <el-select style="width: 100%!important;" v-model="form.assureurs" multiple filterable allow-create
                  default-first-option placeholder="Selectionner assureur(s)">
                  <el-option v-for="items in assureurOptions" :key="items" :label="items.societe" :value="items.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light mt-20">
                <el-button class="float-right" @click="goFilter()" type="primary" round>Valider</el-button>
              </div>
            </el-col>
          </el-row>

        </div>
      </div>

      <div class="toolbar-box flex align-center" v-if="isMobile">
        <div class="box grow m-10 mt-5">
          <el-row :gutter="24">
            <el-col :span="24">
              <div class="grid-content bg-purple mr-5">
                <label>Période</label>
                <el-date-picker style="width: 100%!important;" v-model="form.date" type="daterange" range-separator="à"
                  start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy" @change="onChange">
                </el-date-picker>
              </div>
              <!-- <el-date-picker style="width: 100%!important;" v-model="form.debut_periode" type="date"
                  @change="onChange" placeholder="Debut filtre" :picker-options="dateBeforeToday">
                </el-date-picker> -->

            </el-col>
            <!-- <el-col :span="5">
              <div class="grid-content bg-purple">
                <label>Au</label>
                <el-date-picker style="width: 100%!important;" v-model="form.fin_periode" type="date"
                  placeholder="Fin filtre" :picker-options="dateBeforeToday">
                </el-date-picker>
              </div>
            </el-col> -->
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Selectionner la branche(s)</label>
                <el-select style="width: 100%!important;" v-model="form.branches" multiple filterable allow-create
                  default-first-option placeholder="Selectionner branche(s)">
                  <el-option v-for="items in brancheOptions" :key="items" :label="items.branche" :value="items._id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <label>Selectionner l'assureur(s)</label>
                <el-select style="width: 100%!important;" v-model="form.assureurs" multiple filterable allow-create
                  default-first-option placeholder="Selectionner assureur(s)">
                  <el-option v-for="items in assureurOptions" :key="items" :label="items.societe" :value="items.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="grid-content bg-purple-light mt-20">
                <el-button class="float-right" @click="goFilter()" type="primary" round>Valider</el-button>
              </div>
            </el-col>
          </el-row>

        </div>
      </div>

    </div>

    <div class="mt-10 mb-5">
      <el-row :gutter="15">
        <el-col :span="18">
          <el-button type="primary" round :disabled="!checkfiltre" @click="dialogBordereaux = true">Exporter en
            Excel</el-button>
          <el-button type="primary" round @click="modalManyEncaissements()"> Plusieurs encaissements</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="Rechercher ..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <resize-observer @notify="handleResize" />

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :row-class-name="tableRowClassName" v-loading="loading" :data="listInPage" style="width: 100%;"
        v-if="ready" :height="height" @selection-change="handleSelectionChange">
        <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.user)">
                {{ scope.row.user.prenom }} - {{ scope.row.user.nom }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Avenant" min-width="180" prop="type_avenant"></el-table-column>

        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime NETTE" min-width="150" prop="prime_nette">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_nette.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Accessoires" min-width="150" prop="accessoires">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.accessoires.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Encaissement" prop="etat_encaissement" min-width="150">
          <template slot-scope="scope">
            <b>
              <span :style="scope.row.etat_encaissement == 'Non'
                  ? 'color : red;'
                  : 'color : green;'
                ">
                {{ scope.row.etat_encaissement }}
              </span>
            </b>
          </template>
        </el-table-column>
        <el-table-column label="Moyen paiement" min-width="150" prop="moyen_paiement"></el-table-column>
        <el-table-column label="Numéro" min-width="150" prop="num_cheque_encaissement"></el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="toggleDetails(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" type="primary" @click="toggleEncaissement(scope.row)" icon="el-icon-edit"
              circle></el-button>
            <el-button size="mini" type="info" @click="confirmReinitialiser(scope.row)" icon="el-icon-refresh-right"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <el-dialog title="Export un bordereaux en format excel" width="50%" :visible.sync="dialogBordereaux"
      v-if="dialogBordereaux == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div>
            <el-input placeholder="Description du bordereau..." type="textarea" v-model="form.description"></el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="exportBordereaux()"
          v-loading.fullscreen.lock="loading">Exporter</el-button>
        <el-button type="danger" round @click="dialogBordereaux = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire d'encaissement " :visible.sync="editEncaissement"
      v-if="editEncaissement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Etat Encaissement</label>
                <el-select style="width: 100% !important" v-model="detailEncaissement.etat_encaissement"
                  placeholder="Selectionner ---">
                  <el-option value="Non">Non</el-option>
                  <el-option value="Oui">Oui</el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" class="mt-5">
              <div>
                <label>Moyen de paiement</label>
                <el-select style="width: 100% !important" v-model="detailEncaissement.moyen_paiement"
                  placeholder="Selectionner ---" @change="updateLabelDate()">
                  <el-option value="Chéque"></el-option>
                  <el-option value="Virement"></el-option>
                  <el-option value="Autre"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15" v-if="detailEncaissement.moyen_paiement == 'Autre'">
            <el-col :span="12" class="mt-5">
              <div>
                <label>Mode de paiment</label>
                <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailEncaissement.num_cheque_encaissement" placeholder="Numéro du reçu ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailEncaissement.date_encaissement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-else>
            <el-col :span="12" class="mt-5" v-if="detailEncaissement.moyen_paiement != 'Virement'">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailEncaissement.num_cheque_encaissement" placeholder="Numéro  ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Banque</label>
                <el-input v-model="detailEncaissement.banque_encaissement" placeholder="banque"
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailEncaissement.date_encaissement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>

        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="goEncaissement()">Encaisser</el-button>
          <el-button type="danger" round @click="editEncaissement = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire d'encaissement " :visible.sync="editManyEncaissement"
      v-if="editManyEncaissement == true">
      <el-card class="box-card">
        <div style="text-align: center">
          <el-transfer :titles="['contrats', 'encaissement']" filterable :button-texts="['Retirer', 'Ajouter']"
            style="text-align: left; display: inline-block;" :filter-method="filterMethod" filter-placeholder="filtre ..."
            v-model="listencaissement" :data="newlistcontrat">
          </el-transfer>
        </div>
      </el-card>
      <el-card class="mt-10">
        <el-row :gutter="15">
          <el-col :span="8" class="mt-5">
            <div>
              <label>Etat Encaissement</label>
              <el-select style="width: 100% !important" v-model="encaissement.etat_encaissement"
                placeholder="Selectionner ---">
                <el-option value="Non">Non</el-option>
                <el-option value="Oui">Oui</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5">
            <div>
              <label>Moyen de paiement</label>
              <el-select style="width: 100% !important" v-model="encaissement.moyen_paiement" @change="updateLabelDate()"
                placeholder="Selectionner ---">
                <el-option value="Chéque"></el-option>
                <el-option value="Virement"></el-option>
                <el-option value="Autre"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Chéque'">
            <div>
              <label>Numéro</label>
              <el-input v-model="detailEncaissement.num_cheque_encaissement" placeholder="Numéro  ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Autre'">
            <div>
              <label>Mode de paiment</label>
              <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
            </div>

          </el-col>
          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Autre'">
            <div>
              <label>Numéro </label>
              <el-input v-model="encaissement.num_cheque_encaissement" placeholder="Numéro du reçu ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement !== 'Autre'">
            <div>
              <label>Banque</label>
              <el-input v-model="encaissement.banque_encaissement" placeholder="banque"
                class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5">
            <div>
              <label>Date {{ labelDate }}</label><br />
              <el-date-picker v-model="encaissement.date_encaissement" type="date" format="dd/MM/yyyy"
                :picker-options="dateBeforeToday" placeholder="Date encaissée" />
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="mb-5" style="margin-top: 15px">
        <el-button type="primary" round @click="goManyEncaissement()">Valider</el-button>
        <el-button type="danger" round @click="editManyEncaissement = false">Annuler</el-button>
      </div>

    </el-dialog>

    <el-dialog width="50%" title="Détails" :visible.sync="showencaissement">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style: none">
                  <li>
                    Assureur :
                    <span class="to-right assuraf-orange">{{
                      viewAssureur(detailEncaissement.partenaireid)
                    }}</span>
                  </li>
                  <li>
                    Branche :
                    <span class="to-right assuraf-orange">
                      {{ viewBranche(detailEncaissement.brancheid) }}
                    </span>
                  </li>

                  <li>
                    Contrat :
                    <span class="to-right assuraf-orange">{{
                      viewPolice(detailEncaissement)
                    }}</span>
                  </li>

                  <li>
                    Prime nette:
                    <span class="to-right assuraf-orange">{{
                      viewPrime(detailEncaissement)
                    }}</span>
                  </li>

                  <li>
                    Date d'éffet :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailEncaissement.dateEffet)
                    }}</span>
                  </li>

                  <li>
                    Date d'échéance :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailEncaissement.dateEcheance)
                    }}</span>
                  </li>

                  <li>
                    Date de création :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailEncaissement.dateSouscription)
                    }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Etat encaissement :
                    <span class="to-right" :style="detailEncaissement.etat_encaissement == 'Non'
                          ? 'color : red;'
                          : 'color : green;'
                        ">
                      {{
                        detailEncaissement.etat_encaissement != undefined ?
                        detailEncaissement.etat_encaissement :
                        null
                      }}</span>
                  </li>
                  <li>
                    Moyen de paiement :
                    <span class="to-right">{{
                      detailEncaissement.moyen_paiement != undefined ? detailEncaissement.moyen_paiement : null
                    }} </span>
                  </li>
                  <li v-if="detailEncaissement.moyen_paiement != 'Virement'">
                    Numéro :
                    <span class="to-right">{{
                      detailEncaissement.num_cheque_encaissement != undefined ?
                      detailEncaissement.num_cheque_encaissement : null
                    }}</span>
                  </li>
                  <li
                    v-if="detailEncaissement.moyen_paiement == 'Virement' || detailEncaissement.moyen_paiement == 'Chéque'">
                    Banque:
                    <span class="to-right">{{
                      detailEncaissement.banque_encaissement != undefined ? detailEncaissement.banque_encaissement :
                      null
                    }}</span>
                  </li>
                  <li>
                    Date :
                    <span class="to-right">{{
                      detailEncaissement.date_encaissement != undefined ?
                      DateFormater(detailEncaissement.date_encaissement) : null
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Voulez-vous vraiment réinitialiser l'encaissement ?"
      :visible.sync="initEncaissement" v-if="initEncaissement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div>
            <ul style="list-style: none">
              <li>
                Contrat :
                <span class="to-right assuraf-orange">{{
                  viewPolice(detailEncaissement)
                }}</span>
              </li>
              <li>
                <el-divider />
              </li>
              <li>
                Etat encaissement :
                <span class="to-right" :style="detailEncaissement.etat_encaissement == 'Non'
                      ? 'color : red;'
                      : 'color : green;'
                    ">
                  {{
                    detailEncaissement.etat_encaissement != undefined ?
                    detailEncaissement.etat_encaissement :
                    null
                  }}</span>
              </li>
              <li>
                Moyen de paiement :
                <span class="to-right">{{
                  detailEncaissement.moyen_paiement != undefined ? detailEncaissement.moyen_paiement : null
                }} </span>
              </li>
              <li v-if="detailEncaissement.moyen_paiement != 'Virement'">
                Numéro :
                <span class="to-right">{{
                  detailEncaissement.num_cheque_encaissement != undefined ?
                  detailEncaissement.num_cheque_encaissement : null
                }}</span>
              </li>
              <li v-if="detailEncaissement.moyen_paiement == 'Virement' || detailEncaissement.moyen_paiement == 'Chéque'">
                Banque:
                <span class="to-right">{{
                  detailEncaissement.banque_encaissement != undefined ? detailEncaissement.banque_encaissement :
                  null
                }}</span>
              </li>
              <li>
                Date :
                <span class="to-right">{{
                  detailEncaissement.date_encaissement != undefined ?
                  DateFormater(detailEncaissement.date_encaissement) : null
                }}</span>
              </li>
            </ul>
          </div>
        </el-card>
        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="goEncaissementReinitialiser()">Oui</el-button>
          <el-button type="danger" round @click="initEncaissement = false">Non</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="50%!important" title="Renseigner le moyen de paiement ?" :visible.sync="showautres"
      v-if="showautres == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div class="container">
            <el-input v-model="autre_moyen_paiement" placeholder="moyen de paiement" class="input-with-select"></el-input>
          </div>
        </el-card>
        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="selectAutres()">Valider</el-button>
          <el-button type="danger" round @click="showautres = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months priore
    const maxDate = new Date(today);

    return {
      labelDate: "d'encaissement",
      newlistcontrat: [],
      listencaissement: [],
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      filterMethod(query, item) {
        return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
      encaissement: {
        etat_encaissement: '',
        moyen_paiement: '',
        banque_encaissement: '',
        num_cheque_encaissement: '',
        date_encaissement: '',
      },
      showUser: false,
      detailEncaissement: {},
      editManyEncaissement: false,
      showencaissement: false,
      showautres: false,
      editEncaissement: false,
      initEncaissement: false,
      checkfiltre: false,
      dialogBordereaux: false,
      listbranche: [],
      listassureur: [],
      count: 0,
      max: maxDate,
      recherche: false,
      totalRows: null,
      perPage: 10,
      listuser: null,
      listcontrat: [],
      modalgeneration: false,
      filter: null,
      AUTRES: "",
      form: {
        date: [],
        branches: [],
        assureurs: [],
        description: "",
      },
      assureurOptions: [],
      brancheOptions: [],
      listbranche: [],
      detailbranche: [],
      showpack: false,
      nombranche: null,

      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: '',
        content: ''
      },
      userflette: [],
      fletteRows: null,
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      showuser: false,
      voidField: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      autre_moyen_paiement: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listbranche,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
    }
  },
  computed: {
    listFiltered() {
      if (this.search.length >= 1) {
        return this.listcontrat.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              ctrl = true;
              /*on le push dans le tableau */
              //this.listcontrat.push(obj)
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                });
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (
                !Array.isArray(obj[k]) &&
                typeof obj[k] == "object" &&
                obj[k] != null
              ) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = [];

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k]);

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;

                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                });
              }
            }
          }
          return ctrl;
        });
      } else {
        return this.listcontrat.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true;
          }
          return ctrl;
        });
      }
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    },
    sortOptions() {
      return this.listuser
        .filter((f) => f.sortable)
        .map((f) => {
          return {
            text: f.label,
            value: f.key,
          };
        });
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      const av = row.type_avenant;
      if (av != undefined && av.toUpperCase() == "ANNULATION") {
        return 'warning-row';
      }
    },
    updateLabelDate() {
      if (this.encaissement.moyen_paiement == "Virement" || this.detailEncaissement.moyen_paiement == "Virement") {
        this.labelDate = "du virement"
      }
      if (this.encaissement.moyen_paiement == "Chéque" || this.detailEncaissement.moyen_paiement == "Chéque") {
        this.labelDate = "du chéque"
      }
      if (this.encaissement.moyen_paiement == "Autre" || this.detailEncaissement.moyen_paiement == "Autre") {
        this.labelDate = "d'encaissement"

      }

    },
    modalManyEncaissements() {
      this.newlistcontrat = []
      this.listencaissement = []
      this.listcontrat.forEach((contrat, index) => {
        if (contrat.etat_encaissement && contrat.etat_encaissement.toUpperCase() == "NON") {
          this.newlistcontrat.push({
            label: contrat.numero_police,
            key: contrat._id,
            initial: contrat.numero_police
          });
        }
      });
      this.editManyEncaissement = true;
    },
    goManyEncaissement() {
      if (
        this.encaissement.etat_encaissement &&
        this.encaissement.moyen_paiement &&
        this.encaissement.date_encaissement &&
        this.listencaissement.length != 0
      ) {
        if (this.encaissement.moyen_paiement == 'Autre')
          this.encaissement.moyen_paiement = this.AUTRES
        Api()
          .post("/borderaux/updatemany/encaissement", {
            _id: this.listencaissement,
            etat_encaissement: this.encaissement.etat_encaissement,
            moyen_paiement: this.encaissement.moyen_paiement,
            num_cheque_encaissement: this.encaissement.num_cheque_encaissement,
            banque_encaissement: this.encaissement.banque_encaissement,
            date_encaissement: this.encaissement.date_encaissement
          })
          .then((result) => {
            this.encaissement = {
              etat_encaissement: '',
              moyen_paiement: '',
              banque_encaissement: '',
              num_cheque_encaissement: '',
              date_encaissement: '',
            },
              this.$message.success(
                "Operation effectuée avec succés !",
                this.AlertMessage
              );
            this.editManyEncaissement = false;
            this.getContrat();
          })
          .catch((err) => {
            this.$message.error(
              "Verifié les informations saisies !",
              this.AlertMessage
            );
            console.log(err);
          });
      } else {
        this.$message.error(
          "Verifié les informations saisies !",
          this.AlertMessage
        );
      }
    },
    goEncaissement() {
      if (
        this.detailEncaissement.etat_encaissement &&
        this.detailEncaissement.moyen_paiement &&
        this.detailEncaissement.date_encaissement
      ) {
        if (this.detailEncaissement.moyen_paiement == 'Autre')
          this.detailEncaissement.moyen_paiement = this.AUTRES
        Api()
          .post("/borderaux/update/encaissement", {
            _id: this.detailEncaissement._id,
            etat_encaissement: this.detailEncaissement.etat_encaissement,
            moyen_paiement: this.detailEncaissement.moyen_paiement,
            num_cheque_encaissement: this.detailEncaissement.num_cheque_encaissement,
            banque_encaissement: this.detailEncaissement.banque_encaissement,
            date_encaissement: this.detailEncaissement.date_encaissement
          }
          )
          .then((result) => {

            this.$message.success(
              "Operation effectuée avec succés !",
              this.AlertMessage
            );
            this.editEncaissement = false;
            this.getContrat();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "Verifié les informations saisies!",
          this.AlertMessage
        );
      }
    },
    // view

    goEncaissementReinitialiser() {

      Api()
        .post("/borderaux/update/encaissement", {
          _id: this.detailEncaissement._id,
          etat_encaissement: "Non",
          moyen_paiement: "",
          num_cheque_encaissement: "",
          banque_encaissement: "",
          date_encaissement: ""
        }
        )
        .then((result) => {
          this.$message.success(
            "Operation effectuée avec succés !",
            this.AlertMessage
          );
          this.initEncaissement = false;
          this.getContrat();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    round(val) {
      if (val != undefined)
        return Math.round(val).toLocaleString() + " CFA";
    },
    totalRevenu(item) {
      if (item.commission != undefined) {
        let total = Math.round(item.commission) + Math.round(item.accessoires_courtier);
        return Math.round(total).toLocaleString() + " CFA";
      }
    },
    viewAssureur(assureur) {

      if (assureur) return assureur.societe;
    },
    viewBranche(brancheid) {
      if (brancheid) return brancheid.branche;
    },
    viewClient(contrat) {
      if (contrat.user) return contrat.user.nom + " - " + contrat.user.prenom;
    },
    viewPolice(contrat) {
      if (contrat) return contrat.numero_police;
    },
    viewPrime(contrat) {
      if (contrat) return contrat.prime_nette + " CFA";
    },

    confirmReinitialiser(item) {
      this.detailEncaissement = item;
      this.initEncaissement = true;
    },


    toggleEncaissement(item) {
      this.detailEncaissement = item;
      this.editEncaissement = true;
    },
    toggleDetails(item) {
      this.detailEncaissement = item;
      this.showencaissement = true;
    },
    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },
    onChange: function (e) {
      this.recherche = true;
    },

    goFilter: async function () {
      if (this.form.date.length > 1 && this.form.branches.length != 0 && this.form.assureurs.length != 0) {
        const response = await Api().post("borderaux/filtreContract", {
          debut_periode: this.form.date[0],
          fin_periode: this.form.date[1],
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches,
        });

        if (response.data.contrats.length != 0) {
          this.recherche = true;
          this.checkfiltre = true;
          this.listcontrat = response.data.contrats;
          this.count = response.data.count;
          this.$message.success("voici la liste les contrats filtrés !", this.AlertMessage)
        } else {
          this.recherche = false;
          this.checkfiltre = false;
          this.$message.error("Aucun resultat ne correspond a votre requete !", this.AlertMessage)
        }
      } else {
        this.recherche = false;
        this.checkfiltre = false;

        this.$message.error("Tous les champs sont obligatoires!", this.AlertMessage)
      }
    },

    exportBordereaux: async function () {
      if (
        this.form.description != "" &&
        this.form.date.length > 1 &&
        this.form.branches.length != 0 &&
        this.form.assureurs.length != 0
      ) {
        this.loading = true;
        const response = await Api().post("borderaux/exportBorderauxExpress", {
          debut_periode: this.form.date[0],
          description: this.form.description,
          fin_periode: this.form.date[1],
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches
        });

        if (response.data.saveBorderaux) {
          let url = response.data.lien;

          setTimeout(() => ((
            this.loading = false,
            this.dialogBordereaux = false,
            this.checkfiltre = false,
            this.$message.success("les données sont exportées !", this.AlertMessage),
            this.getContrat(),
            this.reset()
          ), window.open(url)), 5000);

        } else {
          this.$message.error("Le filtre a échoué !", this.AlertMessage)
        }
      } else {
        this.$message.error("Tous les champs sont obligatoires!", this.AlertMessage)
      }
    },
    reset() {
      this.form = {
        date: [],
        branches: [],
        assureurs: [],
        description: "",
      };
    },
    getBranches: async function () {
      const assureurs = await Api().get("/branche");
      if (assureurs) {
        this.listbranche = assureurs.data;
        for (let i = 0; i < this.listbranche.length; i++)
          this.brancheOptions.push(this.listbranche[i]);
      } else {
        this.$toasted.show(
          "Erreur lors de la récuperation des branches !",
          this.ToastTabError
        );
      }
    },
    getAssureurs: async function () {
      const assureurs = await Api().get("/partenaire");
      if (assureurs) {
        this.listassureur = assureurs.data.partenaires;
        //console.log(this.listassureur)
        for (let i = 0; i < this.listassureur.length; i++) {
          this.assureurOptions.push(this.listassureur[i]);
        }
      } else {
        this.$toasted.show(
          "Erreur lors de la récuperation des assueurs !",
          this.ToastTabError
        );
      }
    },
    getContrat: async function () {
      Api()
        .get("/borderaux/listeContratComission")
        .then((resultat) => {
          this.listcontrat = resultat.data.contrats;
          this.count = resultat.data.contrats.length;
        });
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
        .then(response => {
          if (response.data) {
            this.showAlert()
          }
          this.showAlert()
        })
        .catch(err => {

        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
        }).catch((err) => {
          console.log(err)
        });
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getBranches();
      this.getAssureurs();
      this.getContrat();
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.el-transfer-panel {
  border: 1px solid #EBEEF5 !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background: #FFFFFF !important;
  display: inline-block !important;
  vertical-align: middle !important;
  width: max-content !important;
  max-height: 100% !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative !important;
}

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
