<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Utilisateurs <span style="color:#ffa409"> Front office</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>
    <div class="mb-5">
      <el-button type="primary" @click="toCreateNewUser()" round>Créer un nouvel utilisateur</el-button>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher utilisateur..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher utilisateur..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Nom" min-width="150" fixed prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
          <el-table-column label="Genre" min-width="150" prop="sexe"></el-table-column>
        <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>
        <el-table-column label="Email" prop="email" min-width="300">
          <template slot-scope="scope">
            <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Rôle" min-width="150" prop="roles.name"></el-table-column>
        <el-table-column label="Plateforme" prop="plateforme" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.plateforme }}
          </template>
        </el-table-column>
        <el-table-column label="Créateur" prop="createdby" min-width="250">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.createdby">{{scope.row.createdby.prenom }} - {{scope.row.createdby.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Activer/Desactiver" prop="account_status" min-width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.account_status =='enabled'">Activé</span>
            <span v-if="scope.row.account_status =='desabled'">Desactivé</span>
          </template>
        </el-table-column>
        <el-table-column label="Etat" prop="etat" min-width="100">
          <template slot-scope ="scope">
            <img :src="require('../../../assets/images/online.png')" width="20" alt="" v-if="scope.row.etat=='Online'">
            <img :src="require('../../../assets/images/offline.png')" width="20" alt="" v-if="scope.row.etat=='Offline'">
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailuser(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" type="primary" @click="toggleDetails(scope.row)" icon="el-icon-edit" circle></el-button>
            <el-button size="mini" @click="userDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Nom" min-width="150" prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
        <el-table-column label="Genre" min-width="150" prop="sexe"></el-table-column>
        <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>
        <el-table-column label="Email" prop="email" min-width="300">
          <template slot-scope="scope">
            <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Rôle" min-width="150" prop="role"></el-table-column>
        <el-table-column label="Plateforme" prop="plateforme" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.plateforme }}
          </template>
        </el-table-column>
        <el-table-column label="Créateur" prop="createdby" min-width="250">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.createdby">{{scope.row.createdby.prenom }} - {{scope.row.createdby.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Activer/Desactiver" prop="account_status" min-width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.account_status =='enabled'">Activé</span>
            <span v-if="scope.row.account_status =='desabled'">Desactivé</span>
          </template>
        </el-table-column>
        <el-table-column label="Etat" prop="etat" min-width="100">
          <template slot-scope ="scope">
            <img :src="require('../../../assets/images/online.png')" width="20" alt="" v-if="scope.row.etat=='Online'">
            <img :src="require('../../../assets/images/offline.png')" width="20" alt="" v-if="scope.row.etat=='Offline'">
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailuser(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" type="primary" @click="toggleDetails(scope.row)" icon="el-icon-edit" circle></el-button>
            <el-button size="mini" @click="userDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails utilisateur" :visible.sync="showuser" v-if="showuser==true && !isMobile">
      <pre style="background-color:black;color:white;">
        {{ detailsUser }}
      </pre>
        <div class="mb-5">
          <el-button type="primary" round @click="enable_user(detailsUser._id)" v-if="detailsUser.account_status=='desabled'">Activer</el-button>
          <el-button type="danger" round @click="desable_user(detailsUser._id)" v-if="detailsUser.account_status=='enabled'">Désactiver</el-button>
        </div>
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                  <li>Téléphone bureau : <span class="to-right assuraf-orange">{{detailsUser.telephone_bur}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                  <li>Numéro pièce : <span class="to-right assuraf-orange">{{detailsUser.numero_piece}}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails utilisateur" :visible.sync="showuser" v-if="showuser==true && isMobile">
        <div class="mb-5">
          <el-button type="primary" round @click="enable_user(detailsUser._id)" v-if="detailsUser.account_status=='desabled'">Activer</el-button>
          <el-button type="danger" round @click="desable_user(detailsUser._id)" v-if="detailsUser.account_status=='enabled'">Désactiver</el-button>
        </div>
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                  <li>Téléphone bureau : <span class="to-right assuraf-orange">{{detailsUser.telephone_bur}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                  <li>Numéro pièce : <span class="to-right assuraf-orange">{{detailsUser.numero_piece}}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de modification" :visible.sync="editUser" v-if="editUser==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="8">
              <div>
                <label>Nom</label>
                <el-input v-model="detailsUser.nom" placeholder="Nom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Prénom</label>
                <el-input v-model="detailsUser.prenom" placeholder="Prénom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Téléphone portable</label>
                <el-input v-model="detailsUser.telephone_port" placeholder="Téléphone portable" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Téléphone bureau</label>
                <el-input v-model="detailsUser.telephone_bur" placeholder="Téléphone bureau" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Adresse</label>
                <el-input v-model="detailsUser.adresse" placeholder="Adresse" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Profession</label>
                <el-input v-model="detailsUser.profession" placeholder="Profession" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Date de naissance</label>
                <el-date-picker
                    style="width: 100%!important;"
                    @change="yearsDiff()"
                    v-model="detailsUser.datenaissance"
                    type="date"
                    placeholder="Choississez une date"
                    :picker-options="dateBeforeToday">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.sexe" placeholder="Selectionner">
                  <el-option label="homme" value="homme">Homme</el-option>
                  <el-option label="femme"  value="femme">Femme</el-option>
                  <el-option label="entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Statut Marital</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.statut_marital" placeholder="Selectionner">
                  <el-option label="célibataire">célibataire</el-option>
                  <el-option label="marié">marié(e)</el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Adresse mail</label>
                <el-input v-model="detailsUser.email" placeholder="Email" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Type piéce identité</label>
                  <el-select style="width: 100%!important;" v-model="detailsUser.piece_identite" placeholder="Selectionner">
                   <el-option label="Passeport" value="passeport">Passeport</el-option>
                   <el-option label="CNI" value="Carte CI">CNI</el-option>
                  </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Numéro piéce</label>
                <el-input v-model="detailsUser.numero_piece" placeholder="Numéro de pièce" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-col :span="8">
            <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
              <label>Rôle</label>
              <el-select style="width: 100%" v-model="userRole" placeholder="Selectionner">
                <el-option
                    v-for="role in listRoles"
                    :key="role._id"
                    :label="role.name"
                    :value="role._id"
                  />
              </el-select>
            </div>
          </el-col>

        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateUser()">Modifier</el-button>
          <el-button type="danger" round @click="editUser=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="90%!important" title="Formulaire de modification" :visible.sync="editUser" v-if="editUser==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Nom</label>
                <el-input v-model="detailsUser.nom" placeholder="Nom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Prénom</label>
                <el-input v-model="detailsUser.prenom" placeholder="Prénom" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Téléphone portable</label>
                <el-input v-model="detailsUser.telephone_port" placeholder="Téléphone portable" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

         
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Téléphone bureau</label>
                <el-input v-model="detailsUser.telephone_bur" placeholder="Téléphone bureau" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Adresse</label>
                <el-input v-model="detailsUser.adresse" placeholder="Adresse" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Profession</label>
                <el-input v-model="detailsUser.profession" placeholder="Profession" class="input-with-select"></el-input>
              </div>
            </el-col>

         
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date de naissance</label>
                <el-date-picker
                    style="width: 100%!important;"
                    @change="yearsDiff()"
                    v-model="detailsUser.datenaissance"
                    type="date"
                    placeholder="Choississez une date"
                    :picker-options="dateBeforeToday">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.sexe" placeholder="Selectionner">
                  <el-option label="homme" value="homme">Homme</el-option>
                  <el-option label="femme" value="femme">Femme</el-option>
                  <el-option label="entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Statut Marital</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.statut_marital" placeholder="Selectionner">
                  <el-option label="célibataire">célibataire</el-option>
                  <el-option label="marié">marié(e)</el-option>
                </el-select>
              </div>
            </el-col>
         
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Adresse mail</label>
                <el-input v-model="detailsUser.email" placeholder="Email" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Type piéce identité</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.piece_identite" placeholder="Selectionner">
                   <el-option label="Passeport" value="passeport">Passeport</el-option>
                   <el-option label="CNI" value="Carte CI">CNI</el-option>
                  </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Numéro piéce</label>
                <el-input v-model="detailsUser.numero_piece" placeholder="RNuméro piéce" class="input-with-select"></el-input>
              </div>
            </el-col>
          
            <el-col :span="24">
              <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
                <label>Rôle</label>
                <el-select style="width: 100%" v-model="detailsUser.role" placeholder="Selectionner">
                  <el-option
                      v-for="role in listRoles"
                      :key="role._id"
                      :label="role.name"
                      :value="role._id"
                    />
                </el-select>
              </div>
            </el-col>

        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateUser()">Modifier</el-button>
          <el-button type="danger" round @click="editUser=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="Configuration de menu" :visible.sync="showuser" v-if="showConfigMenu">
      <el-row :gutter="15">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Nom du menu principal</span>
              </div>
              <div>
                <el-select style="width: 100%!important;" @change="setCollapse()" v-model="menu_name" placeholder="Selectionner">
                  <el-option v-for="items in submenu"
                             :key="items"
                             :label="items.theme"
                             :value="items.theme">{{items.theme}}
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:15px">
                <label>id menu pricipal</label>
                <el-input v-model="collapse" placeholder="id menu principal" disabled></el-input>
                <el-button class="mt-5 mb-5" style="float:left" type="primary" @click="pushMenu()" round>Ajouter</el-button>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card" v-for="(item, index) in submenu" :key="index" style="margin-bottom: 15px">
              <div slot="header" class="clearfix">
                <span style="color: red">{{ item.theme }}</span>
              </div>
              <div v-for="(item, id) in item.config" :key="id" style="margin-top:15px">
                <el-checkbox v-model="userSubmenu" :label="item" border  unchecked-value="not_accepted">
                  {{item.libelle}}
                </el-checkbox>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div style="margin-top:15px;margin-bottom: 30px">
        <el-button type="primary" style="float: right" @click="create_user_menu()" round>Valider le menu</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Suppression utilisateur" width="50%" :visible.sync="modalDelete" v-if="modalDelete==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cet utilisateur?</span>
          </div>
          <div  >
            <ul style="list-style:none">
              <li>Utilisateur : <span class="to-right assuraf-orange">{{detailsUser.prenom.toUpperCase()}} {{detailsUser.nom.toUpperCase()}}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="DeletingUser(detailsUser._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      listRoles:null,
      detailsUserCar : [],
      listCars : [],
      userCars : [],
      ListeItems: ['0'], 
      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],



      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: [],
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      headerContrat: [
          {
        text: "Marque",
        sortable: false,
        value: "marque"
        },
        {
          text: "Modele",
          sortable: false,
          value: "model"
        },
        {
          text: "Immatriculation",
          sortable: false,
          value: "Immatriculation"
        },
        {
          text: "Nombre de places",
          sortable: false,
          value: "nbplace"
        },
        {
          text: "Force",
          sortable: false,
          value: "force"
        },
        {
          text: "Date 1ère Circulation",
          sortable: false,
          value: "dateCircule"
        },
        {
          text: "Valeur actuelle",
          sortable: false,
          value: "valeur_venale"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      fields: [
        {
          key: 'nom',
          label: 'Nom',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'prenom',
          label: 'Prenom',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'adresse',
          label: 'Adresse',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'telephone_port',
          label: 'Telephone',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'role',
          label: 'Role'
        },
        {key:'createdby',label:'Créateur'},
        {
          key: 'created',
          label: 'Date création'
        },
        {
          key: 'etat',
          label: 'Etat'
        },
        {
          key: 'action',
          label: 'Action'
        },
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listuser,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listuser.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
 
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },

    listFilteredCar() {
      return this.listCars.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
 
    listSorteredCar() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredCar.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageCar() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSorteredCar.slice(from, to)
      return this.listFilteredCar.slice(from, to)
    },
    totalCar() {
      return this.listFilteredCar.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    getUserRoles(){ 
      Api().get('/roles/all')
      .then(response => {
        this.listRoles = response.data.AllRoles
        console.log(this.listRoles)
      }).catch(err => {console.log("failed getAllUserRoles");})
    },

    exportToExcel() {
      Api().get("/export/users")
        .then((resultat) => {
          this.modalgenerationloading = true;
          let url = resultat.data.lien;
          setTimeout(
            () => ((this.modalgenerationloading = false), window.open(url)),
            7000
          );
        });
    },

    desable_user(userid){
        Api().post("/user/desable/account", {userid:userid})
          .then((result) => {
              this.getUserList()
              this.showuser = false
              this.$message.success('Utilisateur desactivé !', this.AlertMessage)
          }).catch((err) => {
            consoel.log("error")
          });
      },

    enable_user(userid){
        Api().post("/user/enable/account", {userid:userid})
          .then((result) => {
              this.getUserList()
              this.showuser = false
              this.$message.success('Utilisateur activé !', this.AlertMessage)
          }).catch((err) => {
            consoel.log("error")
          });
      },
    toCreateNewUser (){
      this.$router.go(this.$router.push('/users/createuser'))
    },
    
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },


    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$message.success('Opération effectuée !', this.AlertMessage)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.detailsUserCar = item._id
      this.showuser = true

      Api().post("flette/listing/", {userid: this.detailsUserCar})
          .then(response => {
            this.listCars = response.data.cars
            console.log(this.listCars)
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'SuperAdmin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;
        
          case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser() {

      let idU = this.detailsUser.id
      let item = this.detailsUser
      Api().post('user/' + idU, item)
          .then(response => {
              this.editUser  = false
              this.$message.success('Modification effectuée avec succés !', this.AlertMessage)
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('YYYY-MM-DD')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().post('user/delete/one', {deleter :store.state.user._id, todelete: id})
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
   
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.getUserList()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getUserRoles();
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
