<template>
  <div class="page-ecommerce-product-detail scrollable only-y">
    <el-breadcrumb separator="/" class="themed">
      <el-breadcrumb-item>Devis</el-breadcrumb-item>
      <el-breadcrumb-item>Liste</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #ffa409">{{ listdevis.length }} </span>Offre(s) de
        devis</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row class="mt-30" v-if="!isMobile">
      <el-col>
        <div class="item-box card-shadow--medium" style="margin-top: 20px" v-for="(assur, index) in listdevis"
          :key="index">
          <el-row>
            <el-col class="el-col-lg-5 el-col-xl-5">
              <div class="gallery-box" style="margin-left: 20%; margin-top: 45%">
                <div>
                  <img width="130" height="90" :src="assur.image" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="16" :xl="16">
              <div class="detail-box">
                <h2 class="title">
                  Numéro Police :
                  <span class="assuraf-orange">{{ assur.police }}</span>
                </h2>
                <h2 class="title">
                  Prime TTC :
                  <span class="assuraf-orange">{{ assur.tarif.toLocaleString() }} FCFA</span>
                </h2>
                <div class="description">frais de dossier inclus</div>
                <div class="actions-box">
                  <!-- <el-button 
                                        v-loading.fullscreen.lock="modalgenerationloading" 
                                        v-if="$store.state.user.role=='Admin' || $store.state.user.role=='SuperAdmin'" 
                                        class="themed mr-10 mb-10" 
                                        style="background:#ffa409;
                                        color:#fff" 
                                        type="button" @click="passage_souscription(assur)">
                                        <i class="mdi mdi-cash-multiple"></i> Souscrire
                                    </el-button> -->
                  <el-button v-loading.fullscreen.lock="modalgenerationloading" class="themed mr-10 mb-10"
                    style="background: #ffa409; color: #fff" type="button" @click="passage_souscription(assur)">
                    <i class="mdi mdi-cash-multiple"></i> Souscrire
                  </el-button>
                  <el-button v-if="
                    (assur.branche == 'Automobile' &&
                      $store.state.user.role == 'Admin') ||
                    $store.state.user.role == 'SuperAdmin'
                  " class="themed mr-10 mb-10 ml-0" type="button" @click="PopupSpecialReductionLoading(assur)">
                    <i class="mdi mdi-cash-minus"></i> Réduction spéciale
                  </el-button>
                  <el-button v-if="
                    $store.state.user.role == 'SuperAdmin' ||
                    $store.state.user.role == 'Admin'
                  " class="themed mr-10 mb-10 ml-0" type="button" @click="go_express_payment(assur)">
                    <i class="mdi mdi-clock-fast"></i> Paiement Express
                  </el-button>
                  <el-button class="themed mr-10 mb-10 ml-0" type="button" @click="devisParMail(assur)">
                    <i class="mdi mdi-email-outline"></i> Devis par email
                  </el-button>
                  <el-button v-loading.fullscreen.lock="modalgenerationloading" class="themed mr-10 mb-10 ml-0"
                    type="button" @click="Downloa_devis(assur)">
                    <i class="mdi mdi-download-outline"></i> Télécharger Devis
                  </el-button>
                  <el-button class="themed mr-10 mb-10 ml-0" type="button" @click="PaymentLink(assur)">
                    <i class="mdi mdi-email-send-outline"></i>
                    Lien de paiement
                  </el-button>

                  <el-button class="themed mr-10 mb-10 ml-0" type="button" @click="groupedLinkByMail(assur)">
                    <i class="mdi mdi-email-send-outline"></i>
                    Lien d'accès aux devis
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <!--================================= Mobile Part ================================-->
    <!--================================= Mobile Part ================================-->
    <!--================================= Mobile Part ================================-->
    <!--================================= Mobile Part ================================-->
    <div v-if="isMobile" class="card-base card-shadow--medium bg-white" style="margin-top: 20px">
      <el-row v-for="(assur, index) in listdevis" :key="index" class="mt-5 border pb-5">
        <el-col offset="8" justify="center" class="mt-2">
          <div>
            <img width="100" height="auto" :src="assur.image" style="margin-top: 15px" />
          </div>
        </el-col>
        <el-col class="text-center mt-2">
          <h3 class="">
            Numéro Police <span class="assuraf-orange">{{ assur.police }}</span>
          </h3>
          <h3 class="">
            Prime TTC :
            <span class="assuraf-orange">{{ assur.tarif.toLocaleString() }} FCFA</span>
          </h3>
          <div class="description">frais de dossier inclus</div>
        </el-col>
        <el-col class="mt-2">
          <div style="width: 70%; margin-left: 20%">
            <el-button v-loading.fullscreen.lock="modalgenerationloading" class="mobile-btn ml-10"
              v-if="$store.state.user.role == 'SuperAdmin'" style="background: #ffa409; color: #fff" type="button"
              @click="passage_souscription(assur)"><i class="mdi mdi-cash-multiple"></i> Souscrire</el-button>
            <el-button class="mobile-btn" v-if="
              assur.branche == 'Automobile' &&
              $store.state.user.role == 'Admin'
            " type="button" @click="PopupSpecialReductionLoading(assur)"><i class="mdi mdi-cash-minus"></i> Réduction
              spéciale</el-button>
            <el-button class="mobile-btn" v-if="$store.state.user.role == 'Admin'" type="button"
              @click="go_express_payment(assur)"><i class="mdi mdi-clock-fast"></i> Paiement Express</el-button>
            <el-button class="mobile-btn" type="button" @click="devisParMail(assur)"><i
                class="mdi mdi-email-outline"></i> Devis par email</el-button>
            <el-button class="mobile-btn" v-loading.fullscreen.lock="modalgenerationloading" type="button"
              @click="Downloa_devis(assur)"><i class="mdi mdi-download-outline"></i> Télécharger
              Devis</el-button>
            <el-button class="mobile-btn" type="button" @click="PaymentLink(assur)"><i
                class="mdi mdi-email-send-outline"></i> Lien de
              paiement</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- The pop-up for display and reduce devis prices
             The pop-up for display and reduce devis prices
             The pop-up for display and reduce devis prices  -->
    <el-dialog width="100%" style="margin-left: 8% !important; margin-right: 8% !important" title="Réduction spéciale"
      :visible.sync="modalReductionSpeciale">
      <div v-for="(car, indexCar) in CarDetailsArray" :key="indexCar">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <span>Marque & Modèle :
                <span style="color: #ffa409">{{
                  car.marque + "-" + car.model
                  }}</span></span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <span>Immatriculation :
                <span style="color: #ffa409">{{
                  car.immatriculation
                  }}</span></span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <span>
                Prime TTC sans réduction :
                <span style="color: red">{{
                  oldPrimeTT.toLocaleString()
                  }}</span>
                CFA</span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <span>Prime TTC avec réduction :
                <span style="color: red">{{
                  newprime_ttc.toLocaleString()
                  }}</span>
                CFA</span>
            </div>
          </el-col>
        </el-row>
        <table style="width: 100%; margin-top: 20px">
          <thead>
            <th class="border text-center">Garantie(s)</th>
            <th class="border text-center">Prime sans réduction</th>
            <th class="border text-center">Taux de réduction</th>
            <th class="border text-center">Prime après réduction</th>
          </thead>
          <tr v-for="(gar, indexGar) in car.Detail_Prime" :key="indexGar">
            <td class="border pl-3">
              {{ gar.garantie }}
            </td>

            <td class="border pl-3">{{ gar.prime.toLocaleString() }} CFA</td>

            <td class="border pl-3 pt-1 pb-1">
              <span class="ml-2"> Cliquez pour selectionner </span>
              <select style="width: 100% !important" v-model="gar.PourcentageReduction" class="border pl-2 pr-2"
                @change="ReductionCalculation(gar)">
                <option value="0" key="0">0%</option>
                <option v-for="n in 100" :value="n" :key="n">{{ n }}%</option>
              </select>
            </td>

            <td class="border pl-3" v-if="gar.primeAfterReduction != null">
              {{ gar.primeAfterReduction.toLocaleString() }} CFA
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 20px">
        <el-button v-if="newprime_ttc != 0" class="themed mr-10 mb-10 ml-0" type="primary"
          @click="UpdateDevisAfetrSpecialredution()"><i class="mdi mdi-cash-minus"></i> Valider réduction
          spéciale</el-button>
      </div>
    </el-dialog>

    <!-- POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT  -->
    <el-dialog title="LIEN DE PAIEMENT" :visible.sync="ThePaymentLinkModal">
      <el-form style="border-radius: 30px">
        <el-form-item label="Lien">
          <el-input readonly v-model="ThePaymentLink"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="LIEN DE DEVIS" :visible.sync="showRefLink">
      <el-form style="border-radius: 30px">
        <el-form-item label="Lien">
          <el-input readonly v-model="reference_link"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import store from "../../../store";

export default {
  name: "Paiement_liste",
  data() {
    return {
      // Data of ASSURAF - Data of ASSURAF - Data of ASSURAF
      reference_link: "",
      showRefLink: false,
      modalgenerationloading: false,
      isMobile: false,
      mode_paiement: null,
      mode_livraison: null,
      listdevis: [],
      assureurs: [],
      branche: null,
      modalgeneration: false,
      modalSendByMail: false,
      brancheTocheck: null,
      ThePaymentLink: null,
      ThePaymentLinkModal: false,
      ThePaymentLinkModalError: false,
      modalReductionSpeciale: false,
      DevisForReduction: null,
      CarDetailsArray: [],
      PourcentageReduction: null,
      newprime_ttc: 0,
      oldPrimeTT: 0,
      ToastTabSucess: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "success",
        className: "custom-success-class",
      }, // Customized options for success toast
      ToastTabError: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "error",
      }, // Customized options for error toast
      // FIN Data of ASSURAF - Data of ASSURAF - Data of ASSURAF

      rate: 4,
      qnt: 1,
      activeTab: "description",
    };
  },
  methods: {
    groupedLinkByMail(params) {
      const data = {
        reference: params.reference,
        userid: params.userid._id,
        userid: params.userid,
        brancheid: params.brancheid,
      };

      Api()
        .post("/devis/reference/bymail", data)
        .then((result) => {
          (this.reference_link = result.data.link), (this.showRefLink = true);
          if (result.data.message == "OK") {
            this.$message.success(
              "Le lien a bien été envyé par mail !",
              this.AlertMessage
            );
          } else {
            this.$message.error(
              "L'envoi du lien a échouée !",
              this.AlertMessage
            );
          }
        })
        .catch((err) => { });
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    init() {
      if (window.innerWidth <= 768) this.isMobile = true;
    },

    go_express_payment(contrat) {
      localStorage.setItem("contrat_for_express_payment", contrat.police);
      this.$router.go(this.$router.push({ name: "paiement_cash" }));
    },

    ReductionCalculation(gar) {
      let Reduction = (gar.prime * gar.PourcentageReduction) / 100;
      gar.primeAfterReduction = gar.prime - Reduction;

      const NewDetails = this.DevisForReduction.vehicul;
      let newPrimeNette = 0;

      for (let v of NewDetails) {
        for (let det of v.Detail_Prime) {
          if (det.primeAfterReduction > 0) {
            newPrimeNette = newPrimeNette + det.primeAfterReduction;
          } else {
            newPrimeNette = newPrimeNette + det.prime;
          }
        }
      }

      let Newtaxe = Math.ceil(
        ((newPrimeNette + this.DevisForReduction.accessoires) * 10) / 100
      );
      this.newprime_ttc =
        newPrimeNette +
        Newtaxe +
        this.DevisForReduction.fga +
        this.DevisForReduction.accessoires;
    },

    //Special reduction Pop-up loader function
    PopupSpecialReductionLoading(devis) {
      this.oldPrimeTT = devis.tarif;
      this.DevisForReduction = devis;
      this.CarDetailsArray = this.DevisForReduction.vehicul;
      this.modalReductionSpeciale = true;
    },

    passage_souscription(devis) {
      Api()
        .get("devis/" + devis.id)
        .then((response) => {
          this.modalgenerationloading = true;
          this.$store.dispatch("setDevisPayement", response.data);
          setTimeout(
            () => (
              (this.modalgenerationloading = false),
              this.$router.go(
                this.$router.push({
                  name: "paiement",
                })
              )
            ),
            4000
          );
        });
    },

    // Fonction de download du devis en fichier pdf
    Downloa_devis(devis) {
      Api()
        .get("devis/download/" + devis.id)
        .then((response) => {
          this.modalgenerationloading = true;
          let url = response.data.lien;

          let WinOpener = null;
          setTimeout(
            () => {
              this.modalgenerationloading = false
              WinOpener = window.open()
              WinOpener.location = url
            },
            7000
          );
        });
    },

    // Fonction d'envois du devis par email
    devisParMail(params) {
      Api()
        .get("devis/mydevisbyemail/" + params.id)
        .then((response) => {
          if (response.data.info) {
            //this.modalSendByMail = true
            this.$message.success(
              "Votre devis vous a été envoyé par mail !",
              this.AlertMessage
            );
          }
        });
    },

    PaymentLink: function (params) {
      let ObjetToSend = {
        userid: params.userid,
        devisid: params.id,
        brancheid: params.brancheid,
        partenaireid: params.partenaireid,
      };
      Api()
        .get("/paymentLink/devis/" + params.id)
        .then((resp) => {
          if (resp.data.link.length == 1) {
            this.ThePaymentLinkModal = true;
            this.ThePaymentLink = resp.data.link[0].contractLink;
          } else {
            Api()
              .post("/paymentLink/", ObjetToSend)
              .then((response) => {
                if (response.data.message == "OK") {
                  this.$message.success(
                    "Un lien de paiement vous a été envoyé par mail !",
                    this.AlertMessage
                  );
                  this.ThePaymentLink = response.data.link;
                }
              });
          }
        })
        .catch((e) => {
          this.ThePaymentLinkModalError = true;
          this.$message.error(
            "Erreur de connexion, veuillez réessayer !",
            this.AlertMessage
          );
        });
    },

    //Update devis after Special Reduction
    UpdateDevisAfetrSpecialredution: function () {
      const NewDetails = this.DevisForReduction.vehicul;
      let newPrimeNette = 0;

      for (let v of NewDetails) {
        for (let det of v.Detail_Prime) {
          if (det.primeAfterReduction) {
            newPrimeNette = newPrimeNette + det.primeAfterReduction;
            det.prime = det.primeAfterReduction;
          } else {
            newPrimeNette = newPrimeNette + det.prime;
            det.prime = det.prime;
          }
        }
      }

      let Newtaxe = Math.ceil(
        ((newPrimeNette + this.DevisForReduction.accessoires) * 10) / 100
      );
      this.newprime_ttc =
        newPrimeNette +
        Newtaxe +
        this.DevisForReduction.fga +
        this.DevisForReduction.accessoires;

      const DevisForUpdate = {
        id: this.DevisForReduction.id,
        primeTTC: this.newprime_ttc,
        primeNette: newPrimeNette,
        taxe: Newtaxe,
        vehicules: this.DevisForReduction.vehicul,
      };
      Api()
        .post("/devis/auto/special/reduction", DevisForUpdate)
        .then((result) => {
          if (result.data.status) {
            this.$toasted.show(result.data.msg, this.ToastTabSucess);
            setTimeout(
              () => (
                this.$router.go(this.$router.push({ name: "listdevis" })),
                window.open(url)
              ),
              1000
            );
          } else {
            this.$toasted.show(result.data.msg, this.ToastTabError);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("PaymentLink");
  },
  mounted() {
    this.init();
    if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
      let dernierDevis = [];
      if (this.$store.state.devis) {
        dernierDevis = this.$store.state.devis.result.devis;
        this.assureurs = this.$store.state.devis.result.partenaire;
        let branche = this.$store.state.devis.result.branche;
        this.brancheTocheck = this.$store.state.devis.result.branche.branche;

        if (branche.branche == "Habitation") {
          // Test if Branche==Habitation

          for (let ass of this.assureurs) {
            for (let dev of dernierDevis) {
              if (ass._id == dev.partenaireid) {
                this.listdevis.push({
                  id: dev._id,
                  image: ass.logo,
                  userid: dev.userid,
                  police: dev.numeroDevis,
                  brancheid: dev.brancheid,
                  branche: "Habitation",
                  partenaireid: ass._id,
                  nom: ass.societe,
                  tarif: dev.prime_ttc,
                  prime_nette: dev.prime_nette,
                  dateEffet: dev.dateEffet,
                  dateEcheance: dev.dateEcheance,
                  typeBien: dev.typeBien,
                  naturecouverture: dev.naturecouverture,
                  valeurbatiment: dev.valeurbatiment,
                  valeurcontenu: dev.valeurcontenu,
                  qualiteassure: dev.qualiteassure,
                  nombrepiece: dev.nombrepiece,
                  nombreetage: dev.nombreetage,
                  garanties: dev.garanties,
                  optionAss: [
                    "Assistance",
                    "Remplacement",
                    "Orientation sinistre",
                  ],
                  reference: dev.reference,
                });
              }
            }
          }
        } else if (branche.branche == "Voyage") {
          // Dans le cas de voyage on cree un tableau avec des libelles pour voyage
          for (let ass of this.assureurs) {
            for (let dev of dernierDevis) {
              if (ass._id == dev.partenaireid) {
                this.listdevis.push({
                  id: dev._id,
                  image: ass.logo,
                  police: dev.numeroDevis,
                  userid: dev.userid,
                  brancheid: dev.brancheid,
                  branche: "Voyage",
                  partenaireid: ass._id,
                  nom: ass.societe,
                  tarif: dev.prime_ttc,
                  prime_nette: dev.prime_nette,
                  dateEffet: dev.dateEffet,
                  dateEcheance: dev.dateEcheance,
                  nbpassager: dev.nbpassager,
                  pays_domicile: dev.vpays_domicile,
                  destination: dev.destination,
                  garanties: dev.garanties,
                  optionAss: [
                    "Assistance",
                    "Remplacement",
                    "Orientation sinistre",
                  ],
                  reference: dev.reference,
                  // Autres completer a la suite
                });
              }
            }
          }
        } else if (branche.branche == "Automobile") {
          // Dans le cas de voyage on cree un tableau avec des libelles pour Auto
          for (let ass of this.assureurs) {
            for (let dev of dernierDevis) {
              const partner = typeof dev.partenaireid == 'object' ? dev.partenaireid._id : dev.partenaireid
              if (ass._id.toString() == partner.toString()) {
                this.listdevis.push({
                  id: dev._id,
                  image: ass.logo,
                  police: dev.numeroDevis,
                  userid: dev.userid,
                  brancheid: dev.brancheid,
                  branche: "Automobile",
                  partenaireid: ass._id,
                  nom: ass.societe,
                  tarif: dev.prime_ttc,
                  prime_nette: dev.prime_nette,
                  taxe: dev.taxe,
                  fga: dev.fga,
                  accessoires: dev.accessoires,
                  dateEffet: dev.dateEffet,
                  dateEcheance: dev.dateEcheance,
                  vehicul: dev.vehicules,
                  garanties: dev.garanties,
                  reference: dev.reference,
                });
              }
            }
          }
        }
      }
    } else {
      this.$router.go(this.$router.push("/login"));
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.assuraf-orange {
  color: #ffa409 !important;
}

.mobile-btn {
  width: 180px;
  margin-top: 5px;
}

.page-ecommerce-product-detail {
  .item-box {
    border-radius: 4px;
    overflow: hidden;
    background: #fff;

    .main-photo {
      position: relative;
      overflow: hidden;
      background: white;
      padding: 30px;

      img {
        width: 100%;
      }
    }

    .detail-box {
      padding: 30px;
      position: relative;

      .title {
        margin: 0;
      }

      .price-box {
        margin-top: 20px;
        margin-bottom: 30px;

        .discounted-price {
          color: $text-color-accent;
          font-weight: bold;
          font-size: 25px;
          margin-right: 20px;
          display: inline-block;
        }

        .normal-price {
          opacity: 0.5;
          text-decoration: line-through;
          text-decoration-color: $text-color-accent;
          margin-right: 10px;
          display: inline-block;
        }

        .discount {
          color: $text-color-accent;
          display: inline-block;
        }
      }

      .actions-box {
        margin-top: 30px;

        .el-input-number {
          width: 100px;

          .el-input__inner {
            color: $text-color-accent;
            background-color: transparent;
            border-color: $text-color-accent;
            font-family: inherit;
            font-weight: bold;
            padding-left: 5px;
            padding-right: 45px;
          }
        }

        .el-button {
          font-family: inherit;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
